<template>
  <div>
    <el-container class="el-container">
      <div class="leftReceiver">
        <LeftReceiver></LeftReceiver>
      </div>

      <div class="rightSource">
        <router-view></router-view>
      </div>
    </el-container>
  </div>
</template>
<script>
import LeftReceiver from './LeftReceiver'
import { mapState } from 'vuex'
export default {
  components: {
    LeftReceiver
  },
  props: {},
  data () {
    return {
      msg: 'This is new Home.',
      showDialog: {
        multiControl: true
      },
      multiControl: true
    }
  },
  created () {
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    })
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/nhmAdaptive/index.less'; //响应式文件
::v-deep .el-row {
  width: 100%;
}
.rightSource {
  ::v-deep .bookmark-cascader {
    width: 100%;
    .el-cascader {
      width: 100%;
    }
  }
}
.leftReceiver {
  box-shadow: 0 0 0.1rem #000;
  background-color: #444;
  width: 395px;
}

.rightSource {
  margin: 0 auto;
  position: relative;
  /*height: 9.03rem;*/
  width: calc(100% - 395px);
}
.el-container {
  max-width: 1920px !important;
  /*overflow-x: hidden;*/
  /*overflow-y: scroll;*/
}
</style>
