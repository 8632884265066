<template>
  <el-dialog
    :title="language[lang].IpStreamOut"
    width="1075px"
    class="add-profile"
    :visible.sync="profileDialogInfo.show"
    :center="true"
    @close="cancel"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    append-to-body>
    <el-container
      v-if="operateType=='list'"
      class="profiles-wrapper">
      <el-header
        class="profiles-header"
        style="height: auto">
        <i
          class="el-icon-arrow-left"
          @click="cancel"></i>
        <span class="profiles-title">{{language[lang].Profiles}}</span>
      </el-header>
      <el-main
        class="profiles-main"
        v-loading="listLoading"
        element-loading-text="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <el-table
          v-if="!listLoading"
          :data="profileList"
          style="width: 100%">
          <el-table-column
            prop="ID"
            :label="language[lang].ProfileName">
            <template slot-scope="scope">
              <span :title="scope.row.ID">
                {{ scope.row.ID }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="language[lang].Status">
            <template slot-scope="scope">
              <span class="profile-status">{{ scope.row.applying ? 'Applying' : '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="Number(rVersion) >= 7.7"
            prop="date"
            label="Operation"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="scope.row.applying"
                @click="goEditProfile(scope.row.ID)"
              >{{$t('lang.edit')}}</el-button>
              <el-button
                :disabled="scope.row.applying"
                @click="deleteProfile(scope.row)"
              >{{$t('lang.Delete')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="profiles-footer">
        <el-button
          @click.stop="cancel">{{language[lang].Back}}</el-button>
        <el-button
          type="success"
          @click="goInitProfile('add')">+{{language[lang].Add}}</el-button>
      </el-footer>
    </el-container>
    <el-container
      v-if="operateType=='add'"
      class="profiles-wrapper">
      <el-header
        class="profiles-header"
        style="height: auto">
        <i
          class="el-icon-arrow-left"
          @click="cancel"></i>
        <span
          v-if="operateType=='list'"
          class="profiles-title">{{language[lang].Profiles }}</span>
        <span v-else>{{profileID? language[lang].Edit : language[lang].Add}}</span>
      </el-header>
      <div
        style="min-height: 290px;max-height: 500px;overflow: auto;"
        v-loading="addLoading"
        element-loading-text="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <el-main
          id="contentBody"
          v-if="profileInfo !== null"
          class="edit-profile-box ipStreamOutBody">
          <el-form
            ref="form"
            label-width="150px">
            <el-form-item :label="language[lang].ProfileName">
              <el-input
                v-model="profileInfo.ID"
                size="small"
                @change="changeProfileName"></el-input>
            </el-form-item>
            <el-form-item :label="language[lang].Muxer">
              <el-select
                v-model="profileInfo.Muxer"
                size="small"
                placeholder="">
                <el-option
                  v-for="(item, index) in muxerOptions"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-row class="Title">{{language[lang].VideoEncoder}}</el-row>
            <!-- Bitrate -->
            <el-form-item :label="language[lang].Bitrate">
              <el-select
                v-model="profileInfo.Video.Bitrate"
                size="small"
                placeholder="">
                <el-option
                  v-for="(item, index) in enableOptions.VBirates"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!-- Resolution -->
            <el-form-item :label="language[lang].Resolution">
              <el-select
                v-model="profileInfo.Video.Resolution"
                size="small"
                placeholder="">
                <el-option
                  v-for="(item, index) in enableOptions.VResolutions"
                  :key="index"
                  :label="item"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- collapse Container -->
          <el-collapse
            v-model="activeNames"
            class="collapseAdvanced">
            <el-collapse-item
              title="Advanced…"
              name="1">
              <el-form
                ref="form"
                label-width="150px">
                <!-- Global Quality -->
                <el-form-item
                  v-if="Number(rVersion) < 7.8"
                  :label="language[lang].GlobalQuality">
                  <el-input
                    v-model="profileInfo.Video.GlobalQuality"
                    key="GlobalQuality"
                    size="small"></el-input>
                </el-form-item>
                <!-- CBR -->
                <el-form-item :label="language[lang].CBR">
                  <el-radio-group
                    v-model="profileInfo.Video.CBR"
                    size="small">
                    <el-radio :label="true">{{language[lang].Yes}}</el-radio>
                    <el-radio :label="false">{{language[lang].No}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- Codec -->
                <el-form-item :label="language[lang].Codec">
                  <el-select
                    v-model="profileInfo.Video.Codec"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.Vcodecs"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="language[lang].Deinterlace">
                  <el-radio-group
                    v-model="profileInfo.Video.Deinterlace"
                    size="small">
                    <el-radio label="true">{{language[lang].Yes}}</el-radio>
                    <el-radio label="false">{{language[lang].No}}</el-radio>
                    <el-radio label="Auto">{{language[lang].Auto}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="language[lang].FrameRate">
                  <el-input
                    v-model="profileInfo.Video.FrameRate"
                    key="FrameRate"
                    size="small"></el-input>
                </el-form-item>
                <el-form-item :label="language[lang].GOP">
                  <el-input
                    v-model="profileInfo.Video.GOP"
                    key="GOP"
                    size="small"></el-input>
                </el-form-item>
                <el-form-item :label="language[lang].Scale">
                  <el-select
                    v-model="profileInfo.Video.Scale"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.Scales"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="language[lang].Profiles">
                  <el-select
                    v-model="profileInfo.Video.Profile"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.Profiles"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="language[lang].Level">
                  <el-input
                    v-model="profileInfo.Video.Level"
                    key="Level"
                    size="small"></el-input>
                </el-form-item>
                <el-form-item :label="language[lang].Preset">
                  <el-select
                    v-model="profileInfo.Video.Preset"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.Presets"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="language[lang].Tune">
                  <el-input
                    v-model="profileInfo.Video.Tune"
                    size="small"
                    readonly="readonly"></el-input>
                </el-form-item>
              </el-form>
            </el-collapse-item>

            <!-- Audio Encoder -->
            <el-row class="Title">{{language[lang].AudioEncoder}}</el-row>
            <el-form
              ref="form"
              label-width="150px">
              <el-form-item :label="language[lang].Bitrate">
                <el-select
                  v-model="profileInfo.Audio.Bitrate"
                  size="small"
                  placeholder="">
                  <el-option
                    v-for="(item, index) in enableOptions.ABitrates"
                    :key="index"
                    :label="item"
                    :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="language[lang].Codec">
                <el-select
                  v-model="profileInfo.Audio.Codec"
                  size="small"
                  placeholder="">
                  <el-option
                    v-for="(item, index) in enableOptions.ACodecs"
                    :key="index"
                    :label="item"
                    :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-collapse-item
              title="Advanced…"
              name="2">
              <el-form
                ref="form"
                label-width="150px">
                <el-form-item :label="language[lang].Channel">
                  <el-select
                    v-model="profileInfo.Audio.Channel"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.AChannels"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="language[lang].SampleRate">
                  <el-select
                    v-model="profileInfo.Audio.SampleRate"
                    size="small"
                    placeholder="">
                    <el-option
                      v-for="(item, index) in enableOptions.ASampleRate"
                      :key="index"
                      :label="item"
                      :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </el-main>
      </div>
      <el-footer class="profiles-footer">
        <el-button
          @click.stop="cancel">{{language[lang].Cancel}}</el-button>
        <el-button
          type="primary"
          :disabled="disabled"
          @click.stop="saveProfile">{{language[lang].Save}}</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
import language from '@/assets/lang/config.js'
import vueBus from '@/assets/js/vueBus.js'
import Api from '@/assets/api/api.js'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
export default {
  props: {
    applyingProfileList: {
      type: Array,
      default: _ => []
    },
    profileDialogInfo: {
      type: Object,
      default: () => {
        return {
          type: 'add',
          show: false
        }
      }
    },
    receiverWebsocket: {
      type: Object
    },
    rid: {
      type: String
    },
    lang: {
      type: String,
      default: 'en'
    },
    rVersion: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      language: language,
      profileList: [],
      isCreate: false,
      profileID: '',
      profileInfo: null,
      modelFlag: true,
      operateType: 'add',
      addLoading: false,
      listLoading: false,
      activeNames: ['1', '2'],
      enableOptions: {},
      muxerOptions: [],
      disabled: false,
      lastName: ''
    }
  },
  watch: {
    profileInfo: {
      handler (newInfo) {
        let disabled = false
        for (const key in this.profileInfo) {
          const item = this.profileInfo[key]
          if (typeof item === 'string' && key !== 'ID') {
            if (item === '') {
              disabled = true
            }
          } else {
            for (const itm in item) {
              if (item[itm] === '') {
                disabled = true
              }
            }
          }
        }
        this.disabled = disabled
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    this.operateType = this.profileDialogInfo.type
    this.queryEnableOptions()
    await this.queryProfileList()
    // this.operateType == 'add'? this.queryEnableOptions() :
  },
  methods: {
    cancel () {
      $('.v-modal').remove()
      $('.ipStreamOut75').parent('.el-dialog__wrapper').after($("<div class='v-modal' tabindex='0'></div>"))
      if (this.operateType == 'add') {
        this.operateType = 'list'
      } else {
        this.profileDialogInfo.show = false
      }
    },
    changeProfileName (e) {
      let reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g')
      if (reg.test(e)) {
        this.$message.error('It is forbidden to enter Chinese')
        if (this.lastName != '') {
          this.profileInfo.ID = this.lastName
        } else {
          this.profileInfo.ID = ''
        }
      }
    },
    // 获取profile的列表
    queryProfileList () {
      this.listLoading = true
      const data = {
        rid: this.rid,
        Data: ''
      }
      const params = Object.assign(data, Api.options.getEncodingProfiles)
      const URL = `${this.profileDialogInfo.initUrl}${Api.url.getEncodingProfiles}`
      this.axios.post(URL, params)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            this.listLoading = false
            this.profileList = JSON.parse(res.data.result).map(item => {
              item.applying = this.applyingProfileList.includes(item.ID)
              return item
            })
            console.log(this.profileList)
          }
        }).catch(() => {})
    },
    // 获取所有的可选数据
    queryEnableOptions () {
      // this.addLoading = true
      const data = {
        rid: this.rid,
        Data: ''
      }
      const params = Object.assign(data, Api.options.getAllAvailabelOptions)
      const URL = `${this.profileDialogInfo.initUrl}${Api.url.getAllAvailabelOptions}`
      this.axios.post(URL, params).then(res => {
        if (res.data.errorCode === '0x0') {
          this.enableOptions = JSON.parse(res.data.result)
          this.muxerOptions = this.enableOptions.Muxers
          this.goInitProfile()
        }
      }).catch(() => {})
    },
    // 获取当前profile的信息
    queryProfileInfo () {
      const data = {
        rid: this.rid,
        Data: JSON.stringify({ ID: this.profileID })
      }
      const params = Object.assign(data, Api.options.getProfile)
      const URL = `${this.profileDialogInfo.initUrl}${Api.url.getProfile}`
      this.axios.post(URL, params)
        .then(res => {
          const result = JSON.parse(res.data.result)
          if (res.data.errorCode === '0x0') {
            if (result.errorCode == '0x0' || result.errorInfo == 'Success' || !result.errorCode) {
              this.profileInfo = result
              this.lastName = this.profileInfo.ID
            } else {
              this.$message({
                type: 'warning',
                message: this.$t('lang.requestFailed')
              })
            }
          }
        }).catch(() => {})
    },
    // 去编辑Profile
    goEditProfile (Id) {
      this.operateType = 'add'
      this.profileID = Id
      this.queryProfileInfo()
    },
    // 删除Profile
    deleteProfile (profile) {
      this.$confirm(this.$t('lang.ConfirmRecording'), 'Prompt', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.Cancel')
      }).then(() => {
        const data = {
          rid: this.rid,
          Data: JSON.stringify({ ID: profile.ID })
        }
        const params = Object.assign(data, Api.options.deleteProfile)
        const URL = `${this.profileDialogInfo.initUrl}${Api.url.deleteProfile}`
        this.axios.post(URL, params).then(res => {
          const result = JSON.parse(res.data.result)
          if (res.data.errorCode === '0x0') {
            if (result.errorCode == '0x0' || result.errorInfo == 'Success' || !result.errorCode) {
              this.$message({
                type: 'success',
                message: this.$t('lang.deleteSuccess')
              })
              this.queryProfileList()
            }
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    goInitProfile (tag) {
      this.operateType = tag && tag == 'add' ? 'add' : this.operateType
      if (this.operateType == 'add') {
        // this.addLoading = false
        if (this.enableOptions.DefaultEncodingProfile) {
          this.profileInfo = this.enableOptions.DefaultEncodingProfile
          this.profileID = ''
        } else {
          this.queryEnableOptions()
        }
      }
      adAnalytice('adSettingipStreamOutPrefileAdd')
    },
    // 保存profile的信息
    isNumber (obj) {
      return typeof obj === 'number' && !isNaN(obj)
    },
    saveProfile () {
      const { ProfileFileName } = this.profileInfo
      if (ProfileFileName) {
        this.updateProfile()
      } else {
        if (this.profileInfo.ID) {
          const hasArr = this.profileList.filter(val => val.ID == this.profileInfo.ID)
          if (hasArr.length > 0) {
            this.$message({ type: 'warning', message: this.language[this.lang].hasProfileName })
          } else {
            this.createProfile()
          }
        } else {
          this.$message.error(this.language[this.lang].MustProfileName)
        }
      }
    },
    // 更新profile的信息
    updateProfile () {
      const data = {
        rid: this.rid,
        Data: JSON.stringify(this.profileInfo)
      }
      const params = Object.assign(data, Api.options.upDateProfile)
      const URL = `${this.profileDialogInfo.initUrl}${Api.url.upDateProfile}`
      this.axios.post(URL, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode === '0x0') {
          if (result.errorCode == '0x0' || result.errorInfo == 'Success' || !result.errorCode) {
            this.$message({
              type: 'success',
              message: this.$t('lang.EditSuccess')
            })
            this.operateType = 'list'
            this.queryProfileList()
          } else {
            this.$message({
              type: 'error',
              message: result.errorInfo
            })
          }
        }
      }).catch(() => {})
    },
    // 创建profile
    createProfile () {
      // this.profileInfo.ID = this.profileID
      const data = {
        rid: this.rid,
        Data: JSON.stringify(this.profileInfo)
      }
      const params = Object.assign(data, Api.options.creatProfile)
      const URL = `${this.profileDialogInfo.initUrl}${Api.url.creatProfile}`
      this.axios.post(URL, params)
        .then(res => {
          const result = JSON.parse(res.data.result)
          if (res.data.errorCode === '0x0') {
            if (result.errorCode == '0x0' || result.errorInfo == 'Success') {
              this.$message({ type: 'success', message: this.language[this.lang].CreateSuccess })
              vueBus.$emit('updateProfileList')
              this.cancel()
              this.queryProfileList()
            } else {
              this.$message({ type: 'warning', message: result.errorInfo })
            }
          } else {
            this.$message({ type: 'warning', message: this.$t('lang.requestFailed') })
          }
        }).catch(() => {})
    }
  },
  beforeDestroy () {
    if (this.receiverWebsocket && this.receiverWebsocket.successObj.GetProfileList) {
      this.receiverWebsocket.stop('GetProfileList')
    }
    if (this.receiverWebsocket && this.receiverWebsocket.successObj.GetProfileOptions) {
      this.receiverWebsocket.stop('GetProfileOptions')
    }
  }
}
</script>
<style lang="less" scoped>
.add-profile {
  min-width: 520px;
  .Title {
    margin: 20px 0;
    font-size: 16px;
  }
  .el-col-3 {
    color: #dbdbdb;
  }
  .collapseAdvanced {
    .el-collapse-item__header {
      margin-bottom: 10px;
    }
  }
  .el-form-item {
    display: inline-block;
    ::v-deep .el-radio-group, .el-select, .el-input{
      width: 270px;
    }
  }
  #contentBody {
    .el-collapse-item__arrow {
      margin: 0px;
    }
    .el-collapse-item__header {
      justify-content: flex-end;
      background: none;
    }
  }
  .profiles-wrapper {
    text-align: left;
  }
  .profiles-header {
    padding-bottom: 12px;
    border-bottom: solid 1px #333;
    cursor: pointer;
  }
  .el-icon-arrow-left {
    font-size: 20px;
    vertical-align: middle;
  }
  .profiles-title {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 16px;
    cursor: auto;
  }
  .profile-status {
    color: #00d000;
  }
  .el-button--text {
    color: #dbdbdb;
  }
  .profiles-main {
    height: 420px;
    overflow-y: scroll;
    width: 100%;
  }
  .profiles-main {
    ::v-deep .el-button {
      padding: 0 10px;
      background: none;
      border: none;
    }
  }
  .profiles-footer {
    text-align: right;
  }
  .edit-profile-box {
    padding: 15px 0 30px 0;
  }
  .edit-profile-box .el-col {
    margin-bottom: 5px;
  }
  .edit-profile-box .el-select {
    display: block;
  }
  #contentBody .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
  #contentBody {
    ::v-deep .el-collapse-item__wrap {
      background: none !important;
    }
  }
  #contentBody .el-collapse {
    border-bottom: none;
  }
}
.el-dialog {
  padding: 0;
}
::v-deep .el-dialog__body {
  .el-form-item {
    margin-bottom: 10px!important;
    .el-form-item__label {
      margin: 5px 0 5px 0!important;
      text-align: right;
      padding-right: 15px!important;
    }
  }
}
::v-deep .el-collapse-item__header {
  margin: 0 18px 0 auto;
}
::v-deep .el-collapse-item__header,
.el-collapse-item__wrap {
  background-color: transparent !important;
}
::v-deep .el-table .cell {
  white-space: nowrap;
}
</style>
