<template>
  <div>
    <div :class="[socketItemData.status == 0 ? 'receiverItem cursorStyle left':'receiverItem left']">
      <div
        class="left"
        @click="showVideo">
        <div
          class="Info"
          v-if="socketItemData.status == 1">
          <span class="square green"></span>
          <span class="RName">{{receiverItemOps.name}}</span>
        </div>
        <div
          class="Info"
          v-if="socketItemData.status == 0">
          <span class="square gray"></span>
          <span class="RName">{{receiverItemOps.name}}</span>
        </div>
        <div
          class="liveInfo"
          v-if="socketItemData.status == 2">
          <div
            class=""
            :class="'liveDis'+ receiverItemOps.peerId"
            :title="receiverItemOps.peerId">
            <div
              class="Info left"
              :title="receiverItemOps.name">
              <span class="square red"></span>
              <span class="RName">{{receiverItemOps.name}}</span>
            </div>

          </div>
          <div
            :class="'onlineDis'+ receiverItemOps.peerId"
            class="onlineSource">
            <div
              class="Info left"
              :title="socketItemData.livePeerName || 'R local source'">
              <span class="padding colorRed">{{$t('lang.live')}}:</span>
              <span class="colorRed">{{socketItemData.livePeerName || 'R local source'}}</span>
            </div>
          </div>
          <button
            class="el-button el-button--success el-button--small red right"
            @click.stop="stopLive(receiverItemOps.peerId)">{{$t('lang.stop')}}
          </button>
        </div>
      </div>
    </div>
    <video-control
      v-if="showVideoControl"
      :rid="receiverItemOps.peerId"
      :currentInfo="socketItemData"
      :currentSocketData="socketItemData"
      :close="showVideo"
      :listItemOps="receiverItemOps"
      :key="receiverItemOps.peerId + 'videoBox'"
      title="receiverControlTitle">
    </video-control>
    <ShareConfirm
      :show.sync="showFlag"
      @confirmFun="confirmFun"></ShareConfirm>
    <GridDescription
      v-if="confirmObj.showFlag && openOnGoingEvent"
      :confirmObj="confirmObj"></GridDescription>

  </div>
</template>
<script>
import videoControl from './videoControl'
import Tool from '@/assets/js/utils.js'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import Bus from '@/assets/js/vueBus'
import ShareConfirm from '@/components/shareConfirm'
import GridDescription from '@/components/gridDescription'

export default {
  props: {
    socketItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    receiverItemOps: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      socketItemData: this.receiverItemOps,
      showOnline: true, // 控制实时的显示隐藏
      showLiveNameTimer: null, // 控制显示RlivePeerName的定时器
      liveNameDis: false, // 是否是显示livePeerName
      showVideoControl: false,
      clickStop: false, // 是否点击了stop按钮,
      showFlag: false,
      stopRId: '',
      confirmObj: {
        showFlag: false,
        name: '',
        peerId: '',
        sourceType: '',
        checkBehavior: true
      }
    }
  },
  components: {
    videoControl,
    ShareConfirm,
    GridDescription
  },
  computed: {
    openOnGoingEvent () {
      return this.$store.state.common.baseUrl.openOnGoingEvent
    }
  },
  mounted () {
    this.changeRLiveTName()
  },
  watch: {
    socketItem: {
      handler (val) { // 监听socket数据
        if (val.peerId) {
          this.socketItemData = val
        }
      },
      deep: true
    }
  },
  methods: {
    confirmFun (flag) {
      if (this.confirmObj.sourceType && flag) this.confirmObj.showFlag = true
    },
    showVideo () {
      if (this.socketItemData.status == '0') return
      this.showVideoControl = !this.showVideoControl
      if (this.showVideoControl) { // 关闭打开弹窗的埋点
        if (this.$createWebsocket.pageRWebsocket) {
          this.$createWebsocket.pageRWebsocket('stop', 'receiverData') // 停止websocket
        }
        newHomeAnalyze('RShowVideo', this.$route.path)
      } else {
        Bus.$emit('openListWebsocket')
        newHomeAnalyze('RHideVideo', this.$route.path)
      }
      Bus.$emit('openRVideoDetail', this.showVideoControl)
    },
    changeRLiveTName () {
      if (this.showLiveNameTimer) clearInterval(this.showLiveNameTimer)
      $('.onlineSource').hide()
      this.showLiveNameTimer = setInterval(() => {
        if (this.socketItemData.status == 2) {
          if (this.liveNameDis) {
            $('.liveDis' + this.receiverItemOps.peerId).hide()
            $('.onlineDis' + this.receiverItemOps.peerId).fadeIn(1500)
          } else {
            $('.liveDis' + this.receiverItemOps.peerId).fadeIn(1500)
            $('.onlineDis' + this.receiverItemOps.peerId).hide()
          }
          this.liveNameDis = !this.liveNameDis
        }
      }, 5000)
    },
    stopLive (stopRId) {
      if (this.socketItemData.livePeerType) {
        const sourceType = this.socketItemData.livePeerType.toLowerCase()
        const platform = Number(this.socketItemData.liveDevicePlatform)
        if (sourceType == 'x' || sourceType == 'tvugrid') {
          this.confirmObj.sourceType = 'Grid'
        }
        if (sourceType == 't' && platform) {
          this.confirmObj.sourceType = platform >= 10 && platform <= 14 ? 'Anywhere' : 'Pack'
        }
      }
      this.confirmObj.name = this.socketItemData.livePeerName
      this.confirmObj.peerId = this.confirmObj.sourceType ? this.socketItemData.livePeerId.toLowerCase() : ''

      this.stopRId = stopRId
      const data = {
        pn: 'shareTransmission'
      }
      this.axios.post('/ccp/tvucc-user/userDevice/listPreference', data, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result && res.data.result[0] && res.data.result[0].content == 'true') {
            this.showFlag = true
          } else {
            if (this.confirmObj.peerId) this.confirmObj.showFlag = true
            this.stopOption()
          }
        } else {
          if (this.confirmObj.peerId) this.confirmObj.showFlag = true
        }
      }).catch(() => {
        if (this.confirmObj.peerId) this.confirmObj.showFlag = true
        this.stopOption()
      })
    },
    stopOption () {
      this.clickStop = true
      const params = {
        rid: this.stopRId
      }
      newHomeAnalyze('RStopLive', this.$route.path)
      this.axios.post('/ccp/tvucc-device/receiver/stopLive', params).then(res => {
        const errorCode = res.data.errorCode
        this.clickStop = false
        if (errorCode == '0x0') {
          this.clickStop = false
        } else {
          Tool.ToTip(errorCode, 'warning', res.data.errorInfo)
        }
      }).catch(() => {})
    }
  },
  beforeDestroy () {
    clearInterval(this.showLiveNameTimer)
  }
}
</script>
<style lang="less" scoped>
.receiverItem {
  height: 52px;
  line-height: 52px;
  width: 100%;
  text-align: left;
  padding-left: 0.25rem;
  cursor: pointer;

  .hide {
    display: none;
  }

  &:hover {
    background: rgba(51, 171, 79, 0.26);
  }

  .Info {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .padding {
      padding-right: 10px;
    }
  }

  .left {
    width: 100%;

    .square {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0 0.3rem 0 0;
    }

    .right {
      float: right;
      margin-top: 10px;
      margin-right: 5%;
    }

    .liveInfo {
      width: 100%;
    }

    .RName {
      font-size: 0.16rem;
    }
  }

  .green {
    background: #0c6;
  }

  .red {
    background: #f00;
  }

  .colorRed {
    color: #f00;
    font-size: 0.16rem;
  }

  .gray {
    background: #999;
  }
}
.cursorStyle {
  cursor: no-drop;
}
</style>
