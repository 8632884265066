<template>
  <div
    class="shareBox"
    v-loading="share_loading"
    :element-loading-text="loadingText"
    element-loading-background="rgba(102,102,102,.6)"
    :class="{share_display: shareFlag}">
  </div>
</template>
<script>
export default {
  props: {
    shareFlag: {
      type: Boolean,
      default: false
    },
    rId: {
      type: String,
      default: ''
    },
    sId: {
      type: String,
      default: ''
    },
    shareInfo: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    sType: String
  },
  data () {
    return {
      share_loading: true,
      loadingText: this.$t('lang.i18n_loading'),
      loadDomTimers: null,
      refreshStatusTimers: null
    }
  },
  watch: {
    shareFlag: {
      handler (val, oldVal) {
        if (val != oldVal) {
          try {
            mediaObj.mediaType = 'L'
            mediaObj.rId = this.rId
            mediaObj.sId = this.sId
            mediaObj.blobUrl = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.blobUrl
            mediaObj.sType = this.sType && this.sType.toLowerCase() || ''
            mediaObj.shareToggle = this.shareFlag
            mediaObj.changeType = 'showOrHide'
            mediaObj.platform = 'cc'
            this.$nextTick(res => {
              SM.__proto__.JumpMediaJs()
            })
          } catch (err) { }
        }
      },
      immediate: true,
      deep: true
    },
    rId () {
      try {
        mediaObj.rId = this.rId
      } catch (err) { }
    },
    sId () {
      try {
        mediaObj.sId = this.sId
      } catch (err) { }
    },
    shareInfo: {
      handler (newVal, oldVal) {
        if (newVal != oldVal && oldVal != undefined) {
          // 兼容m2.0
          if (typeof ShareBox === 'function') {
            ShareBox.setSharingUI(this.id)
            return
          }
          // 兼容v3.0
          if (typeof (window.setSharingUI) === 'function' && window.setSharingUI != undefined && window.setSharingUI != 'undefined' && window.setSharingUI) {
            window.setSharingUI($('#' + this.id))
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.share_loading = true
    mediaObj.socialLoaded = false
    this.refreshStatus()
    this.loadDom()
  },
  methods: {
    loadDom () {
      if (this.loadDomTimers) {
        clearInterval(this.loadDomTimers)
        this.loadDomTimers = null
      }
      this.loadDomTimers = setInterval(() => {
        if (mediaObj.socialLoaded) {
          this.share_loading = false
          clearInterval(this.loadDomTimers)
          this.loadDomTimers = null
          mediaObj.socialLoaded = false
        } else {
          this.share_loading = true
        }
      }, 200)
    },
    refreshStatus () {
      if (this.refreshStatusTimers) {
        clearInterval(this.refreshStatusTimers)
        this.refreshStatusTimers = null
      }
      this.refreshStatusTimers = setInterval(() => {
        if (typeof ShareBox === 'function') ShareBox.setSharingUI(this.id, false)
      }, 2000)
    }
  },
  beforeDestroy () {
    mediaObj.rId = ''
    if (this.loadDomTimers) clearInterval(this.loadDomTimers)
    if (this.refreshStatusTimers) clearInterval(this.refreshStatusTimers)
  }
}
</script>
<style lang="less" scoped>
.shareBox {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1002;
  text-align: left;
  .share_title_select {
    height: 36px;
    border-bottom: 1px solid #666;
    padding: 6px 6px 0 12px;
    .multiple_select {
      float: right;
      width: 166px;
      ::v-deep .dropdownDefault {
        border-radius: 2px;
        height: 25px;
        line-height: 25px;
      }
      ::v-deep .dropdown_menu {
        top: 28px;
      }
    }
  }
}
.share_display {
  display: block;
}
</style>
