<template>
  <el-dialog
    :title="$t(`lang.${title}`)"
    :visible.sync="shows"
    width="1130px"
    :center="true"
    :close-on-click-modal="false"
    class="receverControl homeControl"
    @close="close()">
    <div
      class="content scrollbar"
      @click="showInfo = false">
      <div class="top clearfix">
        <div class="lefts m-r-20">
          <div class="clearfix">
            <div class="left title">
              <span
                class="title-name"
                :title="currentInfoNew && currentInfoNew.name? currentInfoNew.name : ''">{{ currentInfoNew && currentInfoNew.name? currentInfoNew.name : '' }}</span>
              <span v-if="currentInfoNew && currentInfoNew.type == 'R'">
                <i
                  @click.stop="showInfoFn"
                  class="el-icon-info"
                  v-popover:popover></i>
                <span
                  class="abosulte"
                  v-if="showInfo">
                  <i class="el-icon-caret-top"></i>
                </span>
              </span>
            </div>
            <!--下拉选择-->
            <div class="right selectToLive">
              <SourceSelect
                v-if="currentInfoNew && currentInfoNew.type.toLowerCase() == 'r'"
                :receiverItemOps="currentInfoNew"
                :socketItemData="currentSocketData"
                :socketRInfo="getKernelStateObj"
                @changeList="isSelectedNew" />
              <SelectSourceR
                v-else
                :sourceItem="currentInfoNew"
                @getSelectInfo="getSelectInfo"
                :hadSelectDevice="isSelectDevice"
                :analyzeKey="currentInfoNew.type.toLowerCase() == 'r' ? 'newHomeVideoControlChangeR' : 'newHomeVideoControlChangeSource'"
                :type="listItemOps.type.toLowerCase() == 'r' ? 'R' : 'T'"
                @changeHadSelectDevice="changeHadSelectDevice"
                class="selectSourceR left">
              </SelectSourceR>
              <videoButton
                :sourceItem="currentInfoNew"
                :hadSelectDevice="isSelectDevice"
                :flag="listItemOps.type.toLowerCase() == 'r' ? 'R' : 'T'"
                :deviceList="deviceList"
                :selectType="selectType"
                :socketRInfo="getKernelStateObj"
                @changeHadSelectDevice="changeHadSelectDevice"
                @changeSourceRId="changeSourceRId"
                @stopRState="stopRState"
                class="videoButton right">
                <!-- {{ currentInfoNew }} -->
              </videoButton>
            </div>
          </div>
          <div class="clearfix">
            <div class="audio left">
              <!-- 音量住区域 -->
              <!-- <div class="graduateModule f_left">
                    <div class="graduateModule-bg"></div>
                </div> -->
              <!-- 音量显示区域 -->
              <div class="audio-module">
                <audios
                  :rid="rid"
                  :source="currentInfoNew"
                  :pageRWebsocket="{}"></audios>
              </div>
            </div>
            <div class="left">
              <video-c
                :height="videoHeight"
                :width="videoWidth"
                :source="sourceInfo"
                :deviceType="deviceType"
                :rid="rId"
                :sid="currentInfoNew.peerId"
                :status="currentInfoNew.status"
                :socketItemData="currentSocketData"
                :delayVal="setDelayInfo.DelaySettingModel.CurrentValue" />
            </div>
          </div>
        </div>
        <div
          class="lefts rightModule mediaSourceList"
          :id="sourceId"
          :rId="receiverId"
          :share-data="currentSocketData.shareInfo || ''">
          <!--media相关-->
          <div
            class="shareToggleIcon"
            style="float: left;;height: 24px;"
            v-show="selectRIsLive && ifOpenMediaService && mediaLoaded && currentInfoNew.type != 'R'">
            <span
              class="iconfont i-share"
              :title="$t('lang.i18n_moreShare')"
              @click="toggleShareBox()"></span>
            <span
              v-show="currentSocketData.shareSize && currentSocketData.shareSize != 0"
              class="sharingNum globalNum">{{ currentSocketData.shareSize }}</span>
          </div>
          <!--media框-->
          <span
            class="iconfont i-closeInput closeMediaIcon"
            style="top: 47px;"
            @click="toggleShareBox()"
            v-show="shareFlag && selectRIsLive"></span>
          <MediaRBox
            v-if="selectRIsLive && ifOpenMediaService && shareFlag"
            :shareFlag="selectRIsLive && shareFlag"
            :rId="receiverId"
            :sId="sourceId"
            :id="sourceId"
            :sType="currentInfoNew.type"
            :shareInfo="currentSocketData.shareInfo"
            class="homeMediaBox" />
          <div class="m-auto">
            <div class="button-box clearfix">
              <span
                v-if="currentInfoNew.type == 'R'"
                class="right"
                @click="showIpStreamOut(true)">
                <i class="iconfont icon-share"></i>{{ $t("lang.IPStreamOut") }}</span>
              <selectBindIp
                class="right"
                v-if="showConfigT"
                className="right"
                :bindPeerId="sourceId"></selectBindIp>
              <span
                v-if="showCamera && currentInfoNew.status != '0'"
                class="right"
                @click.stop="selectCameraType('00')"
                style="color: #33ab4f">
                {{ $t(anyWhereInfo) }}
                <i
                  class="iconfont"
                  :class="dropDownAnywhere ? 'icon-zhankai1' : 'icon-shouqi1'"
                  style="font-size:15px;padding-left: 6px;color: #fff"></i>
              </span>
            </div>
            <div
              class="adjustCamera"
              v-show="showCameraBox">
              <el-tooltip placement="top">
                <div slot="content">
                  {{ $t("lang.manPanTitle1") }}<br />{{ $t("lang.manPanTitle2") }}
                </div>
                <div
                  class="operate"
                  :class="{ active: isManual }"
                  @click.stop="selectCameraType('11')">
                  <i class="iconfont icon-manualpan"></i>
                  <p>{{ $t("lang.manualPan") }}</p>
                </div>
              </el-tooltip>
              <el-tooltip placement="top">
                <div slot="content">
                  {{ $t("lang.autoPanTitle1") }}<br />{{ $t("lang.autoPanTitle2") }}
                </div>
                <div
                  class="operate"
                  :class="{ active: isAuto }"
                  @click.stop="selectCameraType('21')">
                  <i class="iconfont icon-autopan"></i>
                  <p>{{ $t("lang.autoPan") }}</p>
                </div>
              </el-tooltip>
              <div
                class="button"
                @click.stop="selectCameraType('40')"
                :class="{ disabled: !operateCameraType }">
                {{ $t("lang.reset") }}
              </div>
            </div>
            <div class="bitrateAndDelay">
              <delay-silder
                :rid="rId ? [rId] : []"
                :delayInfo="setDelayInfo"
                width="300px"
                @set="setDelay"></delay-silder>
              <bitrate-silder
                :rid="rId ? [rId] : []"
                :bitInfo="setBitrateInfo"
                width="300px"
                @set="setBitrate"></bitrate-silder>
            </div>
          </div>
        </div>
        <R-Info77
          :rId="rId"
          :showInfo="showInfo"
          :liveStatus="currentSocketData && currentSocketData.status == '2'"></R-Info77>
      </div>
      <div class="clearfix">
        <div class="left chart-module">
          <div class="title clearfix">
            <div class="left">
              {{ $t("lang.quality") }}
              <span class="green">{{ receiverInfo.QualityMeasureOnGUI || 0 }}Kbps
              </span>
            </div>
            <div class="right">
              <select-menu
                :data="bitrateList"
                :selectVal="bitrateVal"
                :noTranslate="true"
                :callback="changeBit"></select-menu>
            </div>
          </div>
          <div class="chart-box">
            <quality-chart
              :defaultQuatly="defaultQuatly"
              ref="qualityChart"
              :qualityVal="receiverInfo.QualityMeasureOnGUI"
              :mutilColors="mutilColors"
              :changeColorVal="receiverInfo.ErrorRateLastSec"
              :yAxis="yAxis"
              :height="200"
              :workMode="getKernelStateObj.WorkMode" />
          </div>
        </div>
        <div class="right qualityInfo">
          <el-row :gutter="20">
            <el-col :span="6">
              <div>
                <p>
                  {{ receiverInfo.ErrorRateLastNSec ? receiverInfo.ErrorRateLastNSec + "%" : 0 + "%" }}
                </p>
                {{ $t("lang.ErrorRate8s") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ receiverInfo.ReSendRateLastNSec ? receiverInfo.ReSendRateLastNSec + "%" : 0 + "%" }}
                </p>
                {{ $t("lang.LineQuality8s") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  1:
                  <span v-show="isShowSpan1">N/A</span>
                  <span v-show="!isShowSpan1">{{ getKernelStateObj.CurrentTPackBatteryRaw ? getKernelStateObj.CurrentTPackBatteryRaw.BAT1VAL + "%" : "0%" }}
                  </span>&nbsp;&nbsp; 2:
                  <span v-show="isShowSpan2">N/A</span>
                  <span v-show="!isShowSpan2">{{ getKernelStateObj.CurrentTPackBatteryRaw ? getKernelStateObj.CurrentTPackBatteryRaw.BAT2VAL + "%" : "0%" }}
                  </span>
                </p>
                {{ $t("lang.tBattery") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ getKernelStateObj.CurrentTFormat ? getKernelStateObj.CurrentTFormat .ReadableName : "N/A" }}
                </p>
                {{ $t("lang.TFormat") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ receiverInfo.ErrorRateLastSec ? receiverInfo.ErrorRateLastSec + "%" : 0 + "%" }}
                </p>
                {{ $t("lang.ErrorRate1s") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ receiverInfo.ReSendRateLastSec ? receiverInfo.ReSendRateLastSec + "%" : 0 + "%" }}
                </p>
                {{ $t("lang.LineQuality1s") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ getKernelStateObj.CurrentTInputType ? getKernelStateObj .CurrentTInputType .ReadableName : "N/A" }}
                </p>
                {{ $t("lang.TInput") }}
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>
                  {{ getKernelStateObj.CurrentOutputFormat2 ? getKernelStateObj .CurrentOutputFormat2 .ReadableName : "NTSC" }}
                </p>
                {{ $t("lang.RFormat") }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="ip-stream-out">
      <i
        class="el-icon-caret-bottom drop-down"
        @click="showIpStreamOut(false)"></i>
      <ip-stream-out
        v-if="show.ipStreamOut && Number(rVersion) < 7.5"
        :show="show"
        :rid="rId"
        :lang="lang"
        :receiverWebsocket="pageRWebsocket"
        :mediaUrl="states.baseUrl.mediaApiUrl"
        :rVersion="currentInfoNew.version"
        :source="{ peerId: sourceInfo.livePeerId }"
        :useOldR="false"
        :CurrentOutputFormat2="getKernelStateObj.CurrentTFormat && getKernelStateObj.CurrentTFormat.ReadableName"
        @selectOtherR="startWebrtc"
        :voiceInfo="voiceInfo"></ip-stream-out>
      <ip-stream-out75
        v-else-if="show.ipStreamOut && Number(rVersion) >= 7.5"
        :rid="rId"
        :lang="lang"
        :useOldR="false"
        :receiverWebsocket="pageRWebsocket"
        :mediaUrl="states.baseUrl.mediaApiUrl"
        :CurrentOutputFormat2="getKernelStateObj.CurrentTFormat && getKernelStateObj.CurrentTFormat.ReadableName"
        @selectOtherR="startWebrtc"
        :rVersion="rVersion"
        :voiceInfo="voiceInfo">
      </ip-stream-out75>
    </div>
  </el-dialog>
</template>
<script>
import Tool from '@/assets/js/utils.js'
import videoC from '@/components/VideoC'
import selectMenu from '@/components/SelectMenu'
import Audios from '@/views/MySource/voice'
import { mapState } from 'vuex'
import { BitrateList } from '@/config.js'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import bitrateSilder from '@/components/bitrateSilder'
import delaySilder from '@/components/delaySilder'
import SelectSourceR from '@/components/SelectSourceR'
import videoButton from '@/components/VideoButton'
import qualityChart from '@/components/qualityChart'
import Bus from '@/assets/js/vueBus.js'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import IpStreamOut from './IPStreamOut1.vue'
import IpStreamOut75 from './IPStreamOut75.vue'
import MediaRBox from '@/components/mediaRbox'
import { handleSource } from '@/assets/js/shareCommon.js'
import SourceSelect from '@/components/sourceSelect'
import RInfo77 from '@/components/rInfo77'
import selectBindIp from '../receivercontrol/selectBindIp'

export default {
  props: {
    rid: String, // 当前的Rid
    currentInfo: Object, // 当前R或者source信息
    close: Function, // 关闭弹窗触发的事件
    title: String, // 弹出窗的标题
    listItemOps: Object, // 接口返回的数据
    currentSocketData: {
      default: function () {
        return {}
      },
      type: Object
    },
    mediaBoxOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      shows: true,
      videoHeight: '300px',
      videoWidth: '512px',
      sourceInfo: {},
      deviceType: 'R',
      qualityTotalPoints: 300,
      receiverInfo: {},
      videoInfo: {},
      bitrateList: BitrateList,
      qualityVal: 0,
      showInfo: false,
      setBitrateInfo: {
        BitrateSettingModel: {}
      },
      setDelayInfo: {
        DelaySettingModel: {}
      },
      getKernelStateObj: {},
      isSelectDevice: false, // 下拉中是否重新选择了Source或者R
      deviceList: '', // 向Take按钮传送的值
      bitrateVal: 6,
      rId: this.rid,
      rVersion: this.currentInfo && this.currentInfo.version && this.currentInfo.version.slice(0, 3) || '',
      recRemain: '',
      playerVersion: '',
      mutilColors: [
        { max: 0, color: 'Lime' },
        { min: 0, max: 10, color: 'orange' },
        { min: 10, color: 'red' }
      ],
      yAxis: {
        // y轴坐标
        tickAmount: 5, // 默认显示多少个刻度
        max: 6000 // 默认显示的最大值
      },
      // camera功能相关
      showCameraBox: false,
      operateCameraType: '',
      sendBtn: false,
      show: {
        ipStreamOut: false
      },
      lang: this.$i18n.locale,
      isAuto: false,
      isManual: false,
      analyzeKey: '',
      switchTime: '',
      // media相关
      shareFlag: this.mediaBoxOpen,
      receiverId: this.listItemOps.livePeerId ? this.listItemOps.livePeerId.split(';')[0] : '',
      sourceId: this.currentSocketData.peerId,
      selectRIsLive: this.currentSocketData && this.currentSocketData.status == '2', // 选中的R是否正在和当前source进行live
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.home || false,
      mediaLoaded: false,
      hasStopSelectR: false,
      currentInfoNew: this.currentInfo,
      voiceInfo: {},
      dropDownAnywhere: true,
      anyWhereInfo: 'lang.adjustCamera',
      defaultQuatly: 0,
      anyWhereLastTime: 0,
      selectType: ''
    }
  },
  components: {
    videoC,
    selectMenu,
    Audios,
    bitrateSilder,
    delaySilder,
    SelectSourceR,
    videoButton,
    qualityChart,
    IpStreamOut,
    MediaRBox,
    IpStreamOut75,
    SourceSelect,
    RInfo77,
    selectBindIp
  },
  computed: mapState({
    // 映射
    State: state => state,
    pageRequest: state => state.websocket.pageRequest,
    pageRWebsocket: state => state.websocket.pageRWebsocket,
    liveOnHoldFlag: state => state.featureFlag.liveOnHoldFlag,
    isShowSpan1: function () {
      if (!this.getKernelStateObj.CurrentTPackBatteryRaw) return false
      const bat1 = this.getKernelStateObj.CurrentTPackBatteryRaw.BAT1
      if (bat1 == -1 || bat1 == null || bat1 == '') {
        return true
      } else {
        return false
      }
    },
    isShowSpan2: function () {
      if (!this.getKernelStateObj.CurrentTPackBatteryRaw) return false
      const bat2 = this.getKernelStateObj.CurrentTPackBatteryRaw.BAT2
      if (bat2 == -1 || bat2 == null || bat2 == '') {
        return true
      } else {
        return false
      }
    },
    showConfigT: function () {
      const { type, version, platform, status } = this.currentInfoNew
      const versionNumber = version ? Number(version.slice(0, 3)) : ''
      if (
        type == 'T' &&
        versionNumber >= 5.6 &&
        platform.trim() != '' &&
        (platform < 10 || platform > 14) &&
        status != 0
      ) {
        return true
      } else {
        return false
      }
    },
    showCamera: function () {
      const { platform } = this.currentInfoNew
      if (platform == 10 || platform == 11 || platform == 12) {
        return true
      } else {
        return false
      }
    },
    ...mapState('common', {
      Webrtc: state => state.webrtc,
      states: state => state
    })
  }),
  watch: {
    currentSocketData (val, oldval) {
      if (this.ifOpenMediaService && val.type.toLowerCase() != 'r') {
        if (val.status != oldval.status) {
          this.shareFlag = false
        }
        if (this.hasStopSelectR) {
          this.receiverId = val.livePeerId
        }
        this.selectRIsLive = val && val.gridLivePeerId != null && val.gridLivePeerId.includes(this.receiverId)
        this.handleList(val)
      }
    },
    'getKernelStateObj.CurrentTStrIdHex': function () {
      this.$refs.qualityChart.createQuality()
    },
    'getKernelStateObj.WorkMode': function () {
      if (this.getKernelStateObj.WorkMode == '0') {
        this.$refs.qualityChart.createQuality()
      }
    },
    '$i18n.locale': function (val) {
      this.bitrateList[0].label = this.$t('lang.autoSelectQuality')
    },
    currentInfo: function (val) {
      this.currentInfoNew = val
      if (val.livePeerType && val.livePeerType.toLowerCase() == 'r') {
        this.rId = val.livePeerId
        if (this.rId) this.sendAll()
      } else {
        if (val.status == '2') this.sendAll()
      }
      if (!this.isSelectDevice) {
        // 如果没有重新选择
        if (val.status == 2) {
          // 如果正在live 将最新的R Id发送给Take组件
          this.deviceList = val.livePeerId + ',' + val.peerId
        }
      }
    }
  },
  created () {
    if (this.currentInfoNew && this.currentInfoNew.status == 2) {
      // 点击进去弹窗 如果在live 将live的R和source Id传递给liveButton组件
      if (this.listItemOps.type.toLowerCase() == 'r') {
        const sourceId = this.currentInfoNew.livePeerId
        this.deviceList = sourceId + ',' + this.listItemOps.peerId
      } else {
        const rid = this.currentInfoNew.livePeerId ? this.currentInfoNew.livePeerId.split(';')[0] : ''
        this.deviceList = this.currentInfoNew.peerId + ',' + rid
      }
    }
    if (this.ifOpenMediaService) {
      Bus.$on('hadClickedBtn', () => {
        this.shareFlag = false
      })
      this.isCompleteLoad()
    }
    this.getUserBehavior()
    this.bitrateList[0].label = this.$t('lang.autoSelectQuality')
  },
  mounted () {
    Bus.$on('changePanBtnInit', obj => {
      if (obj == 'close') {
        this.showCameraBox = false
        this.dropDownAnywhere = false
      } else {
        this.isAuto = obj.auto
        this.isManual = obj.manual
        if (this.isAuto) {
          this.anyWhereInfo = 'lang.autoPan'
        }
        if (this.isManual) {
          this.anyWhereInfo = 'lang.manualPan'
        }
      }
    })
    Bus.$on('changIpLanguage', lang => {
      this.lang = lang
    })
    this.sendAll()
  },
  methods: {
    isSelectedNew (obj) {
      this.isSelectDevice = obj.isSelectNewR
      this.deviceList = obj.deviceList
      this.selectType = obj.selectType
    },
    stopRState () {
      this.hasStopSelectR = true
    },
    handleList (val) {
      if (
        !this.receiverId &&
        val.type.toLowerCase() != 'r' &&
        val.status == 2
      ) {
        this.receiverId = val.gridLivePeerId.split(';')[0]
      }
      this.currentSocketData = handleSource(
        this.sourceId,
        this.receiverId,
        this.selectRIsLive,
        val
      )
    },
    isCompleteLoad () {
      if (typeof mediaObj != 'undefined') {
        this.mediaLoaded = true
      } else {
        setTimeout(() => {
          this.isCompleteLoad()
        }, 100)
      }
    },
    // 控制分享box的显示隐藏
    toggleShareBox () {
      if (this.currentSocketData && this.currentSocketData.status != '2') return
      this.shareFlag = !this.shareFlag
      if (this.shareFlag) {
        newHomeAnalyze('newHomeOpenShare', this.$route.path)
      } else {
        newHomeAnalyze('newHomeCloseShare', this.$route.path)
      }
      if (!this.shareFlag && typeof ShareBox === 'function' && typeof ShareBox.close === 'function') {
        ShareBox.close(this.sourceId)
      }
    },
    startWebrtc (rid, notChangeRStatus) {
      Bus.$emit('selectOtherR', '', 'notChangeRStatus')
    },
    sendAll () {
      if (this.rId && !this.sendBtn) {
        this.sendTaskInfo()
        this.sendRWebsoket({ categoryId: '2152857600', operationType: '101' }, 'getReceiverInfo_videoControl') // R的信息
      }
      if (this.rId && !this.sendBtn) {
        if (this.currentInfoNew.status == '2') {
          this.sendBtn = true
          this.sendRWebsoket({ categoryId: '2152857600', operationType: '102' }, 'getVideoInfo_videoControl') // 控制视频的变量
          this.sendRWebsoket({ categoryId: '2152867840', operationType: '200' }, 'getVolumeInfo_videoControl', 200) // 音量柱
          this.sendRWebsoket({ categoryId: '2152866048', operationType: '101' }, 'getSettingInfo_videoControl') // 获取R错误率format等信息
        }
        if (this.currentInfoNew.status == '1' && this.liveOnHoldFlag) {
          this.sendRWebsoket({ categoryId: '2152857600', operationType: '102' }, 'getVideoInfo_videoControl') // 控制视频的变量
        }
      }
    },
    sendRWebsoket (data, key, time) {
      this.pageRWebsocket.send({
        // 视频信息
        message: { ...data, rid: this.rId },
        time: time || 1000,
        key,
        success: res => {
          // 控制视频的变量
          const result = res.data ? JSON.parse(res.data) : {}
          if (key == 'getVideoInfo_videoControl') {
            this.$store.state.common.deviceListCurrentRInfoData = [res]
            this.getKernelStateObj = result
            // Live On Hold
            if (this.liveOnHoldFlag && this.currentSocketData?.status == 1 && result && result.WorkMode == 1) {
              this.deviceList = result.CurrentTStrIdHex.toLowerCase() + ',' + this.currentSocketData.peerId
            }
          }
          if (key == 'getReceiverInfo_videoControl') {
            this.$store.state.common.deviceListReceiverDataData = [res]
            this.receiverInfo = result
          }
          if (key == 'getVolumeInfo_videoControl') {
            this.State.common.deviceListVolumeChartData = [res]
            this.voiceInfo = result
          }
          if (key == 'getSettingInfo_videoControl') {
            this.setBitrateInfo = result
            this.setDelayInfo = result
          }
          if (this.pageRWebsocket.sendMsgs) {
            const websoket = this.pageRWebsocket.sendMsgs[key]
            if (websoket) {
              websoket.message = { ...data, rid: this.rId }
            }
          }
        }
      })
    },
    sendTaskInfo () {
      const data = {
        type: 'taskInfo',
        module: 'taskInfoTR',
        data: { peerIds: this.rId }
      }

      this.pageRequest.send({
        message: data,
        time: 1000,
        key: 'taskInfoTR',
        success: res => {
          this.sourceInfo = res.result ? res.result[0] : {}
          const taskInfoTObj = this.pageRequest.sendMsgs.taskInfoTR
          if (taskInfoTObj) {
            taskInfoTObj.message = {
              type: 'taskInfo',
              module: 'taskInfoTR',
              data: { peerIds: this.rId }
            }
          }
        }
      })
    },
    // 获取用户行为记录
    getUserBehavior () {
      const params = {
        pn: location.hash.split('?')[0].slice(2),
        remark: this.rid,
        serverName: 'ccp'
      }
      this.axios.get('/ccp/tvucc-user/userBehavior/get', {
        params: params
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          const data = res.data.result && res.data.result[this.rid] && JSON.parse(res.data.result[this.rid])
          if (!data) {
            this.changeBit(0, true)
            this.defaultQuatly = 0
          } else {
            this.changeBit(data.data, true)
            this.defaultQuatly = data.data
          }
        }
      }).catch(() => {})
    },
    // 记录用户选择的流量图显示
    recordUserBehavior (data) {
      const parmas = {
        pn: location.hash.split('?')[0].slice(2),
        content: JSON.stringify({ data, rid: this.rid }),
        remark: this.rid
      }
      this.axios
        .post('/ccp/tvucc-user/userBehavior/set', parmas)
        .then(res => { }).catch(() => {}).catch(() => {})
    },
    // 更改比特率
    changeBit (val, init) {
      this.defaultQuatly = val
      const oldVal = val
      if (val == 0) {
        val = 6
      }
      // 更新流量图坐标
      const maxValue = val * 1000
      this.bitrateVal = oldVal
      this.yAxis.max = maxValue
      /*
      * this.chart.yAxis[0].update({ //更新坐标轴
      *     max:maxValue,
      *     tickInterval:maxValue/4
      * });
      */
      if (init) return
      newHomeAnalyze('newHomeVideoControlChangeQulity', this.$route.path)
      this.recordUserBehavior(oldVal)
    },
    // 显示Rinfo
    showInfoFn () {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        newHomeAnalyze('newHomeVideoControlRInfoOpen', this.$route.path)
      } else {
        newHomeAnalyze('newHomeVideoControlRInfoClose', this.$route.path)
      }
      /*
      * recRemain:'',
      * playerVersion:''
      */
      if (!this.recRemain && this.rId) {
        const params = Object.assign(
          { rid: this.rId },
          receviceParams.getRInfo.recTimeRemain
        )
        this.axios.post(Receiver().getRInfo.recTimeRemain, params).then(res => {
          if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
            if (res.data.result.includes('StrRemainTime')) {
              this.recRemain = JSON.parse(
                res.data.result
              ).StrRemainTime
            } else {
              this.recRemain = res.data.result
            }
          }
        }).catch(() => {})
      }
      if (!this.playerVersion && this.rId) {
        const params = Object.assign(
          { rid: this.rId },
          receviceParams.getRInfo.playBackModel
        )
        this.axios
          .post(Receiver().getRInfo.playBackModel, params)
          .then(res => {
            if (
              res.data.errorCode == '0x0' &&
              res.data.errorInfo == 'Success'
            ) {
              if (res.data.result) {
                this.playerVersion = JSON.parse(
                  res.data.result
                ).PlayerVersion
              }
            }
          }).catch(() => {})
      }
    },
    // 获取下拉组件传递的信息
    getSelectInfo (data) {
      // 获取SelectSourceR.vue组件的参数    this.hasStopSelectR = false;
      this.hasStopSelectR = false
      this.deviceList = data.deviceList // 监听SelectSourceR.vue组件传递过来的参数
      this.receiverId = data.deviceList.split(',')[1]
      this.selectRIsLive =
        this.currentSocketData &&
        this.currentSocketData.gridLivePeerId != null &&
        this.currentSocketData.gridLivePeerId.includes(this.receiverId)
    },
    // 接收liveButton是否操作完当前选择的source或者R 或者接收 select下拉组件是否重新选择了source或R
    changeHadSelectDevice (val) {
      this.isSelectDevice = val
    },
    // 更改R
    changeSourceRId (id) {
      this.rId = id
      if (!this.sendBtn) {
        this.sendAll()
      }
    },
    // 显示ipstramOUt
    showIpStreamOut (btn) {
      const params = Object.assign(
        { rid: this.rId },
        receviceParams.ExternalEncoder.externalEncoderInfo
      )
      if (btn) {
        if (this.show.ipStreamOut) return
        this.axios.post(Receiver().ExternalEncoder.externalEncoderInfo, params).then(res => {
          const data = res.data
          if (data.errorCode == '0x0' && data.errorInfo == 'Success' && !JSON.parse(data.result).errorInfo) {
            this.show.ipStreamOut = true
            setTimeout(() => {
              if (this.getKernelStateObj.WebRM3U8PlaybackUrl) {
                this.Webrtc.stopCall(this.getKernelStateObj.WebRM3U8PlaybackUrl)
              }
            }, 1000)
            $('.ip-stream-out').animate({ top: 30 }, 1000)
            newHomeAnalyze('newHomeVideoControlIpStremOutOpen', this.$route.path)
          } else {
            if (data.result) {
              if (JSON.parse(data.result).errorCode == '0x80520101') {
                this.$message.error(this.$t('lang.moduleNotFound'))
              }
            } else {
              Tool.ToTip(data.errorCode, 'error', res.data.errorInfo)
            }
          }
        }).catch(() => {})
      } else {
        $('.ip-stream-out').animate({ top: 723 }, 1000)
        setTimeout(() => {
          this.show.ipStreamOut = false
        }, 1000)
        newHomeAnalyze('newHomeVideoControlIpStremOutClose', this.$route.path)
      }
    },
    selectCameraType (type) {
      const nowDate = new Date().getTime()
      const nowNum = new Date().valueOf()
      if (this.anyWhereLastTime == 0) {
        this.anyWhereLastTime = nowNum
      } else {
        if ((nowNum - this.anyWhereLastTime) > 2000) {
          this.anyWhereLastTime = nowNum
        } else {
          return
        }
      }
      if (type == '00') {
        if (this.showCameraBox) {
          newHomeAnalyze('newHomeCloseCamera', this.$route.path)
        } else {
          newHomeAnalyze('newHomeOpenCamera', this.$route.path)
        }
        this.showCameraBox = !this.showCameraBox
        this.dropDownAnywhere = !this.dropDownAnywhere
      }
      if (type == '30') {
        newHomeAnalyze('newHomeCloseCamera', this.$route.path)
      }
      if (type == '40') {
        newHomeAnalyze('newHomeCameraReset', this.$route.path)
      }
      if (type == '11') {
        if (nowDate - this.switchTime < 5000) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.modeSwitching')
          })
          return
        }
        if (this.isManual) {
          type = '10'
          this.isManual = false
        } else {
          this.isManual = true
          this.isAuto = false
        }
        this.switchTime = nowDate
        newHomeAnalyze('newHomeCameraManual', this.$route.path)
      }
      if (type == '21') {
        if (nowDate - this.switchTime < 5000) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.modeSwitching')
          })
          return
        }
        if (this.isAuto) {
          type = '20'
          this.isAuto = false
        } else {
          this.isAuto = true
          this.isManual = false
        }
        newHomeAnalyze('newHomeCameraAuto', this.$route.path)
      }
      this.operateCameraType = type
      Bus.$emit('changePanOperatetype', this.operateCameraType)
    },
    // 展示、关闭camera的操作
    showCameraOperate () {
      this.show.showCameraBox = !this.show.showCameraBox
      this.dropDownAnywhere = !this.dropDownAnywhere
      if (this.show.showCameraBox) {
        newHomeAnalyze(
          'newHomeVideoControlCameraOpen',
          this.$route.path
        )
      } else {
        newHomeAnalyze(
          'newHomeVideoControlCameraClose',
          this.$route.path
        )
      }
    },
    setDelay (val) {
      newHomeAnalyze('newHomeVideoControlChangeDelay', this.$route.path)
    },
    setBitrate (val) {
      newHomeAnalyze(
        'newHomeVideoControlChangeBitrate',
        this.$route.path
      )
    },
    stopWebsoket () {
      this.$createWebsocket.pageRequest('stop', 'taskInfoTR')
      this.$createWebsocket.pageRWebsocket(
        'stop',
        'getVideoInfo_videoControl'
      )
      this.$createWebsocket.pageRWebsocket(
        'stop',
        'getReceiverInfo_videoControl'
      )
      this.$createWebsocket.pageRWebsocket(
        'stop',
        'getVolumeInfo_videoControl'
      )
      this.$createWebsocket.pageRWebsocket(
        'stop',
        'getSettingInfo_videoControl'
      )
      this.sendBtn = false
    }
  },
  beforeDestroy () {
    this.stopWebsoket()
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-select-dropdown__item {
  width: 100% !important;

  .check {
    padding: 0;
  }
}

.receverControl {
  ::v-deep .el-dialog {
    padding: 0;

    .el-dialog__body {
      padding: 0;
      position: relative;
      overflow: hidden;
    }

    .el-dialog__header {
      margin: 0 20px;
    }
  }

  line-height: 26px;

  .ip-stream-out {
    width: 1130px;
    padding: 20px 20px 0;
    height: 695px;
    position: absolute;
    /*top: 663px;*/
    left: 0px;
    background: #565656;
    z-index: 2000;

    .drop-down {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 18px;
      z-index: 2001;
      cursor: pointer;
    }

    ::v-deep .el-main {
      background: #565656;
    }

    ::v-deep .title {
      background: #565656 !important;
    }

    ::v-deep .el-slider__runway {
      background: #333;
    }
  }

  .top {
    position: relative;
    margin-bottom: 25px;
    margin-left: 20px;
    display: flex;

    .lefts {
      .title {
        font-size: 22px;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 5px;

        .title-name {
          display: inline-block;
          max-width: 275px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          position: relative;

          .abosulte {
            position: absolute;
            top: 6px;
            left: -4px;
            z-index: 1000;

            i {
              font-size: 30px;
              color: #565656;
            }
          }

          .el-icon-info {
            font-size: 14px;
            color: #898989;
            cursor: pointer;
            position: relative;
            top: -11px;
            margin-left: 5px;
          }
        }
      }
    }

    .rightModule {
      position: relative;
      flex: 1;

      .m-auto {
        width: 300px;
        margin: 0 auto;
      }

      // media相关
      .shareToggleIcon {
        position: absolute;
        top: 0;
        left: 0.2rem;
        line-height: 30px;
        .i-share:before {
          font-size: 30px;
        }

        .sharingNum.globalNum {
          bottom: 16px;
        }
      }

      .homeMediaBox {
        height: calc(100% - 40px);
        margin-top: 40px;
      }
    }

    .info {
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 10000;
      background: #565656;
      line-height: 26px;
      padding: 5px 10px;
      width: 90%;

      .green {
        color: #33ab4f;
      }

      .titles {
        text-align: left;
      }

      ::v-deep .el-col {
        padding: 0 5px !important;
      }

      ::v-deep .el-row {
        margin: 0 !important;
      }
    }
  }

  .adjustCamera {
    text-align: center;
    width: 300px;
    height: 110px;
    border-bottom: 2px solid #33ab4f;

    .operate {
      float: left;
      padding-top: 10px;
      height: 60px;
      color: #666;
      margin: 0 13px 40px 5px;
      cursor: pointer;

      i {
        font-size: 64px;
      }

      &.active {
        color: #33aa50;
      }
    }

    .operate:nth-child(2) {
      margin-left: 30px;
    }

    .buttons {
      float: left;
    }

    .button {
      /*display: inline-block;*/
      float: right;
      color: #33aa50;
      border: 1px solid #33aa50;
      width: 80px;
      height: 30px;
      line-height: 28px;
      border-radius: 2px;
      margin: 14px 0px 0px 0px;
      cursor: pointer;

      &:hover {
        background: #33aa50;
        color: #fff;
      }

      &.disabled {
        color: #fff;
        background: gray;
        border: 1px solid gray;

        &:hover {
          color: #fff;
          background: gray;
          border: 1px solid gray;
        }
      }
    }
  }

  .bitrateAndDelay {
    margin-top: 33px;
  }

  .m-r-20 {
    margin-right: 20px;
  }

  .button-box {
    margin-bottom: 1%;
    font-size: 16px;

    span {
      cursor: pointer;
    }

    i {
      margin-right: 5px;
      font-size: 16px;
      color: #33ab4f;
    }
  }

  .audio {
    .graduateModule {
      width: 47px;
      height: 373px;
      float: left;
    }

    .graduateModule-bg {
      background: url('../../assets/img/graduateG.png') no-repeat;
      height: 100%;
      background-position: right;
    }

    .audio-module {
      margin-right: 10px;
      height: 300px;
      float: left;
      text-align: center;
    }
  }

  .chart-module {
    line-height: 36px;

    .green {
      color: #33ab4f;
      margin-left: 10px;
    }

    ::v-deep .el-input__inner {
      height: 36px !important;
      line-height: 36px !important;
    }
  }

  .content {
    padding: 30px 0 60px 25px;
    margin: 0 20px;

    ::v-deep .voice .audio_box {
      margin-left: 5px;
    }

    .qualityInfo {
      width: 610px;
      text-align: center;
      color: #999;
      margin-top: 65px;

      p {
        color: #33ab4f;
        margin-bottom: 5px;
        font-size: 16px;
      }

      .el-col {
        margin-bottom: 20px;
      }
    }
  }

  .selectToLive {
    .sourceSearch {
      width: 250px!important;
    }
    .selectSourceR {
      width: 2.2rem !important;
      height: 0.32rem;

      ::v-deep input {
        height: 100%;
      }

      ::v-deep .el-select-dropdown {
        width: 2.2rem;
      }
    }

    ::v-deep .el-input.is-disabled {
      outline: none;
    }

    .videoButton {
      width: 0.8rem;

      ::v-deep button {
        float: right;
      }

      ::v-deep .is-disabled {
        background: #616161;
        color: #aaa;
      }
    }
  }
  .chart-box {
    width: 430px;
    height: 200px;
  }
}
</style>
