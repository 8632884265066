const language = {
  en: {
    IpStreamOut: 'IP Stream Out',
    Encoder: 'Encoder',
    CBR: 'CBR',
    Resolution: 'Resolution',
    Bitrate: 'Bitrate',
    Port: 'Port',
    ABitrate: 'ABitrate',
    AChannel: 'AChannel',
    Codec: 'Codec',
    Output: 'Output',
    Start: 'Start',
    Stop: 'Stop',
    Edit: 'Edit',
    StreamName: 'Name',
    inputPlaceholder: 'Enter Output name or URL address',
    inputNickName: 'Please input Output name',
    warnAddMsg: 'Nickname or stream url cannot be empty',
    loading: 'Loading...',
    picturesFormat: 'Only upload pictures below 640x480',
    videoLogo: 'Upload video logo completed',
    sourceFormat: 'Source Format',
    video: 'Video',
    audio: 'Audio',
    moduleNotFound: 'Module Not Found',
    pushSameStream: 'Push stream failed, the current input url is pushing',
    profileManage: 'Profile Management',
    Status: 'Status',
    AudioBitrate: 'Audio Bitrate',
    FrameRate: 'Frame Rate',
    VideoBitrate: 'Video Bitrate',
    Profiles: 'Profile',
    ProfileName: 'Profile Name',
    Add: 'Add',
    Back: 'Back',
    Setting: 'Setting',
    Yes: 'Yes',
    No: 'No',
    Auto: 'Auto',
    GOP: 'GOP',
    Scale: 'Scale',
    Level: 'Level',
    Preset: 'Preset',
    Tune: 'Tune',
    AudioEncoder: 'Audio Encoder',
    Channel: 'Channel',
    SampleRate: 'Sample Rate',
    Cancel: 'Cancel',
    Save: 'Save',
    Deinterlace: 'Deinterlace',
    GlobalQuality: 'Global Quality',
    VideoEncoder: 'Video Encoder',
    Muxer: 'Muxer',
    MustProfileName: 'Please input Profile name',
    CreateSuccess: 'Create Success',
    hasProfileName: 'The profile name already exists, please re-enter.'
  },
  zh: {
    IpStreamOut: 'IP流输出',
    Encoder: '编码器',
    CBR: 'CBR',
    Resolution: '分辨率',
    Bitrate: '比特率',
    Port: '端口',
    ABitrate: '音频比特率',
    AChannel: '声道',
    Codec: '编码器',
    Output: '输出',
    Start: '开始',
    Stop: '停止',
    Edit: '编辑',
    StreamName: '名字',
    Save: '保存',
    inputPlaceholder: '输入名字或者推流地址',
    inputNickName: '请输入用户名',
    warnAddMsg: '用户名和推流地址不能为空',
    loading: '数据加载中',
    picturesFormat: '只允许上传640x480以下的图片',
    videoLogo: '上传视频台标完成',
    sourceFormat: '信号源格式',
    video: '视频',
    audio: '音频',
    moduleNotFound: '暂未开启该功能',
    pushSameStream: '推流失败，该视频地址正在推流中',
    profileManage: '配置管理',
    Status: '状态',
    AudioBitrate: '声频码率',
    FrameRate: '频率',
    VideoBitrate: '视频码率',
    Profiles: '配置',
    ProfileName: '配置名称',
    Add: '新增',
    Back: '返回',
    Setting: '设置',
    Yes: '是',
    No: '否',
    Auto: '自动',
    GOP: 'GOP',
    Scale: 'Scale',
    Level: 'Level',
    Preset: '预设',
    Tune: 'Tune',
    AudioEncoder: '音频编码器',
    Channel: '通道',
    SampleRate: '采样率',
    Cancel: '取消',
    Deinterlace: '去隔行扫描',
    GlobalQuality: '全局质量',
    VideoEncoder: '视频编码器',
    Muxer: 'Muxer',
    MustProfileName: '请输入配置名称',
    CreateSuccess: '创建成功',
    hasProfileName: '配置名称已存在，请重新输入'
  },
  ru: {
    IpStreamOut: 'IP Stream Out',
    Encoder: 'Encoder',
    CBR: 'CBR',
    Resolution: 'Resolution',
    Bitrate: 'Bitrate',
    Port: 'Port',
    ABitrate: 'ABitrate',
    AChannel: 'AChannel',
    Codec: 'Codec',
    Output: 'Output',
    Start: 'Start',
    Stop: 'Stop',
    StreamName: 'Name',
    inputPlaceholder: 'Enter Output name or URL address',
    inputNickName: 'Please input Output name',
    warnAddMsg: 'Nickname or stream url cannot be empty',
    loading: 'Loading...',
    picturesFormat: 'Only upload pictures below 640x480',
    videoLogo: 'Upload video logo completed',
    sourceFormat: 'Source Format',
    video: 'Video',
    audio: 'Audio',
    moduleNotFound: 'Module Not Found',
    pushSameStream: 'Push stream failed, the current input url is pushing',
    profileManage: 'Profile Management',
    Status: 'Status',
    AudioBitrate: 'Audio Bitrate',
    FrameRate: 'Frame Rate',
    VideoBitrate: 'Video Bitrate',
    Profiles: 'Profile',
    ProfileName: 'Profile Name',
    Add: 'Add',
    Back: 'Back',
    Setting: 'Setting',
    Yes: 'Yes',
    No: 'No',
    Auto: 'Auto',
    GOP: 'GOP',
    Scale: 'Scale',
    Level: 'Level',
    Preset: 'Preset',
    Tune: 'Tune',
    AudioEncoder: 'Audio Encoder',
    Channel: 'Channel',
    SampleRate: 'Sample Rate',
    Cancel: 'Cancel',
    Save: 'Save',
    Deinterlace: 'Deinterlace',
    GlobalQuality: 'Global Quality',
    VideoEncoder: 'Video Encoder',
    Muxer: 'Muxer',
    MustProfileName: 'Please input Profile name',
    CreateSuccess: 'Create Success',
    hasProfileName: 'The profile name already exists, please re-enter.'
  }
}

export default language
