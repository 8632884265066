<template>
  <div id="moduleBox">
    <div class="module-left w_35 f_left m_t_15">
      <video-module
        :clearSelectR="true"
        height="158px"
        width='320px'
        :source="source"
        :useOldR="useOldR"
        deviceType="R"
        :rid="rid"></video-module>
      <div class="sourceInfo">
        <div class='title'>{{language[lang].sourceFormat}}</div>
        <div class="info">
          <span>{{language[lang].video}}: </span>
          <span>{{CurrentOutputFormat2}} -8 bit 4:2:2 YUV</span>
        </div>
        <div class="info">
          <span>{{language[lang].audio}}: </span>
          <span>2 channels, 48.0kHz, 16-bit</span>
        </div>
      </div>
      <div class="hide">{{rVersion}}</div>
      <div
        class="outputVoiceModule"
        v-if="sliderInfo.show">
        <div class="title">{{$t('lang.MainLevel')}}</div>
        <div class="content">
          <div class="setVoiceModule">
            <div class="leftVoice f_left">
              <el-slider
                :max="3"
                :min="-40"
                v-model="sliderInfo.leftValue"
                :disabled="sliderInfo.leftEnable"
                vertical
                height="150px"
                @change="(val)=>{dragEnd(val,'L')}"></el-slider>
              <div class="text_center voiceTitle">L</div>
              <div>
                <i
                  class="iconfont leftVoiceIcon"
                  :disabled="sliderInfo.leftEnable"
                  @click="externalMute('L')"
                  :class="{'icon-mute': sliderInfo.leftMute}"></i>
              </div>
            </div>
            <div class="rightVoice f_left">
              <el-slider
                :max="3"
                :min="-40"
                :disabled="sliderInfo.rightEnable"
                v-model="sliderInfo.rightValue"
                vertical
                height="150px"
                @change="(val)=>{dragEnd(val,'R')}"></el-slider>
              <div class="text_center voiceTitle">R</div>
              <div>
                <i
                  class="iconfont rightVoiceIcon"
                  :disabled="sliderInfo.rightEnable"
                  @click="externalMute('R')"
                  :class="{'icon-mute': sliderInfo.rightMute}"></i>
              </div>
            </div>
          </div>
          <div class="outputVoice">
            <div class="f_left leftOutput">
              <div class="common_bg">
                <div class="voices"></div>
              </div>
              <div class="text_center m_t_8">L</div>
            </div>
            <div class="f_left rightOutput">
              <div class="common_bg">
                <div class="voices"></div>
              </div>
              <div class="text_center m_t_8">R</div>
            </div>
            <div class="f_left audioScale"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-right w_65 f_right">
      <h3 class="info_title">
        <span class="f_left">{{language[lang].Setting}}</span>
        <span class="profileSet f_right">{{language[lang].profileManage}}&nbsp;&nbsp;
          <i
            v-if="!showLoading"
            @click="profileDialogInfo.show = true;profileDialogInfo.type = 'list'"
            class="iconfont icon-settings_btnCopy"></i>
        </span>
      </h3>
      <el-scrollbar
        style="height:100%;min-height: 300px;"
        ref="scrollView"
        v-loading="showLoading"
        :element-loading-text="language[lang].loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div
          id="slotModule"
          ref="slotModule">
          <div
            class="encoderBox"
            v-for="(item, index) in encoderListInfo"
            :key="index">
            <div>
              <el-checkbox
                :class="{grayColor:!item.Enabled}"
                @change="setEncoder(index)"
                v-model="item.Enabled"
                class="whiteFont">{{language[lang].Encoder + (index + 1)}}</el-checkbox>
              <div class="select-box">
                <el-select
                  v-model="item.CurrentSharedMemoryString"
                  @change="setEncoderInfo('SelectedEncoderSHM', item.CurrentSharedMemoryString, index)"
                  placeholder="Default"
                  :disabled="!item.Enabled"
                  :popper-append-to-body="false">
                  <el-option
                    v-for="item in pushStreamSource"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-container>
              <el-main class="slotBox">
                <el-collapse-transition>
                  <div v-show="item.Enabled">
                    <el-row
                      :gutter="20"
                      class="logoSelect">
                      <el-col :span="3">
                        <span>Logo</span>
                      </el-col>
                      <el-col :span="10">
                        <el-checkbox
                          v-model="item.IsLogoEnabled"
                          @change="changeLogoEnable(index)"
                        >{{$t('lang.pictureSize')}}</el-checkbox>
                      </el-col>
                    </el-row>
                    <!-- avatar-uploader -->
                    <el-row v-if="item.IsLogoEnabled">
                      <el-col :span="3"><span style="opacity: 0;height: 1;">123</span></el-col>
                      <el-col :span="4">
                        <el-upload
                          class="avatar-uploader"
                          action=""
                          :show-file-list="false"
                          v-if="item.IsLogoEnabled"
                          :disabled="logoUrl[index] && logoUrl[index].isEdit"
                          :auto-upload="false"
                          :on-change="(file, fileList) => {changeFile(file, fileList, index);}"
                        >
                          <img
                            class="avatar"
                            v-if="logoUrl[index] ? logoUrl[index].LogoFileBase64String : ''"
                            :src="logoUrl[index] ? 'data:image/png;base64,' + logoUrl[index].LogoFileBase64String: ''"
                          />
                          <i
                            class="el-icon-plus avatar-uploader-icon"
                            v-else
                          ></i>
                        </el-upload>
                      </el-col>
                      <el-col
                        class="logoOffset"
                        :span="16"
                        v-if="item.IsLogoEnabled"
                      >
                        <el-row>
                          <span>{{ $t('lang.offsetX') }}</span>
                          <el-input
                            class="offsetInput"
                            v-model="item.LogoXOffset"
                            :disabled="logoUrl[index] && logoUrl[index].isEdit"
                            @focus="clearRefresh"
                          ></el-input>
                          <i
                            class="el-icon el-icon-edit-outline"
                            @click="editLogo(index)"
                            v-if="logoUrl[index] && logoUrl[index].isEdit"
                          ></i>
                          <el-button
                            size="mini"
                            type="lightBtn"
                            @click="
                              submitUpload(
                                index,
                                item.LogoXOffset,
                                item.LogoYOffset
                              )
                            "
                            v-else
                          >{{ $t('lang.applyBtn') }}</el-button
                          >
                        </el-row>
                        <el-row>
                          <span>{{ $t('lang.offsetY') }}</span>
                          <el-input
                            class="offsetInput"
                            v-model="item.LogoYOffset"
                            @focus="clearRefresh"
                            :disabled="logoUrl[index] && logoUrl[index].isEdit"
                          ></el-input>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="3">{{language[lang].Profiles}}</el-col>
                      <el-col :span="10">
                        <el-select
                          class="w_332 profile_select"
                          v-model="item.CurrentEncodingProfileID"
                          @change="setEncoderInfo('EncodingProfileID', item.CurrentEncodingProfileID, index)"
                          placeholder="Default MPEG-TS 1080p 8M"
                          :disabled="!item.Enabled"
                          :popper-append-to-body="false">
                          <el-option
                            v-for="item in profileList"
                            :key="item.ID"
                            :label="item.ID"
                            :value="item.ID"
                            :title="item.ID">
                          </el-option>
                          <el-option
                            label=""
                            value="">
                            <span
                              class="createProfile"
                              @click.stop="profileDialogInfo.show = true;profileDialogInfo.type = 'add'">+Add</span>
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 5px;margin-bottom: 5px;">
                      <el-col :span="3">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].Output}}</div>
                      </el-col>
                      <!-- 7.7及以上R适配 -->
                      <div
                        v-if="Number(rVersion) >= 7.7"
                        class='checkBoxModule'>
                        <el-dropdown
                          @command="selectCopier"
                          trigger="click"
                          :disabled="encoderListInfo[index].Copiers.length >= copiercount">
                          <span class="el-dropdown-link copierAddBtn">
                            +Add <span :class="{grayColor:item.Copiers.length == 0}">({{item.Copiers.length}}/{{copiercount}})</span>
                          </span>
                          <el-dropdown-menu
                            class="copierSelectDropdown"
                            slot="dropdown">
                            <el-dropdown-item
                              v-for="(copier, key) in supportedProtocols"
                              :key="key"
                              :command="modify(copier, index, key)">{{key|upperCaseName}}</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        <el-checkbox
                          v-if="Number(rVersion) >= 7.8"
                          class="followLive"
                          v-model="item.IsFollowRLiveStop"
                          @change="setEncoderInfo('SelectedEncoderSHM', item.item, index)"
                        >{{ $t('lang.FollowLive') }}</el-checkbox>
                      </div>
                      <!-- 7.5-7.7版本R适配 -->
                      <div
                        v-else
                        class='checkBoxModule'>
                        <el-checkbox
                          v-for="copier in item.Copiers"
                          :key="copier.Name"
                          @change="setCopierInfo(copier.Name, $event, index)"
                          :class="{grayColor:!item.Enabled}"
                          class="whiteFont"
                          v-model="copier.Enabled"
                          :disabled="!item.Enabled">
                          {{copier.Protocol || copier.Name | upperCaseName}}
                        </el-checkbox>
                      </div>
                    </el-row>
                    <div class="streamBox">
                      <div
                        v-for="(copier, key) in item.Copiers"
                        :key="key">
                        <div
                          class="streamDiv"
                          v-if="copier.Enabled">
                          <div class="streamDiv-top">
                            <el-col :span="3">
                              <span
                                class="outputSpan"
                                :class="{grayColor:!item.Enabled}">{{copier.Protocol || copier.Name | upperCaseName}}</span>
                            </el-col>
                            <el-autocomplete
                              :popper-append-to-body="false"
                              :disabled="(copier.ProcessID !=='N/A')||copier.loading"
                              @focus="clearRefresh(index,key)"
                              @blur="changeUpFlag"
                              class="w_55 outputInput"
                              alue-key="rtmpUrl"
                              :placeholder="language[lang].inputPlaceholder"
                              v-scroll="scrollData"
                              v-model="copier.Url"
                              :fetch-suggestions="(queryString,cb)=>{searchShareAccount(queryString,cb,copier, index, key)}"
                              :debounce="1500"
                              @select="saveShareAccount($event, index, key, copier.Url)">
                              <template slot-scope="{ item }">
                                <i
                                  class="iconfont f_left"
                                  :class="showTypeIcon(item)"></i>
                                <div
                                  class="f_left"
                                  style="width:88%;margin-left: 5px;">
                                  <div
                                    class="name f_left ellipsis"
                                    :title="item.nickName"
                                    style="width:15%;">{{ item.nickName }} </div>
                                  <div
                                    class="addr f_left ellipsis"
                                    style="width:80%;"
                                    :title="item.rtmpUrl">({{ item.rtmpUrl }})</div>
                                </div>
                              </template>
                            </el-autocomplete>
                            <button
                              v-if="!copier.showName"
                              @click="pushStream(copier.Name, index, key, copier.Url)"
                              class="outputBtn"
                              :class="showButton(copier.Url, index, key).colorClass"
                              :disabled="!item.Enabled">{{showButton(copier.Url, index, key).lang}}</button>
                            <span v-if="!copier.showName">{{copier.ProcessID}}</span>
                            <i
                              v-if="Number(rVersion) >= 7.7 && (copier.ProcessID ==='N/A') && !copier.loading && !copier.showName"
                              class="el-icon-delete f_right deleteCopierIcon"
                              @click="deleteCopier(copier.Name,index,key)"></i>
                          </div>
                          <div
                            class="streamDive-bottom"
                            v-if="copier.showName">
                            <span
                              class="outputSpan"
                              :class="{grayColor:!item.Enabled}">{{language[lang].StreamName}}</span>
                            <el-input
                              maxlength='32'
                              :placeholder="language[lang].inputNickName"
                              @focus="clearRefresh(index,key)"
                              @blur="changeUpFlag"
                              type="text"
                              class="w_55 outputInput outputNameInput"
                              v-model="copier.addNickName"
                              width="55%"></el-input>
                            <button
                              @click="addShareAccount(copier.Name, index, key)"
                              class="outputBtn"
                              :disabled="!item.Enabled">{{showButton(copier.Url, index, key).lang}}</button>
                            <i
                              v-if="Number(rVersion) >= 7.7 && (copier.ProcessID ==='N/A') && !copier.loading && copier.showName"
                              class="el-icon-delete f_right deleteCopierIcon"
                              @click="deleteCopier(copier.Name,index,key)"></i>
                          </div>
                        </div>
                      </div>
                      <el-row :gutter="20">
                        <el-col :span="3">{{ language[lang].Status }}</el-col>
                        <el-col :span="8">
                          <div class="grayColor">{{ language[lang].AudioBitrate }}:
                            <span>{{ item.ABitrate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="grayColor">{{ language[lang].FrameRate }}:
                            <span>{{ item.VideoStat.FPS.toFixed(2) }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="3">
                          <span style="opacity: 0">{{ language[lang].Status }}</span>
                        </el-col>
                        <el-col :span="8">
                          <div class="grayColor">{{ language[lang].VideoBitrate }}:
                            <span>{{ item.VideoStat.Bitrate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="grayColor">{{ language[lang].Resolution }}:
                            <span>{{ item.VideoStat.Width }} x {{ item.VideoStat.Height }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-collapse-transition>
              </el-main>
            </el-container>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <add-profile
      v-if="profileDialogInfo.show"
      :applyingProfileList="applyingProfileList"
      :profileDialogInfo="profileDialogInfo"
      :receiverWebsocket="receiverWebsocket"
      :rid="rid"
      :lang="lang"
      :rVersion="rVersion" />
  </div>
</template>
<script>
import language from '@/assets/lang/config.js'
import Api from '@/assets/api/api.js'
import Tool from '@/assets/js/utils.js'
import videoModule from '@/components/VideoC'
import addProfile from './AddProfile'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import vueBus from '@/assets/js/vueBus.js'

export default {
  name: 'IpStreamOut',
  data () {
    return {
      fileData: new Image(),
      imageUrl: '',
      indexss: false,
      keyss: false,
      showLoading: true,
      language: language,
      encoderList: 6,
      encoderListInfo: [],
      copyencoderListInfo: [],
      shareAccount: [],
      resolution: [],
      abitrate: [],
      achannel: [],
      acodec: [],
      showIpstream: false,
      currentPage: 1,
      lastCurrentTime: 0,
      currentTime: 0,
      noMore: false,
      scrollData: {
        onScroll: this.onScroll,
        distance: 10,
        directive: '.el-autocomplete-suggestion__wrap'
      },
      showListCb: null,
      behaviorList: [], // 记录之前的操作，与返回值对比，好恢复websocket
      optionIndex: 0, // 记录当前所操作的encoder是哪个
      optionCBRIndex: 0,
      encoderListCopy: [],
      chehckCopierTimer: null,
      hadClick: false, // let customer cannot click button quickly
      sliderInfo: {
        show: false,
        leftEnable: true,
        rightEnable: true,
        leftMute: false,
        rightMute: false,
        leftValue: 0,
        rightValue: 0,
        outputLVoiceValue: 0,
        outputRVoiceValue: 0,
        isFreashLeftVoice: true,
        isFreashRightVoice: true,
        isFreashLeftMute: true,
        isFreashRightMute: true
      },
      imgList: [],
      tvuccUrl: '',
      updateInfo: true,
      isFocus: false,
      pushStreamSource: [],
      reFreshTimer: null,
      pushStreamList: [],
      encoderListInitList: {},
      needBitObject: {},
      needResolution: {},
      // profile相关
      profileList: [],
      applyingProfileList: [],
      profileDialogInfo: {
        type: 'add',
        show: false,
        initUrl: this.initUrl
      },
      logoUrl: [],
      supportedProtocols: [],
      copiercount: 0
    }
  },
  props: {
    lang: {
      type: String,
      default: 'en'
    },
    width: {
      type: String,
      default: '1075px'
    },
    show: {
      type: Object
    },
    rid: {
      type: String,
      default: 'd627bfe491e4d247'
    },
    initUrl: {
      type: String,
      default: '/ccp/tvucc-rws/receiver/command/'
    },
    mediaUrl: {
      type: String,
      default: ''
    },
    receiverWebsocket: {
      type: Object
    },
    source: {
      type: Object,
      default: function () {
        return { peerId: this.$store.state.selectSourceId }
      }
    },
    CurrentOutputFormat2: {
      type: String,
      default: 'NTSC'
    },
    rVersion: {
      type: String,
      default: ''
    },
    useOldR: {
      type: Boolean,
      default: true
    },
    voiceInfo: Object,
    pn: {
      type: String,
      default: 'rc'
    }
  },
  components: {
    videoModule,
    addProfile
  },
  created () {
    // 2021/2/26 7.5及7.5以上适配处理
    vueBus.$on('updateProfileList', () => {
      this.getProfileInfo()
    })
    this.getEncoderInfo75()
    this.getProfileInfo()
    // 默认需要创建一个setTimeOut，否则第一次blur的时候可能会有问题
    this.changeUpFlag()
  },
  watch: {
    'encoderListInfo.length': function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.encoderListInfo.map((v, k) => {
          const val = this.imgList[k] || ''
          this.$set(this.imgList, k, val)
        })
      }
    },
    voiceInfo: function (val) {
      const leftDb = val ? (val.LeftDb - (-40)) / 40 * 160 : -40
      const rightDb = val ? (val.RightDb - (-40)) / 40 * 160 : -40
      $('.leftOutput .voices').css('height', leftDb)
      $('.rightOutput .voices').css('height', rightDb)
    }
  },
  methods: {
    // 封装成想要的参数形式
    modify (value, index, key) {
      let obj = {}
      obj.value = value
      obj.key = key
      obj.index = index
      return obj
    },
    selectCopier (options) {
      if (this.encoderListInfo[options.index].Copiers.some((item) => item.Protocol === 'Ndi') && options.key === 'Ndi') {
        this.$message.warning(this.$t('lang.encoderOneNDI'))
        return
      }
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          SelectedEncoderIndex: options.index,
          Protocol: options.value
        })
      }
      const params = Object.assign(data, Api.options.addCopier)
      const URL = `${this.initUrl}${Api.url.addCopier}`
      this.axios.post(URL, params).then(res => {
        this.changeUpFlag()
        if (res.data.errorCode === '0x0') {
          this.$message({
            type: 'success',
            message: 'Success'
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {})
    },
    deleteCopier (value, index, key) {
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          SelectedEncoderIndex: index,
          Name: value
        })
      }
      const params = Object.assign(data, Api.options.deleteCopier)
      const URL = `${this.initUrl}${Api.url.deleteCopier}`
      this.axios.post(URL, params).then(res => {
        this.changeUpFlag()
        if (res.data.errorCode === '0x0') {
          this.$message({
            type: 'success',
            message: 'Success'
          })
          this.encoderListInfo[index].Copiers.splice(key, 1)
        } else {
          this.$message({
            type: 'warning',
            message: res.errorInfo
          })
        }
      }).catch(() => {})
    },
    editLogo (index) {
      this.logoUrl[index].isEdit = false
      this.logoUrl = Object.assign({}, this.logoUrl)
      this.clearRefresh()
    },
    submitUpload (index, LogoXOffset, LogoYOffset) {
      this.clearRefresh()
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          EncoderIndex: index,
          LogoXOffset: LogoXOffset,
          LogoYOffset: LogoYOffset,
          IsLogoEnabled: this.logoUrl[index].IsLogoEnabled,
          LogoFileBase64String: this.logoUrl[index].LogoFileBase64String
        })
      }
      const params = Object.assign(data, Api.options.setEncoderLogo)
      const URL = `${this.initUrl}${Api.url.setEncoderLogo}`
      this.axios.post(URL, params).then((res) => {
        this.changeUpFlag()
      }).catch(() => {})
    },
    changeLogoEnable (index) {
      this.clearRefresh()
      if (this.logoUrl[index].IsLogoEnabled) {
        this.logoUrl[index].IsLogoEnabled = false
      } else {
        this.logoUrl[index].IsLogoEnabled = true
      }
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          EncoderIndex: index,
          IsLogoEnabled: this.logoUrl[index].IsLogoEnabled,
          LogoYOffset: null,
          LogoXOffset: null
        })
      }
      const params = Object.assign(data, Api.options.setEncoderLogo)
      const URL = `${this.initUrl}${Api.url.setEncoderLogo}`
      this.axios.post(URL, params)
        .then(res => {
          this.changeUpFlag()
        }).catch(() => {})
    },
    // 上传文件
    changeFile (file, fileList, index) {
      adAnalytice('uploadLogoRC')
      const _this = this
      new Promise(function (resolve, reject) {
        const width = 640
        const height = 480
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file.raw)
        img.onload = function () {
          if (img.width > width || img.height > height) {
            _this.$message.error(language[_this.lang].picturesFormat)
            return
          }
          resolve()
        }
      }).then(() => {
        let picUrl = ''
        // let imgInfo = ''
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = function (e) {
          // imgInfo = e
          picUrl = e.target.result.split(',')[1]
          _this.logoUrl[index].LogoFileBase64String = picUrl
          _this.logoUrl[index].IsLogoEnabled = true
        }
      }).catch(() => {})
    },
    updateExternal (result) {
      /*
      * 更新音量信息
      * 是否关闭调节音量功能
      */
      if (!result.CanAdjustVolume) {
        // 首次获取信息的时候，判断是否开启了Preview
        this.sliderInfo.leftEnable = true
        this.sliderInfo.rightEnable = true
      } else {
        this.sliderInfo.leftEnable = false
        this.sliderInfo.rightEnable = false
      }
      // var ltAudioVal, rtAudioVal;
      this.sliderInfo.show = true
      if (result.VolumeModelList && result.VolumeModelList.length) {
        result.VolumeModelList.map((v, k) => {
          var currentType = v.ChannelTag; var currentMuteFlag = v.IsMute
          if (currentType == 'L') {
            if (this.sliderInfo.isFreashLeftVoice) { // 值为true是更新websoket的值
              this.sliderInfo.leftValue = v.Volume
            }
            if (this.sliderInfo.isFreashLeftMute) { // 值为true是更新websoket的值
              if (currentMuteFlag) { // 是否显示静音
                this.sliderInfo.leftMute = true
              } else {
                this.sliderInfo.leftMute = false
              }
            }
          }
          if (currentType == 'R') {
            if (this.sliderInfo.isFreashRightVoice) {
              this.sliderInfo.rightValue = v.Volume
            }
            if (this.sliderInfo.isFreashRightMute) { // 值为true是更新websoket的值
              if (currentMuteFlag) {
                this.sliderInfo.rightMute = true
              } else {
                this.sliderInfo.leftMute = false
              }
            }
          }
        })
      }
    },
    // 设置是否静音
    externalMute (type, status) {
      let mute = ''
      if (type == 'L') {
        if (this.sliderInfo.leftEnable || status == 'close') {
          return
        }
        this.isFreashLeftMute = false
        mute = !this.sliderInfo.leftMute
      } else {
        if (this.sliderInfo.rightEnable || status == 'close') {
          return
        }
        this.isFreashRightMute = false
        mute = !this.sliderInfo.rightMute
      }
      const param = {
        rid: this.rid,
        params: JSON.stringify({ ChannelTag: type, IsMute: mute })
      }
      this.axios.post('/ccp/tvucc-device/receiver/setMute', param).then(res => {
        const result = res.data.result
        if (result && result.includes('0x0')) {
          if (type == 'L') {
            this.sliderInfo.leftMute = mute
            setTimeout(() => {
              this.isFreashLeftMute = true
            }, 1000)
          } else {
            this.sliderInfo.rightMute = mute
            setTimeout(() => {
              this.isFreashRightMute = true
            }, 1000)
          }
        }
      }).catch(() => {})
    },
    // 设置音量
    dragEnd (val, type) {
      if (type == 'L') {
        this.isFreashLeftVoice = false
      } else {
        this.isFreashRightVoice = false
      }
      const param = {
        rid: this.rid,
        params: JSON.stringify({ ChannelTag: type, Volume: val })
      }
      this.axios.post('/ccp/tvucc-device/receiver/setVolume', param).then(res => {
        const result = res.result.data
        if (result.includes('0x0')) {
          // 重置音量状态，关闭静音
          this.externalMute(type, 'close')

          if (type == 'L') {
            this.sliderInfo.leftValue = val
            // 延迟一秒更新websoket的值
            setTimeout(() => {
              this.isFreashLeftVoice = true
            }, 1000)
          } else {
            this.sliderInfo.rightValue = val
            // 延迟一秒更新websoket的值
            setTimeout(() => {
              this.isFreashRightVoice = true
            }, 1000)
          }
        }
      }).catch(() => {})
    },
    getProfileInfo () {
      const data = {
        rid: this.rid,
        Data: '{"ID":' + '' + '}'
      }
      const params = Object.assign(data, Api.options.getEncodingProfiles)
      const URL = `${this.initUrl}${Api.url.getEncodingProfiles}`
      this.axios.post(URL, params)
        .then(res => {
          if (res.status === 200) {
            this.profileList = JSON.parse(res.data.result)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            // this.getEncoderInfo75();
          }
        }).catch(() => {})
    },
    getLogoUrl () {
      let array = []
      for (var i = 0; i < this.encoderListInfo.length; i++) {
        let obj = {}
        array.push({ EncoderIndex: i })
        this.logoUrl[i] = obj
      }
      const params = {
        rid: this.rid,
        data: JSON.stringify(array)
      }
      const URL = '/ccp/tvucc-device/receiver/getEncoderLogo'
      this.axios.post(URL, params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then((res) => {
        if (res.data.errorCode == '0x0' && res.data.result) {
          this.logoUrl = JSON.parse(res.data.result)
          this.logoUrl.map(item => {
            if (item.LogoFileBase64String == '') {
              item.isEdit = false
            } else {
              item.isEdit = true
            }
            return item
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {})
    },
    getEncoderInfo75 () {
      if (JSON.stringify(this.receiverWebsocket) != '{}') {
        if (this.receiverWebsocket && this.receiverWebsocket.successObj && this.receiverWebsocket.successObj.getIpStreamOut) {
          this.receiverWebsocket.stop('getIpStreamOut')
        }

        this.receiverWebsocket.send({
          message: { categoryId: '2152870144', operationType: '101', rid: this.rid },
          time: 1500,
          key: 'getIpStreamOut',
          success: res => {
            this.encoderListCopy = JSON.parse(res.data).Encoders
            this.pushStreamSource = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListEnabledSHMs,
              valArray: ['label', 'value'],
              isSame: true
            })
            this.supportedProtocols = JSON.parse(res.data).Config.SupportedProtocols
            this.copiercount = JSON.parse(res.data).Config.CopierCount
            this.pushStreamList = []
            const applyProfileList = []
            // update push stream array
            this.encoderListCopy.forEach((v, i) => {
              if (this.updateInfo && v.Enabled) {
                applyProfileList.push(v.CurrentEncodingProfileID)
              }
              const copierList = v.Copiers
              copierList.forEach((v1, i1) => {
                if (v1.ProcessID !== 'N/A') {
                  this.pushStreamList.push(v1.Url)
                }
              })
              v.resolution = `${v.VideoStat.Width}x${v.VideoStat.Height}`
            })
            this.applyingProfileList = applyProfileList
            if (!this.updateInfo) return
            let encoderList = JSON.parse(res.data).Encoders
            encoderList.forEach(item => {
              if (item.Enabled && item.Copiers) {
                item.Copiers.forEach(obj => {
                  if (obj.name == undefined && obj.Url == '') {
                    obj.name = ''
                    obj.showName = true
                  } else {
                    obj.showName = false
                  }
                })
              }
            })
            this.encoderListInfo = encoderList
            this.copyencoderListInfo = JSON.parse(res.data).Encoders
            this.showLoading = false

            if (!this.logoUrl.length) this.getLogoUrl()
            const leftData = JSON.parse(res.data)
            this.updateExternal(leftData)
          }
        })
      }
    },
    // 设置encoder
    setEncoder (index) {
      if (this.encoderListInfo[index].Copiers.some((item) => item.ProcessID !== 'N/A')) {
        this.$message.warning(this.$t('lang.outputStreaming'))
        return
      }
      // 操作过后停止更新websocket，接口返回失败时在去更新否则就等到值发生变化再去更新
      this.optionIndex = index
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      const currentStatus = this.encoderListInfo[index].Enabled
      const data = {
        rid: this.rid,
        Data: '{"Index":' + index + '}'
      }
      const params = currentStatus ? Object.assign(data, Api.options.startEncoder) : Object.assign(data, Api.options.stopEncoder)
      const URL = currentStatus ? `${this.initUrl}${Api.url.startEncoder}` : `${this.initUrl}${Api.url.stopEncoder}`
      this.axios.post(URL, params)
        .then(res => {
          this.getEncoderInfo75()
          if (res.status === 200) {
            // this.checkEncoderSatus(index, currentStatus);
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
          }
          setTimeout(() => {
            this.hadClick = false
          })
        }).catch(() => {})
      // google adAnalytice
      if (currentStatus) {
        adAnalytice('enableEncoderRC')
      } else {
        adAnalytice('disableEncoderRC')
      }
    },
    // 设置encoder里面选项
    setEncoderInfo (key, value, index) {
      this.optionIndex = index
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      let EncodingProfileID = this.encoderListInfo[index].CurrentEncodingProfileID; let SelectedEncoderSHM = this.encoderListInfo[index].CurrentSharedMemoryString
      key == 'EncodingProfileID' ? EncodingProfileID = value : SelectedEncoderSHM = value
      const dataInfo = {
        SelectedEncoderIndex: index,
        EncodingProfileID: EncodingProfileID,
        SelectedEncoderSHM: SelectedEncoderSHM
      }
      if (Number(this.rVersion) >= 7.8) dataInfo.IsFollowRLiveStop = this.encoderListInfo[index].IsFollowRLiveStop
      const data = {
        rid: this.rid,
        data: JSON.stringify(dataInfo)
      }
      const params = Object.assign(data, Api.options.setEncoderInfo)
      this.axios.post(this.initUrl + Api.url.setEncoderInfo, params)
        .then(res => {
          this.hadClick = false
          setTimeout(() => {
            this.getEncoderInfo75()
          })
          if (res.status !== 200) {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
          }
        }).catch(() => {})
    },
    // 设置推流信息
    setCopierInfo (key, value, index, url) {
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      url || (url = '')
      const data = {
        rid: this.rid,
        Data: '{"SelectedEncoderIndex":' + index + ',"Enabled":"' + value + '","Name":"' + key + '","Url":"' + url + '"}'
      }
      const params = Object.assign(data, Api.options.setCopierInfo)
      this.axios.post(this.initUrl + Api.url.setCopierInfo, params).then(res => {
        if (res.status === 200) {
          // if (!value) this.streamUrlList[key] = ''
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('lang.requestFailed')
          })
        }
        setTimeout(() => {
          this.getEncoderInfo75()
          this.hadClick = false
        })
      }).catch(() => {})
      // google adAnalytice
      adAnalytice('setCopierRC', '', '', key)
    },
    checkCurrentPushStatus (name, index, copiersIndex, currentBtnStatus) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            const copierList = JSON.parse(res.data.result).Encoders
            if (copierList[index]) {
              copierList[index].Copiers.map((v, i) => {
                const nextStatus = v.ProcessID === 'N/A' ? 'Start' : 'Stop'
                if (v.Name === name && nextStatus === currentBtnStatus) {
                  v.loading = false
                  console.log('push status', 'same value')
                }
              })
            }
          }
        }).catch(() => {})
    },
    // // 保存推流地址
    // addStreamUrl (key, obj) {
    //   this.streamUrlList[key] = 'obj'
    // },
    // 开始推流
    pushStream (key, index, copiersIndex, copierUrl) {
      // console.log(this.encoderListInfo[index].Copiers[copiersIndex])
      if (!this.encoderListInfo[index].Copiers[copiersIndex].Url) {
        this.$message({
          type: 'warning',
          message: this.language[this.lang].warnAddMsg
        })
        return
      }

      this.encoderListInfo[index].Copiers[copiersIndex].loading = true
      let params
      if (this.encoderListInfo[index].Copiers[copiersIndex].ProcessID === 'N/A') {
        // if current input url was had been pushed not send agin
        if (this.pushStreamList.includes(this.encoderListInfo[index].Copiers[copiersIndex].Url)) {
          this.$message({
            type: 'warning',
            message: this.language[this.lang].pushSameStream
          })
          return
        };
      }
      if (this.encoderListInfo[index].Copiers[copiersIndex].ProcessID === 'N/A' || this.copyencoderListInfo[index].Copiers[copiersIndex].Url != copierUrl) {
        this.setCopierInfo(key, this.encoderListInfo[index].Copiers[copiersIndex].Enabled, index, this.encoderListInfo[index].Copiers[copiersIndex].Url)
        const data = {
          rid: this.rid,
          Data: '{"SelectedEncoderIndex":' + index + ',"Name":"' + key + '"}'
        }
        params = Object.assign(data, Api.options.startPushStream)
        // google adAnalytice
        adAnalytice('startPushStreamRC')
      } else {
        const data = {
          rid: this.rid,
          Data: '{"SelectedEncoderIndex":' + index + ',"Name":"' + key + '"}'
        }
        params = Object.assign(data, Api.options.stopPushStream)
        // google adAnalytice
        adAnalytice('stopPushStreamRC')
      }
      this.axios.post(this.initUrl + Api.url.startPushStream, params)
        .then(res => {
          if (res.status === 200) {
            this.encoderListInfo[index].Copiers[copiersIndex].showName = false
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.updateInfo = true
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.encoderListInfo[index].Copiers[copiersIndex].loading = false
          }
          if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
        }).catch(() => {})
    },
    showButton (url, index, copiersIndex) {
      if (!this.copyencoderListInfo[index]) return
      const obj = {
        colorClass: '',
        lang: this.language[this.lang].Start
      }
      if (this.copyencoderListInfo[index].Copiers[copiersIndex].Url === url) {
        if (this.copyencoderListInfo[index].Copiers[copiersIndex].ProcessID === 'N/A') {
          obj.lang = this.language[this.lang].Start
          return obj
        } else {
          obj.colorClass = 'redBg'
          obj.lang = this.language[this.lang].Stop
          return obj
        }
      } else {
        return obj
      }
    },
    async addShareAccount (key, index, copiersIndex) {
      // 停掉当前的websocket循环
      this.clearRefresh(index, copiersIndex)

      if (!this.encoderListInfo[index].Copiers[copiersIndex].addNickName || !this.encoderListInfo[index].Copiers[copiersIndex].Url) {
        this.$message({
          type: 'warning',
          message: this.language[this.lang].warnAddMsg
        })
        return
      }

      const params = {
        nickName: this.encoderListInfo[index].Copiers[copiersIndex].addNickName,
        rtmpUrl: this.encoderListInfo[index].Copiers[copiersIndex].Url,
        authType: 1
      }

      const isHave = await this.checkNickname(params)

      if (!isHave) {
        this.axios.post(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/other/authInfo', params, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200) {
              this.pushStream(key, index, copiersIndex)
              // google adAnalytice
              adAnalytice('addNewShareAccountRC')
              if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
            } else {
              this.$message({
                type: 'warning',
                message: this.$t('lang.requestFailed')
              })
            }
            this.updateInfo = true
          }).catch(() => {})
      }
    },
    checkNickname (options) {
      return new Promise((resolve, reject) => {
        const data = {
          params: {
            nickName: options.nickName,
            flag: false
          }
        }
        this.axios.get(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/media/checkAuth', data, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200 && res.data.errorCode === '82100001') {
              resolve(false)
            } else if (res.data.errorCode === '82100002') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.nameExist')
              })
              resolve(true)
            } else {
              this.$message({
                type: 'warning',
                message: res.data.errorInfo
              })
              resolve(true)
            }
          }).catch(() => {})
      })
    },
    onScroll () {
      if (this.noMore) return
      this.currentPage++
      this.getShareAccount()
    },
    // 获取推流地址列表
    getShareAccount (queryString, copier) {
      const type = Number(this.rVersion) >= 7.7 ? copier.Protocol : copier.Name
      return new Promise((resolve, reject) => {
        const params = {
          condition: queryString || '',
          pageNum: this.currentPage,
          pageSize: 20,
          type: type || ''
        }
        this.axios.post(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/account/ipStreamOutAccountList', params, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200 && res.data.errorCode === '0x0') {
              this.shareAccount = this.shareAccount.concat(res.data.result.list)
              if (res.data.result.list.length < 20) {
                this.noMore = true
              }
              this.showListCb(this.shareAccount)
              resolve(res.data.result.list)
            } else {
              this.$message({
                type: 'warning',
                message: this.$t('lang.requestFailed')
              })
            }
          }).catch(() => {})
      })
    },
    async searchShareAccount (queryString, cb, copier, index, key) {
      this.showListCb = cb
      this.lastCurrentTime = this.currentTime
      this.currentTime = new Date().getTime()
      // if (this.currentTime - this.lastCurrentTime < 1000) return;

      var accountArray = await this.getShareAccount(queryString, copier)
      var result = queryString ? accountArray.filter(this.filterAccount(queryString)) : accountArray
      if (!result || !result.length) {
        const obj = this.encoderListInfo[index].Copiers[key]
        if (obj) {
          obj.showName = true
          obj.name = ''
          this.$set(this.encoderListInfo[index].Copiers, key, obj)
        }
      }
      cb(result)
    },
    filterAccount (queryString) {
      return (shareAccount) => {
        return (shareAccount.nickName.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          shareAccount.rtmpUrl.indexOf(queryString === 0)
        )
      }
    },
    saveShareAccount (queryString, index, key, url) {
      this.clearRefresh(index, key)
      const obj = this.encoderListInfo[index].Copiers[key]
      obj.showName = false
      obj.name = ''
      obj.Url = queryString.rtmpUrl
      this.$set(this.encoderListInfo[index].Copiers, key, obj)
      this.showButton(queryString.rtmpUrl, index, key)
    },
    // 往出定时任务，防止填写的值被覆盖
    clearRefresh (index, key) {
      if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
      this.updateInfo = false
      this.indexss = index
      this.keyss = key
      this.shareAccount = []
    },
    changeUpFlag () {
      if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
      // reFresh data after ten second
      this.reFreshTimer = setTimeout(() => {
        this.updateInfo = true
      }, 10000)
    },
    showTypeIcon (item) {
      const type = item.type
      if (type === 'O') {
        return 'otherIcon'
      } else if (type === 'Y') {
        return 'youtubeIcon'
      }
    },
    clearWebsocket () {
      if (this.receiverWebsocket && this.receiverWebsocket.successObj && this.receiverWebsocket.successObj.getIpStreamOut) {
        this.receiverWebsocket.stop('getIpStreamOut')
      }
    }
  },
  filters: {
    upperCaseName: function (name) {
      if (name == 'M3u8') {
        return 'HLS'
      } else if (name == 'M3u8Push') {
        return 'HLS Push'
      } else if (name == 'Rtmp') {
        return 'RTMP(s)'
      } else {
        return name.toUpperCase()
      }
    }
  },
  beforeDestroy () {
    this.clearWebsocket()
    if (this.receiverWebsocket && this.receiverWebsocket.successObj && this.receiverWebsocket.successObj.queryExtInfo) {
      this.receiverWebsocket.stop('queryExtInfo')
    }
    this.$emit('selectOtherR', '', 'notChangeRStatus')
  }
}
</script>
<style lang="less" scoped>
.avatar-uploader {
  ::v-deep .el-upload {
    height: 76px;
    width: 76px;
    border-radius: 6px;
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    line-height: 76px;
    font-size: 26px;
  }
}
.logoOffset {
  .offsetInput {
    width:97PX;
    margin: 0 27PX;
    border-radius: 4PX;
    background: #444;
    margin-bottom: 10px;
  }
}
  ::v-deep .avatar {
  width: 100%;
  height: 100%;
}
::v-deep .el-input.w_55 {
  width: 55%;
}
::v-deep .streamBox .el-input__inner::-webkit-input-placeholder {
  color: #666;
}
::v-deep .streamBox .el-input__inner::-moz-input-placeholder {
  color: #666;
}
::v-deep .streamBox .el-input__inner::-ms-input-placeholder {
  color: #666;
}
::v-deep #moduleBox .el-slider.is-vertical .el-slider__runway {
  width: 10px;
  border-radius: 0px;
  margin: 0px 12px;
}
::v-deep #moduleBox .el-slider.is-vertical .el-slider__button-wrapper {
  left: -13px;
}
::v-deep
  #moduleBox
  .el-slider__runway
  .el-slider__button-wrapper
  .el-slider__button {
  border-radius: 0;
  height: 6px;
}

::v-deep #moduleBox .el-slider__runway.disabled .el-slider__button {
  border-color: #c0c4cc;
}
::v-deep .el-dropdown{
    color: #fff;
}
.otherIcon:before {
  content: '\E60F';
  font-size: 24px;
}
.youtubeIcon:before {
  content: '\E60C';
  font-size: 24px;
}
.f_left {
  float: left;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.followLive {
  margin-left: 60px;
  color: #606266;
  font-weight: 500;
  ::v-deep .el-checkbox__inner {
    background: #252525;
    border: 1px solid #555;
  }
  ::v-deep .el-checkbox__input.is-checked {
    .el-checkbox__inner {
      background-color: #209d3d;
      border-color: #209d3d;
    }
    .el-checkbox__inner:after {
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
    }
  }
}

#moduleBox {
  height: 490px;
  margin-bottom: 25px;
  .module-right {
    .info_title {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      padding: 0 10px;
      color: #aaa;
      .profileSet {
        .icon-settings_btnCopy {
          font-size: 20px;
          cursor: pointer;
          vertical-align: middle;
          &:before {
            content: "\e62c";
          }
        }
      }
    }
  }
  .uploadImg {
    display: inline-block;
    margin-left: 10px;
    .icon-upload {
      font-size: 18px;
    }
  }
  .source-default {
    .green {
      color: #33aa50;
    }
    i {
      margin-right: 5px;
    }
  }
  .sourceInfo {
    .title {
      margin-top: 20px;
      font-size: 18px;
      color: #aaa;
      margin-bottom: 5px;
    }
    .info {
      font-size: 14px;
    }
  }
  .outputVoiceModule {
    margin-top: 8px;
    .setVoiceModule {
      margin-top: 6px;
      .voiceTitle {
        margin-top: 8px;
      }
      .rightVoice {
        margin-right: 60px;
      }
      .leftVoiceIcon[disabled],
      .rightVoiceIcon[disabled] {
        color: gray;
        cursor: not-allowed;
      }
      .leftVoiceIcon:before,
      .rightVoiceIcon:before {
        content: '\e613';
        margin-left: 12px;
      }
      .icon-mute:before {
        content: '\E615';
        color: red;
      }
    }
    .outputVoice {
      .common_bg {
        height: 150px;
        width: 15px;
        background: #444;
        margin-right: 6px;
        position: relative;
        .voices {
          position: absolute;
          width: 100%;
          background: #33aa50;
          bottom: 0;
        }
      }
      .audioScale {
        background: url('../../assets/img/audio_scale.png');
        background-size: contain;
        height: 160px;
        width: 50px;
        margin-top: -5px;
      }
    }
  }
  #slotModule {
    padding: 10px;
    color: #fff;
    text-align: left;
    min-width: 300px;
    max-height: 450px;
    .whiteFont {
      color: #fff;
      margin-right: 8px;
    }
    .encoderBox {
      margin-bottom: 10px;
      .select-box {
        width: 120px!important;
      }
    }
    .sj {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background: #fff;
      position: relative;
      top: 2px;
    }
    .sj:after {
      box-sizing: content-box;
      content: '';
      border: 1px solid #33aa50;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 5px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      transform-origin: center;
      transform: rotate(45deg) scaleY(1);
    }
    .outputSpan {
      width: 75px;
      margin-right: 10px;
      display: inline-block;
    }
    .outputInput {
      color: #333;
      margin-right: 10px;
      ::v-deep .el-autocomplete-suggestion.el-popper {
        z-index: 2 !important;
      }
    }
    .outputBtn {
      width: 60px;
      outline: none;
      background: #33ab4f;
      border: none;
      height: 24px;
      border-radius: 3px;
      margin-right: 4px;
      margin-left: 4px;
    }
    .outputBtn[disabled] {
      background: gray;
    }
    .outputBtn:hover {
      background: #209d3d;
    }
    .outputBtn[disabled]:hover {
      background: gray;
    }
    .deleteCopierIcon {
      margin: 10px;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
    .redBg {
      background: red;
    }
    .redBg:hover {
      background: #db1a14;
    }
    .streamBox {
      margin-top: 2px;
    }
    .checkBoxModule {
      position: relative;
      .copierAddBtn {
        color: #33AB4F;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .streamDiv {
      margin-bottom: 10px;
      border-bottom: 2px solid #333;
      .streamDiv-top {
        margin-bottom: 5px;
      }
    }
    .uploadBtn {
      position: absolute;
      right: 20%;
      top: -4px;
    }
    .slotBox {
      padding: 10px 20px 0 20px;
      .w_332 {
        width: 332px;
        &.profile_select {
          ::v-deep .el-select-dropdown {
            margin-top: 20px;
            width: 280px;
          }
          .createProfile {
            display: block;
            border-top: solid 1px #d8d8d8;
            color: #33ab4f;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
  ::v-deep .el-main {
    overflow: initial;
  }
  ::v-deep .el-select-dropdown {
    position: absolute !important;
    left: 0 !important;
  }
  // ::v-deep .el-select--small .el-select-dropdown {
  //   position: absolute !important;
  //   top: 30px !important;
  //   left: 0 !important;
  // }
}
</style>
