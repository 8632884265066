const api = {
  url: {
    externalEncoderInfo: 'externalEncoder',
    setEncoder: 'externalEncoder_setEncoder', // 设置encoder开启关闭
    setEncoderInfo: 'externalEncoder_setEncoderInfo', // 设置encoder里面各参数
    setCBR: 'externalEncoder_setCBR', // 设置encoder里面各参数
    setCopierInfo: 'externalEncoder_setCopierInfo', // 设置copier各参数
    startPushStream: 'externalEncoder_startPushStream',
    setSharedMemory: 'externalEncoder_setSharedMemory',
    startEncoder: 'externalEncoder_startEncoder',
    stopEncoder: 'externalEncoder_stopEncoder',
    setEncoderLogo: 'externalEncoder_setEncoderLogo',
    getEncoderLogo: 'externalEncoder_getEncoderLogo',
    getEncodingProfiles: 'externalEncoder_getEncodingProfiles',
    getAllAvailabelOptions: 'externalEncoder_getAllAvailabelOptions',
    getProfile: 'externalEncoder_getProfile',
    deleteProfile: 'externalEncoder_deleteProfile',
    creatProfile: 'externalEncoder_creatProfile',
    upDateProfile: 'externalEncoder_upDateProfile',
    addCopier: 'externalEncoder_addCopier',
    deleteCopier: 'externalEncoder_deleteCopier'
  },
  options: {
    externalEncoderInfo: {
      operationType: '101',
      categoryId: '2152870144'
    }, // 获取external信息
    setEncoder: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder开启关闭
    setEncoderInfo: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder里面各参数
    setCBR: {
      operationType: '302',
      categoryId: '2152870144'
    }, // 设置encoder里面各参数
    setCopierInfo: {
      operationType: '401',
      categoryId: '2152870144'
    }, // 设置copier各参数
    stopPushStream: {
      operationType: '403',
      categoryId: '2152870144'
    }, // 设置copier各参数
    startPushStream: {
      operationType: '402',
      categoryId: '2152870144'
    },
    setSharedMemory: {
      operationType: '307',
      categoryId: '2152870144'
    },
    // 适配R 7.5及7.5以上版本 start
    startEncoder: {
      operationType: '303',
      categoryId: '2152870144'
    }, // 开启Encoder
    stopEncoder: {
      operationType: '304',
      categoryId: '2152870144'
    }, // 关闭Encoder
    setEncoderLogo: {
      operationType: '306',
      categoryId: '2152870144'
    },
    getEncoderLogo: {
      operationType: '308',
      categoryId: '2152870144'
    },
    getEncodingProfiles: {
      operationType: '700',
      categoryId: '2152870144'
    },
    getAllAvailabelOptions: {
      operationType: '701',
      categoryId: '2152870144'
    },
    getProfile: {
      operationType: '702',
      categoryId: '2152870144'
    },
    creatProfile: {
      operationType: '703',
      categoryId: '2152870144'
    },
    upDateProfile: {
      operationType: '704',
      categoryId: '2152870144'
    },
    deleteProfile: {
      operationType: '705',
      categoryId: '2152870144'
    },
    addCopier: {
      operationType: '405',
      categoryId: '2152870144'
    },
    deleteCopier: {
      operationType: '406',
      categoryId: '2152870144'
    }
    // 适配R 7.5及7.5以上版本 stop
  }
}

export default api
