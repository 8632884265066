<template>
  <div
    class="delayBox"
    :style="{width:width}">
    <div class="box-top clearfix">
      <div class='f_left'>
        {{$t('lang.Delay')}}
      </div>
      <div class="f_left">(second)</div>
      <div class="f_right btns">
        <span
          @click="setMode(false,delayInfo.CurrentTCanSetDelayConfigurable)"
          :class="{'disabled': delayInfo.CurrentTCanSetDelayConfigurable ? false : true, 'greenColor': !delayInfo.CurrentTCanSetDelay}">{{$t('lang.Dynamic')}}</span>
        <span :class="{'disabled': delayInfo.CurrentTCanSetDelayConfigurable ? false : true}"> | </span>
        <span
          @click="setMode(true,delayInfo.CurrentTCanSetDelayConfigurable)"
          :class="{'disabled': delayInfo.CurrentTCanSetDelayConfigurable ? false : true, 'greenColor': delayInfo.CurrentTCanSetDelay}">{{$t('lang.Fixed')}}</span>
      </div>
    </div>
    <div class="box-bottom clearfix">
      <div class="silderBox w_65 f_left">
        <el-slider
          :disabled="canSetDelay"
          @change="dragEnd"
          v-model="delayValue"
          :max="delayInfo.DelaySettingModel.MaximumValue"
          :min="delayInfo.DelaySettingModel.MinimumValue"></el-slider>
      </div>
      <div class="w_25 f_right m_t_8">
        <el-input
          :disabled="canSetDelay"
          :maxlength="10"
          v-model="delay"
          size="small"
          @focus="changeFreash(false)"
          @blur="changeFreash(true)"
          @change="controlVal"
          @keyup.enter.native="changeVal()"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
export default {
  props: {
    width: {
      type: String,
      default: '255px'
    },
    delayInfo: { // categoryId: "2152866048", operationType: "101"的websoket的查询信息返回data结果
      type: Object
    },
    rid: Array, // 当前在播的r的id
    params: { // 设置是的传参参数
      type: Object,
      default: () => {
        return {
          rid: 'rid',
          bitrate: 'bitrate',
          delay: 'delay',
          pn: 'pn',
          module: 'module'
        }
      }

    },
    url: {
      type: Object,
      default: () => {
        return {
          set: '/ccp/tvucc-device/receiver/setBitrateAndDelay'
        }
      }
    }, // set请求时的url
    module: {
      type: String
    }
  },
  data () {
    return {
      delayValue: 0,
      delay: 0,
      isFreash: true,
      canSetDelay: true
    }
  },
  watch: {
    delayInfo (val) {
      if (!val || !this.isFreash) return
      this.delayValue = val.DelaySettingModel.CurrentValue
      this.delay = val.DelaySettingModel.CurrentValue
      this.canSetDelay = !val.CurrentTCanSetDelay
    }
  },
  methods: {
    controlVal (val) {
      this.delay = Number(val).toFixed('3')
    },
    dragEnd () {
      this.setDelay(this.delayValue)
    },
    changeFreash (val) {
      // bitrateValue的值是否需要被websoket刷新
      this.isFreash = val
    },
    changeVal () {
      // 手动更改bitrateValue的值
      const delay = Number(this.delay)

      if (isNaN(delay)) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.rightNumber')
        })
        return
      }

      // 超出范围提示
      if (delay < this.delayInfo.DelaySettingModel.MinimumValue || delay > this.delayInfo.DelaySettingModel.MaximumValue) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.bitrateDelay', [this.delayInfo.DelaySettingModel.MaximumValue, this.delayInfo.DelaySettingModel.MinimumValue])
        })
        return
      }

      this.setDelay(delay)
    },
    // 设置比特率
    setDelay (delay) {
      const params = {}
      params[this.params.rid] = this.rid.join().toLowerCase()
      params[this.params.bitrate] = this.delayInfo.BitrateSettingModel.CurrentValue
      params[this.params.delay] = delay * 1000
      params[this.params.pn] = 'nhm'
      params[this.params.module] = this.module
      this.axios.post(this.url.set, params)
        .then(res => {
          if (res.status === 200) {
            this.delayValue = delay
            this.delay = delay
            this.$emit('set', delay)
            setTimeout(() => {
              this.isFreash = true
            }, 1000)
          }
        }).catch(() => {})
    },
    // 设置cbr,vbr
    setMode (mode, btn) {
      if (!btn) return
      const key = mode ? 'Fixed' : 'Dynamic'
      this.$confirm(this.$t('lang.confirmModeChange', { mode: this.$t(`lang.${key}`) }), '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        const dataStr = {
          rid: this.rid.join().toLowerCase(),
          Data: '{"CanSetDelay":"' + mode + '"}'
        }
        const params = Object.assign(dataStr, receviceParams.setVideo.setVideoDynamic)
        this.axios.post(Receiver().setVideo.setVideoDynamic, params)
          .then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
            } else {
              this.$message({
                type: 'warning',
                message: 'Error'
              })
            }
          }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-input__inner {
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
}
.delayBox {
  .btns {
    span {
      cursor: pointer;
      &.disabled {
        opacity: 0.48;
        cursor: not-allowed;
      }
    }
  }
  ::v-deep .el-input__inner {
    height: 25px !important;
    line-height: 25px !important;
    padding: 0 8px;
  }
}
</style>
