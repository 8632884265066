<template>
  <div class="receiver">
    <div class="receiverTitle clearfix">
      <span
        id="content_left_title"
        class="i18n"
        name="i18n_receiverList">{{$t('lang.selectReceiver')}}</span>
      <a
        href="javascript:;"
        class="right el-button el-button--success el-button--small green"
        @click.stop="goToPage({dataMark: 'tm'})">{{$t('lang.MultiControl')}}</a>
    </div>
    <div class="receiverSearch clearfix">
      <search-input
        :placeholder="$t('lang.searchTText')"
        :callback="search"
        :value="filter.condition"></search-input>
      <DropdownMenu
        :data="statusDataR"
        :typeVal='"status"'
        :title="$t('lang.status')"
        :callback="changeFilter"
        class="statusDown statusDownMedia"></DropdownMenu>
      <BookMark
        :callback="changeFilter"
        :from="{Unclassified:true}"
        :defaultKeys="defaultBookmark"
        class="bookDown bookDownMedia"></BookMark>
    </div>
    <div
      class="receiverContent receiverContentMedia"
      v-scroll="bindScroll"
      style="overflow: auto"
      v-loading='loading'
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <ReceiverItem
        v-for="item in receiverItemsOps"
        :socketItem="allReceiverSocketData[item.peerId]"
        :receiverItemOps="item"
        :key="item.peerId + item.type"></ReceiverItem>
    </div>
    <multi-Control
      :show="showDialog"
      v-if="showDialog['multiControl']"></multi-Control>
  </div>
</template>
<script>
/*
 * 左侧内容
 */
import SearchInput from '@/components/Search'
import DropdownMenu from '@/components/dropMenuSelect'
import BookMark from '@/components/bookMark'
import ReceiverItem from '@/views/Home/ReceiverItem'
import multiControl from '@/views/receivercontrol/multiControl'
import { MySReceiverStatus } from '@/config'
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'

export default {
  components: {
    SearchInput,
    DropdownMenu,
    BookMark,
    ReceiverItem,
    multiControl
  },
  props: {},
  data () {
    return {
      bindScroll: {
        onScroll: this.load,
        distance: 10
      },
      statusDataR: MySReceiverStatus,
      defaultBookmark: { // 默认bookmark选中的值l
        bookmardIds: [],
        token: true,
        unClassified: true
      },
      filter: { // 筛选过滤参数
        living: true,
        online: true,
        offline: true,
        pageNum: 1,
        pageSize: 15,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        onlineFirst: false,
        liveFirst: true,
        sort: true
      },
      allReceiverSocketData: [],
      receiverItemsOps: [],
      peerIds: [], // 所有的R的id str
      hasNextPage: true, // 是否还有下一页
      lastFilterStatus: [],
      isInit: true, // 第一次进入页面不进入滚动加载的回调
      loading: true,
      showDialog: {
        multiControl: false
      },
      isAtLoading: false// 是否正在loading,如果在loading (没有获取到返回值)不加载下一页
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      userBehaviors: state => state.userBehaviors ? state.userBehaviors : {}
    })
  },
  created () {
    Bus.$on('sendReceiverListData', data => {
      if (data) {
        if (data.isInit) { // 用以区分是init接口来的还是滚动翻页加载来的数据
          // 处理行为R列表数据
          const arr = data.content.list || []
          arr.forEach(m => {
            m.lastLiveName = ''
            this.peerIds.push(m.peerId)
          })
          this.receiverItemsOps = arr
          this.getLastLiveDevice(this.peerIds)
          // 处理行为筛选条件
          const userBehaviors = data.param
          if (userBehaviors.living) this.lastFilterStatus.push('living')
          if (userBehaviors.noCamera) this.lastFilterStatus.push('noCamera')
          if (userBehaviors.offline) this.lastFilterStatus.push('offline')
          if (userBehaviors.online) this.lastFilterStatus.push('online')
          // 初始化筛选条件
          if (userBehaviors) {
            Object.keys(this.filter).map(v => {
              Object.keys(userBehaviors).map(K => {
                if (v == K) {
                  this.filter[v] = userBehaviors[K]
                }
                if (K.includes('liveFirst')) {
                  if (userBehaviors[K]) {
                    this.filter.sort = true
                  } else {
                    this.filter.sort = false
                  }
                }
              })
            })
          }
          // 用来处理筛选选中的状态
          this.statusDataR.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.statusDataR, k, obj)
          })
          // bookmark
          this.defaultBookmark.bookmardIds = this.filter.bookmardIds ? this.filter.bookmardIds == 'all' ? ['all'] : this.filter.bookmardIds.split(',') : []
          this.filter.unClassified && this.defaultBookmark.bookmardIds.push('unClassified')
          this.filter.token && this.defaultBookmark.bookmardIds.push('token')

          // 处理condition
          this.filter.condition = userBehaviors.condition
        }
        this.sendWebsocket()
      }
      this.loading = false
    })
    // 更改了R列表将
  },
  methods: {
    getLastLiveDevice (arr) {
      this.axios.post('/ccp/tvucc-device/device/getDeviceLastLivePidMsg', arr, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.length) {
            const resultArr = res.data.result
            resultArr.forEach(m => {
              const index = this.receiverItemsOps.findIndex(n => n.peerId == m.peerId)
              if (index >= 0 && m.livePid) {
                this.receiverItemsOps[index].lastLiveName = m.peerName
              }
            })
          }
        }
      }).catch(() => {})
    },
    goToPage (item) {
      localStorage.setItem('timeLockHisRouter', 'nhm')
      this.$router.push({ path: `/${item.dataMark}` }).catch(() => {})
    },
    search (val) {
      this.filter.pageNum = 1
      this.filter.condition = val
      this.getReceiverList()
      newHomeAnalyze('RSearch', this.$route.path) // Home-Receiver-search
    },
    changeFilter (obj) {
      Object.keys(obj).map(v => {
        // this.filter 过滤参数对象
        this.filter[v] = obj[v]

        if (v.includes('sort')) {
          if (obj[v]) { // 来处理R状态筛选
            this.filter.liveFirst = true
            newHomeAnalyze('RStatusSort', this.$route.path) // Home-Receiver-sort
          } else {
            this.filter.liveFirst = false
            newHomeAnalyze('RStatusUnSort', this.$route.path) //  Home-Receiver-sort
          }
        }
        if (v.includes('living')) {
          if (obj[v]) {
            newHomeAnalyze('RStatusLive', this.$route.path) // Home-Receiver-live
          } else {
            newHomeAnalyze('RStatusUnLive', this.$route.path) // Home-Receiver-live
          }
        }
        if (v.includes('offline')) {
          if (obj[v]) {
            newHomeAnalyze('RStatusOffline', this.$route.path) // Home-Receiver-offline
          } else {
            newHomeAnalyze('RStatusUnOffline', this.$route.path) // Home-Receiver-offline
          }
        }
        if (v.includes('online')) {
          if (obj[v]) {
            newHomeAnalyze('RStatusOnline', this.$route.path) // Home-Receiver-online
          } else {
            newHomeAnalyze('RStatusUnOnline', this.$route.path) // Home-Receiver-online
          }
        }
      })
      this.filter.pageNum = 1
      this.getReceiverList()
    },
    // 滚动加载
    load () {
      if (this.hasNextPage && !this.isAtLoading) { // 如果还有下一页
        this.filter.pageNum++
        this.getReceiverList(true)
      }
    },
    sendWebsocket () {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_Home_R') // 停止websocket
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_Home_R',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_Home_R',
        success: res => {
          var results = res.result
          if (results.length > 0) {
            var thisReceiverData = []
            results.map(item => {
              // 处理记录最后一次Live的R信息
              const index = this.receiverItemsOps.findIndex(n => n.peerId == item.peerId)
              if (index >= 0) item.lastLiveName = this.receiverItemsOps[index].lastLiveName
              thisReceiverData[item.peerId] = item
              this.State.receiverRList[item.peerId] = item
            })
            this.allReceiverSocketData = thisReceiverData
          }
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_Home_R',
            data: { peerIds: this.peerIds.join(',') }
          }
          this.pageRequest.sendMsgs.taskInfo_Home_R.message = taskInfoMsg
        }
      })
    },
    // 获取左侧R的列表
    getReceiverList (init) { // init=>是否是从第一页开始 default=>false
      const routeName = this.$route.name
      const data = JSON.parse(JSON.stringify(this.filter))
      if (routeName == 'NSourceList') {
        data.battery = true
        data.bookmarks = true
      }
      this.isAtLoading = true
      this.axios.post('/ccp/tvucc-user/userDevice/homePageR', data, {
        contentType: 'application/json;charset=UTF-8',
        isCancelToken: true
      }).then(res => {
        if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
          const result = res.data.result
          result.list.forEach(m => {
            m.lastLiveName = ''
          })

          if (!init) {
            this.receiverItemsOps = result.list
          } else { // 滚动加载
            this.receiverItemsOps = this.receiverItemsOps.concat(result.list)
          }
          this.isAtLoading = false
          this.peerIds = []
          this.receiverItemsOps.map((item) => {
            this.peerIds.push(item.peerId)
          })
          this.getLastLiveDevice(this.peerIds)
          this.filter.pageNum = result.pageNum
          this.hasNextPage = result.hasNextPage// 是否还有下一页
          // Bus.$emit('sendReceiverListData');
        }
      }).catch(() => {})
    },
    // 控制MultiControl显示
    showMultiControl () {
      this.showDialog.multiControl = !this.showDialog.multiControl
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_Home_R') // 停止websocket
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .selectSource {
  width: 60% !important;
}

::v-deep .tree {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}

.receiver {
  padding-top: 0.36rem;

  .receiverTitle {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid #666;
    text-align: left;

    span {
      font-size: 0.22rem;
      font-weight: bold;
    }

    a {
      cursor: pointer;
      text-align: center;
      color: #fff;
      text-decoration: none;
      min-width: 0.76rem;
      font-size: 0.12rem;
      border-radius: 0.04rem;
      padding: 0 0.05rem;
      margin-right: 0.1rem;
      height: 0.32rem;
      line-height: 0.32rem;
    }
  }

  .receiverSearch {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;

    .statusDown {
      width: 45%;
      height: 32px;
      line-height: 32px;
      margin-top: 0.15rem;
      float: left;
      position: relative;
      background: #252525;
      border-radius: 4px;
      // overflow: hidden;

      ::v-deep .el-dropdown {
        width: 100%;

        button {
          height: 32px;
          background: #252525;
          border-radius: 4px;
        }
      }
    }

    .bookDown {
      width: 45%;
      height: 32px;
      line-height: 32px;
      margin-bottom: 0.05rem;
      margin-top: 0.15rem;
      border-radius: 4px;

      position: relative;
      float: left;
      margin-left: 10%;

      ::v-deep .tree {
        top: 38px;
      }
    }
  }

  ::v-deep .tree-btn {
    background: #252525;
  }

  .receiverContent {
    border-top: 2px solid #666;
    margin-right: 0.25rem;
    height: 7.55rem;
    padding-top: 20px;
    overflow: hidden;
    outline: none;
    clear: both;
  }
}

.receiverContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.receiverContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

.receiverContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: none;
}

::v-deep .el-dropdown-menu {
  background-color: #444 !important;
  border: none;
  box-shadow: 0 0 20px #222;
  padding: 0.08rem 0;
}

::v-deep .receiverSearch .title {
  background-color: #252525 !important;
  color: white;
}

@media screen and (max-width: 842px) {
  .leftReceiver {
    .receiverContent {
      ::v-deep .left {
        .square {
          margin-left: 0;
          margin-right: 5px;
        }
      }

      ::v-deep .receiverItem {
        .Info {
          max-width: 56%;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .leftReceiver {
    .receiverSearch {
      ::v-deep .multipe-select-box {
        width: 100%;
      }

      ::v-deep .tree-menu {
        width: 100%;
        margin-left: 0;
      }
    }

    .bookDownMedia {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}
</style>
