<template>
  <div
    class="bitrateBox"
    :style="{width:width}">
    <div class="box-top clearfix">
      <div class='f_left'>
        {{$t('lang.Bitrate')}}
      </div>
      <div class="f_left">(kbps)</div>
      <div class="f_right btns">
        <span
          @click="setMode('vbr',bitInfo.CurrentTCanSetVideoMode)"
          :class="{'disabled': bitInfo.CurrentTCanSetVideoMode ? false : true, 'greenColor': bitInfo.CurrentTVideoMode == '0'}">VBR</span>
        <span :class="{'disabled': bitInfo.CurrentTCanSetVideoMode ? false : true}"> | </span>
        <span
          @click="setMode('cbr',bitInfo.CurrentTCanSetVideoMode)"
          :class="{'disabled': bitInfo.CurrentTCanSetVideoMode ? false : true, 'greenColor': bitInfo.CurrentTVideoMode != '0'}">CBR</span>
      </div>
    </div>
    <div class="box-bottom clearfix">
      <div class="silderBox w_65 f_left">
        <el-slider
          :disabled="canSetBitrate"
          :step="0.5"
          @change="dragEnd"
          v-model="bitrateValue"
          :max="bitInfo.BitrateSettingModel.MaximumValue"
          :min="bitInfo.BitrateSettingModel.MinimumValue"></el-slider>
      </div>
      <div class="w_25 f_right m_t_8">
        <el-input
          :disabled="canSetBitrate"
          :maxlength="10"
          v-model="bitrate"
          size="small"
          @focus="changeFreash(false)"
          @blur="changeFreash(true)"
          @keyup.enter.native="changeVal()"
          @change="changeFreash(false)"
          @input="changeFreash(false)"
          ref="bitrateRef"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
export default {
  props: {
    width: {
      type: String,
      default: '255px'
    },
    bitInfo: { // categoryId: "2152866048", operationType: "101"的websoket的查询信息返回data结果
      type: Object
    },
    rid: Array, // 当前在播的r的id
    params: { // 设置是的传参参数
      type: Object,
      default: () => {
        return {
          rid: 'rid',
          bitrate: 'bitrate',
          delay: 'delay',
          pn: 'pn'
        }
      }

    },
    url: {
      type: Object,
      default: () => {
        return {
          set: '/ccp/tvucc-device/receiver/setBitrateAndDelay'
        }
      }
    }// set请求时的url
  },
  data () {
    return {
      bitrateValue: 0,
      bitrate: 0,
      isFreash: true,
      canSetBitrate: true
    }
  },
  watch: {
    bitInfo (val) {
      if (!val || !this.isFreash) return
      this.bitrateValue = val.BitrateSettingModel.CurrentValue
      this.bitrate = val.BitrateSettingModel.CurrentValue
      this.canSetBitrate = !val.CurrentTCanSetBitrate
    }
  },
  mounted () {
  },
  methods: {
    dragEnd () {
      this.setBitrate(this.bitrateValue)
    },
    changeFreash (val) {
      // bitrateValue的值是否需要被websoket刷新
      this.isFreash = val
    },
    changeVal () {
      // 手动更改bitrateValue的值
      const bitrate = Number(this.bitrate)
      if (isNaN(bitrate)) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.rightNumber')
        })
        return
      }
      // 超出范围提示
      if (bitrate < this.bitInfo.BitrateSettingModel.MinimumValue || bitrate > this.bitInfo.BitrateSettingModel.MaximumValue) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.bitrateDelay', [this.bitInfo.BitrateSettingModel.MaximumValue, this.bitInfo.BitrateSettingModel.MinimumValue])
        })
        return
      }
      this.setBitrate(bitrate)
    },
    // 设置比特率
    setBitrate (bitrate) {
      const params = {}
      params[this.params.rid] = this.rid.join().toLowerCase()
      if (typeof bitrate === 'string') {
        const index = bitrate.indexOf('.')
        params[this.params.bitrate] = bitrate.slice(0, index)
      } else {
        params[this.params.bitrate] = bitrate.toFixed(0)
      }
      params[this.params.delay] = this.bitInfo.DelaySettingModel.CurrentValue * 1000
      params[this.params.pn] = 'nhm'
      this.axios.post(this.url.set, params)
        .then(res => {
          if (res.status === 200) {
            this.bitrateValue = bitrate
            this.bitrate = bitrate
            this.$emit('set', bitrate)
            setTimeout(() => {
              this.isFreash = true
              if (this.$refs.bitrateRef) this.$refs.bitrateRef.blur()
            }, 1000)
          }
        }).catch(() => {})
    },
    // 设置cbr,vbr
    setMode (mode, btn) {
      if (!btn) return
      this.$confirm(this.$t('lang.confirmModeChange', { mode: mode.toUpperCase() }), '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        const dataStr = {
          rid: this.rid.join().toLowerCase(),
          data: '{"VideoMode":"' + mode + '"}'
        }
        const params = Object.assign(dataStr, receviceParams.setVideo.setCbrVbr)
        this.axios.post(Receiver().setVideo.setCbrVbr, params)
          .then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
            } else {
              this.$message({
                type: 'warning',
                message: 'Error'
              })
            }
          }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__wrapper .el-dialog .el-dialog__body {
  color: red;
}
.bitrateBox {
  .btns {
    span {
      cursor: pointer;
      &.disabled {
        color: grey;
      }
    }
  }
  ::v-deep .el-input__inner {
    height: 25px !important;
    line-height: 25px !important;
    padding: 0 8px;
  }
}
</style>
