function handleTimeLockTips (code, errorInfo) {
  let msg = ''
  let deviceInfo = ''
  switch (code) {
    case '81100502':
      msg = 'lang.NullIp'
      break
    case '81100513':
      msg = 'lang.sameMachineTip'
      break
    case '81100508':
      msg = 'lang.noTimeLockFeature'
      break
    case '81100503':
      msg = 'lang.failedGetIp'
      break
    case '81101301':
      msg = 'lang.NotDevice'
      break
    case '81101304':
      msg = 'lang.NotTR'
      break
    case '81100507':
      msg = 'lang.MoreThanTwo'
      break
    case '81100504':
      msg = 'lang.AccountSynchronizing'
      break
    case '81100505':
      msg = 'lang.deviceSynchronizing'
      break
    case '81100511':
      msg = 'lang.IpSynchronizing'
      break
    case '81100506':
      msg = 'lang.failedGetFeature'
      break
    case '81100601':
      msg = 'lang.notSynchronizing'
      break
    case '81100509':
      msg = 'lang.failedGetMainR'
      break
    case '81100510':
      msg = 'lang.mainRcantRemove'
      break
    case '81100515':
      msg = 'lang.noPermission'
      break
    default:
      break
  }
  if (typeof errorInfo == 'string') {
    let indexS = errorInfo.indexOf('[')
    let indexE = errorInfo.indexOf(']')
    if (indexS >= 0 && indexE >= 0) deviceInfo = ' ' + errorInfo.slice(indexS, indexE + 1)
  }
  return {
    msg,
    deviceInfo
  }
}
export { handleTimeLockTips }
