<template>
  <div id="moduleBox">
    <div class="module-left w_35 f_left m_t_15">
      <video-module
        :clearSelectR="true"
        height="158px"
        width='320px'
        :source="source"
        :useOldR="useOldR"
        deviceType="R"
        :rid="rid"></video-module>
      <div class="sourceInfo">
        <div class='title'>{{language[lang].sourceFormat}}</div>
        <div class="info">
          <span>{{language[lang].video}}: </span>
          <span>{{CurrentOutputFormat2}} -8 bit 4:2:2 YUV</span>
        </div>
        <div class="info">
          <span>{{language[lang].audio}}: </span>
          <span>2 channels, 48.0kHz, 16-bit</span>
        </div>
      </div>
      <div class="hide">{{rVersion}}</div>
      <div
        class="outputVoiceModule"
        v-if="sliderInfo.show">
        <div class="title">Main Level</div>
        <div class="content">
          <div class="setVoiceModule">
            <div class="leftVoice f_left">
              <el-slider
                :max="3"
                :min="-40"
                v-model="sliderInfo.leftValue"
                :disabled="sliderInfo.leftEnable"
                vertical
                height="150px"
                @change="(val)=>{dragEnd(val,'L')}"></el-slider>
              <div class="text_center voiceTitle">L</div>
              <div>
                <i
                  class="iconfont leftVoiceIcon"
                  :disabled="sliderInfo.leftEnable"
                  @click="externalMute('L')"
                  :class="{'icon-mute': sliderInfo.leftMute}"></i>
              </div>
            </div>
            <div class="rightVoice f_left">
              <el-slider
                :max="3"
                :min="-40"
                :disabled="sliderInfo.rightEnable"
                v-model="sliderInfo.rightValue"
                vertical
                height="150px"
                @change="(val)=>{dragEnd(val,'R')}"></el-slider>
              <div class="text_center voiceTitle">R</div>
              <div>
                <i
                  class="iconfont rightVoiceIcon"
                  :disabled="sliderInfo.rightEnable"
                  @click="externalMute('R')"
                  :class="{'icon-mute': sliderInfo.rightMute}"></i>
              </div>
            </div>
          </div>
          <div class="outputVoice">
            <div class="f_left leftOutput">
              <div class="common_bg">
                <div class="voices"></div>
              </div>
              <div class="text_center m_t_8">L</div>
            </div>
            <div class="f_left rightOutput">
              <div class="common_bg">
                <div class="voices"></div>
              </div>
              <div class="text_center m_t_8">R</div>
            </div>
            <div class="f_left audioScale"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="module-right w_65 f_right">
      <el-scrollbar
        style="height:100%;min-height: 300px;"
        ref="scrollView"
        v-loading="showLoading"
        :element-loading-text="language[lang].loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div
          id="slotModule"
          ref="slotModule">
          <div
            class="encoderBox"
            v-for="(item, index) in encoderListInfo"
            :key="index">
            <div>
              <el-checkbox
                :class="{grayColor:!item.Enabled}"
                @change="setEncoder(index)"
                v-model="item.Enabled"
                class="whiteFont">{{language[lang].Encoder + (index + 1)}}</el-checkbox>
              <el-checkbox
                style="margin-right:15px;"
                :class="{grayColor:!item.IsCBR}"
                @change="setEncoderCBR(index)"
                v-model="item.IsCBR"
                class="whiteFont">{{language[lang].CBR}}</el-checkbox>
              <!-- <span class="source-default" v-show="item.Enabled"><span class="green">Source: </span><i class="el-icon-success"></i>Default</span> -->
              <div class="select-box small">
                <div class="hide">{{item}}</div>
                <el-select
                  v-model="item.CurrentSharedMemoryString"
                  @change="setSharedMemory($event, index)"
                  placeholder="Default"
                  size="small"
                  :disabled="!item.Enabled || !item.CurrentEncoderSharedMemory.IsEnabled"
                  :popper-append-to-body="false">
                  <el-option
                    v-for="item in pushStreamSource"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-container>
              <el-main class="slotBox">
                <el-collapse-transition>
                  <div v-show="item.Enabled">
                    <el-row :gutter="20">
                      <el-checkbox
                        v-model="item.IsLogoEnabled"
                        @change="changeLogoEnable(index)"
                      >{{$t('lang.pictureSize')}}</el-checkbox
                      >
                    </el-row>
                    <el-row>
                      <el-col :span="4">
                        <el-upload
                          class="avatar-uploader"
                          action=""
                          :show-file-list="false"
                          v-if="item.IsLogoEnabled"
                          :disabled="logoUrl[index] && logoUrl[index].isEdit"
                          :auto-upload="false"
                          :on-change="(file, fileList) => {changeFile(file, fileList, index);}"
                        >
                          <img
                            class="avatar"
                            v-if="logoUrl[index] ? logoUrl[index].LogoFileBase64String : ''"
                            :src="logoUrl[index] ? 'data:image/png;base64,' + logoUrl[index].LogoFileBase64String: ''"
                          />
                          <i
                            class="el-icon-plus avatar-uploader-icon"
                            v-else
                          ></i>
                        </el-upload>
                      </el-col>
                      <el-col
                        class="logoOffset"
                        :span="19"
                        v-if="item.IsLogoEnabled"
                      >
                        <el-row>
                          <span>{{ $t('lang.offsetX') }}</span>
                          <el-input
                            class="offsetInput"
                            v-model="item.LogoXOffset"
                            :disabled="logoUrl[index] && logoUrl[index].isEdit"
                            @focus="clearRefresh"
                          ></el-input>
                          <i
                            class="el-icon el-icon-edit-outline"
                            @click="editLogo(index)"
                            v-if="logoUrl[index] && logoUrl[index].isEdit"
                          ></i>
                          <el-button
                            size="mini"
                            type="lightBtn"
                            @click="
                              submitUpload(
                                index,
                                item.LogoXOffset,
                                item.LogoYOffset
                              )
                            "
                            v-else
                          >{{ $t('lang.applyBtn') }}</el-button
                          >
                        </el-row>
                        <el-row>
                          <span>{{ $t('lang.offsetY') }}</span>
                          <el-input
                            class="offsetInput"
                            v-model="item.LogoYOffset"
                            @focus="clearRefresh"
                            :disabled="logoUrl[index] && logoUrl[index].isEdit"
                          ></el-input>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].Resolution}}</div>
                        <div class="select-box">
                          <el-select
                            v-model="item.SelectedResolutionIndex"
                            @change="setEncoderInfo('SelectedResolutionIndex', $event, index)"
                            placeholder="1920x1080"
                            :disabled="!item.Enabled"
                            :popper-append-to-body="false">
                            <el-option
                              v-for="item in resolution"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].Bitrate}}</div>
                        <div class="select-box">
                          <el-select
                            :popper-append-to-body="false"
                            @change="setEncoderInfo('SelectedVBitrateIndex', $event, index)"
                            v-model="item.SelectedVBitrateIndex"
                            placeholder="8M"
                            :disabled="!item.Enabled">
                            <el-option
                              v-for="item in vbitrate"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].Port}}</div>
                        <el-input
                          v-model="item.Port"
                          placeholder="8M"
                          disabled></el-input>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].ABitrate}}</div>
                        <div class="select-box">
                          <el-select
                            :popper-append-to-body="false"
                            @change="setEncoderInfo('SelectedABitrateIndex', $event, index)"
                            v-model="item.SelectedABitrateIndex"
                            placeholder="128K"
                            :disabled="!item.Enabled">
                            <el-option
                              v-for="item in abitrate"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].AChannel}}</div>
                        <div class="select-box">
                          <el-select
                            :popper-append-to-body="false"
                            @change="setEncoderInfo('SelectedAChannelIndex', $event, index)"
                            v-model="item.SelectedAChannelIndex"
                            placeholder="Stere0"
                            :disabled="!item.Enabled">
                            <el-option
                              v-for="item in achannel"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div :class="{grayColor:!item.Enabled}">{{language[lang].Codec}}</div>
                        <div class="select-box">
                          <el-select
                            :popper-append-to-body="false"
                            @change="setEncoderInfo('SelectedACodecIndex', $event, index)"
                            v-model="item.SelectedACodecIndex"
                            placeholder="MP3"
                            :disabled="!item.Enabled">
                            <el-option
                              v-for="item in acodec"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </el-col>
                    </el-row>
                    <div :class="{grayColor:!item.Enabled}">{{language[lang].Output}}</div>
                    <div class='checkBoxModule'>
                      <el-checkbox
                        v-for="copier in item.Copiers"
                        :key="copier.Name"
                        @change="setCopierInfo(copier.Name, $event, index)"
                        :class="{grayColor:!item.Enabled}"
                        class="whiteFont"
                        v-model="copier.Enabled"
                        :disabled="!item.Enabled">
                        {{copier.Name | upperCaseName}}
                      </el-checkbox>
                    </div>
                    <div class="streamBox">
                      <div
                        v-for="(copier, key) in item.Copiers"
                        :key="key">
                        <div
                          class="streamDiv"
                          v-if="copier.Enabled">
                          <div class="streamDiv-top">
                            <span
                              class="outputSpan"
                              :class="{grayColor:!item.Enabled}">{{copier.Name | upperCaseName}}</span>
                            <el-autocomplete
                              :disabled="(copier.CopierNextOperation =='Stop')||copier.loading"
                              size="small"
                              @focus="clearRefresh(index,key)"
                              class="w_55 outputInput"
                              value-key="rtmpUrl"
                              :placeholder="language[lang].inputPlaceholder"
                              v-scroll="scrollData"
                              v-model="copier.Url"
                              :fetch-suggestions="(queryString,cb)=>{searchShareAccount(queryString,cb,copier.Name)}"
                              :debounce="1500"
                              @select="saveShareAccount($event, index, key, copier.Url)">
                              <template slot-scope="{ item }">
                                <i
                                  class="iconfont f_left"
                                  :class="showTypeIcon(item)"></i>
                                <div
                                  class="f_left"
                                  style="width:88%;margin-left: 5px;">
                                  <div
                                    class="name f_left ellipsis"
                                    :title="item.nickName"
                                    style="width:15%;">{{ item.nickName }} </div>
                                  <div
                                    class="addr f_left ellipsis"
                                    style="width:80%;"
                                    :title="item.rtmpUrl">({{ item.rtmpUrl }})</div>
                                </div>
                              </template>
                            </el-autocomplete>
                            <button
                              v-if="!(showEncoderName[index] ? showEncoderName[index][key].value : false)"
                              @click="pushStream(copier.Name, index, key, copier.Url)"
                              class="outputBtn"
                              :class="showButton(copier.Url, index, key).colorClass"
                              :disabled="!item.Enabled">{{showButton(copier.Url, index, key).lang}}</button>
                            <span v-if="!(showEncoderName[index] ? showEncoderName[index][key].value : false)">{{copier.ProcessID}}</span>
                          </div>
                          <div
                            class="streamDive-bottom"
                            v-if="(showEncoderName[index] ? showEncoderName[index][key].value : false)">
                            <span
                              class="outputSpan"
                              :class="{grayColor:!item.Enabled}">{{language[lang].StreamName}}</span>
                            <el-input
                              size="small"
                              maxlength='32'
                              :placeholder="language[lang].inputNickName"
                              @focus="clearRefresh(index,key)"
                              @blur='changeUpFlag'
                              type="text"
                              class="w_55 outputInput"
                              v-model="copier.addNickName"
                              width="55%"></el-input>
                            <button
                              @click="addShareAccount(copier.Name, index, key)"
                              class="outputBtn"
                              :disabled="!item.Enabled">{{showButton(copier.Url, index, key).lang}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-transition>
              </el-main>
            </el-container>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import language from '@/assets/lang/config.js'
import Api from '@/assets/api/api.js'
import Tool from '@/assets/js/utils.js'
import videoModule from '@/components/VideoC'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'

export default {
  name: 'IpStreamOut',
  data () {
    return {
      fileData: new Image(),
      imageUrl: '',
      indexss: false,
      keyss: false,
      showLoading: true,
      language: language,
      encoderList: 6,
      encoderListInfo: [],
      copyencoderListInfo: [],
      shareAccount: [],
      resolution: [],
      abitrate: [],
      achannel: [],
      acodec: [],
      streamUrlList: {},
      showIpstream: false,
      showEncoderName: [],
      currentPage: 1,
      lastCurrentTime: 0,
      currentTime: 0,
      noMore: false,
      scrollData: {
        onScroll: this.onScroll,
        distance: 10,
        directive: '.el-autocomplete-suggestion__wrap'
      },
      showListCb: null,
      behaviorList: [], // 记录之前的操作，与返回值对比，好恢复websocket
      optionIndex: 0, // 记录当前所操作的encoder是哪个
      optionCBRIndex: 0,
      encoderListCopy: [],
      chehckCopierTimer: null,
      initCopierNumber: true,
      hadClick: false, // let customer cannot click button quickly
      sliderInfo: {
        show: false,
        leftEnable: true,
        rightEnable: true,
        leftMute: false,
        rightMute: false,
        leftValue: 0,
        rightValue: 0,
        outputLVoiceValue: 0,
        outputRVoiceValue: 0,
        isFreashLeftVoice: true,
        isFreashRightVoice: true,
        isFreashLeftMute: true,
        isFreashRightMute: true
      },
      imgList: [],
      timers: null,
      tvuccUrl: '',
      updateInfo: true,
      pushStreamSource: [],
      reFreshTimer: null,
      pushStreamList: [],
      encoderListInitList: {},
      needBitObject: {},
      needResolution: {},
      logoUrl: []
    }
  },
  props: {
    lang: {
      type: String,
      default: 'en'
    },
    width: {
      type: String,
      default: '1075px'
    },
    show: {
      type: Object
    },
    rid: {
      type: String,
      default: 'd627bfe491e4d247'
    },
    initUrl: {
      type: String,
      default: '/ccp/tvucc-rws/receiver/command/'
    },
    mediaUrl: {
      type: String,
      default: ''
    },
    receiverWebsocket: {
      type: Object
    },
    source: {
      type: Object,
      default: function () {
        return { peerId: this.$store.state.selectSourceId }
      }
    },
    CurrentOutputFormat2: {
      type: String,
      default: 'NTSC'
    },
    rVersion: {
      type: String,
      default: ''
    },
    useOldR: {
      type: Boolean,
      default: true
    },
    voiceInfo: Object,
    pn: {
      type: String,
      default: 'hm'
    }
  },
  components: {
    videoModule
  },
  created () {
    // 获取5.6版本以下的信息
    this.getExtInfo()
    this.getEncoderInfo()
  },
  watch: {
    'encoderListInfo.length': function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.encoderListInfo.map((v, k) => {
          const val = this.imgList[k] || ''
          this.$set(this.imgList, k, val)
        })
      }
    },
    voiceInfo: function (val) {
      const leftDb = val ? (val.LeftDb - (-40)) / 40 * 160 : -40
      const rightDb = val ? (val.RightDb - (-40)) / 40 * 160 : -40
      $('.leftOutput .voices').css('height', leftDb)
      $('.rightOutput .voices').css('height', rightDb)
    }
  },
  methods: {
    editLogo (index) {
      this.logoUrl[index].isEdit = false
      this.logoUrl = Object.assign({}, this.logoUrl)
      this.clearRefresh()
    },
    submitUpload (index, LogoXOffset, LogoYOffset) {
      this.clearRefresh()
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          EncoderIndex: index,
          LogoXOffset: LogoXOffset,
          LogoYOffset: LogoYOffset,
          IsLogoEnabled: this.logoUrl[index].IsLogoEnabled,
          LogoFileBase64String: this.logoUrl[index].LogoFileBase64String
        })
      }
      const params = Object.assign(data, Api.options.setEncoderLogo)
      const URL = `${this.initUrl}${Api.url.setEncoderLogo}`
      this.axios.post(URL, params).then((res) => {
        this.changeUpFlag()
      }).catch(() => {})
    },
    changeLogoEnable (index) {
      this.clearRefresh()
      if (this.logoUrl[index].IsLogoEnabled) {
        this.logoUrl[index].IsLogoEnabled = false
      } else {
        this.logoUrl[index].IsLogoEnabled = true
      }
      const data = {
        rid: this.rid,
        Data: JSON.stringify({
          EncoderIndex: index,
          IsLogoEnabled: this.logoUrl[index].IsLogoEnabled,
          LogoYOffset: null,
          LogoXOffset: null
        })
      }
      const params = Object.assign(data, Api.options.setEncoderLogo)
      const URL = `${this.initUrl}${Api.url.setEncoderLogo}`
      this.axios.post(URL, params)
        .then(res => {
          this.changeUpFlag()
        }).catch(() => {})
    },
    // 上传文件
    changeFile (file, fileList, index) {
      if (this.pn === 'hm') {
        adAnalytice('uploadLogoHM')
      } else {
        adAnalytice('uploadLogoRC')
      }
      const _this = this
      new Promise(function (resolve, reject) {
        const width = 640
        const height = 480
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file.raw)
        img.onload = function () {
          if (img.width > width || img.height > height) {
            _this.$message.error(language[_this.lang].picturesFormat)
            return
          }
          resolve()
        }
      }).then(() => {
        let picUrl = ''
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = function (e) {
          picUrl = e.target.result.split(',')[1]
          _this.logoUrl[index].LogoFileBase64String = picUrl
          _this.logoUrl[index].IsLogoEnabled = true
        }
      }).catch(() => {})
    },
    // 获取左侧音量信息
    getExtInfo () {
      const isNewVersion = this.rVersion > 5.6
      //     that = this;

      if (isNewVersion) {
        // 版本号大于5.6时走 this.getEncoderInfo()这个方法,发送101websocket
        return
      }
      // 小于5.6版本
      this.receiverWebsocket.send({
        message: { categoryId: '2152870144', operationType: '200', rid: this.rid },
        time: 1000,
        key: 'queryExtInfo',
        success: res => {
          // if (this.isStopExt) return;
          if (res.errorMessage == 'Module Not Found.') {
            this.$message.error('Module not find')
            this.receiverWebsocket.stop('queryExtInfo')
            // $("#bavkGroundDiv2").hide();
            return
          }
          var data = JSON.parse(res.data)
          this.updateExternal(data)
        }
      })
    },
    updateExternal (result) {
      /*
             * 更新音量信息
             * 是否关闭调节音量功能
             */
      const version = this.rVersion > 6.0
      if (version) {
        if (!result.CanAdjustVolume) {
          // 首次获取信息的时候，判断是否开启了Preview
          this.sliderInfo.leftEnable = true
          this.sliderInfo.rightEnable = true
        } else {
          this.sliderInfo.leftEnable = false
          this.sliderInfo.rightEnable = false
        }
      }
      if (version) {
        // var ltAudioVal, rtAudioVal;
        this.sliderInfo.show = true
        if (result.VolumeModelList && result.VolumeModelList.length) {
          result.VolumeModelList.map((v, k) => {
            var currentType = v.ChannelTag; var currentMuteFlag = v.IsMute
            if (currentType == 'L') {
              if (this.sliderInfo.isFreashLeftVoice) { // 值为true是更新websoket的值
                this.sliderInfo.leftValue = v.Volume
              }

              if (this.sliderInfo.isFreashLeftMute) { // 值为true是更新websoket的值
                if (currentMuteFlag) { // 是否显示静音
                  this.sliderInfo.leftMute = true
                } else {
                  this.sliderInfo.leftMute = false
                }
              }
            }
            if (currentType == 'R') {
              if (this.sliderInfo.isFreashRightVoice) {
                this.sliderInfo.rightValue = v.Volume
              }

              if (this.sliderInfo.isFreashRightMute) { // 值为true是更新websoket的值
                if (currentMuteFlag) {
                  this.sliderInfo.rightMute = true
                } else {
                  this.sliderInfo.leftMute = false
                }
              }
            }
          })
        }
      } else {
        this.sliderInfo.show = false
        this.sliderInfo.leftEnable = true
        this.sliderInfo.rightEnable = true
      }
    },
    // 设置是否静音
    externalMute (type, status) {
      let mute = ''
      if (type == 'L') {
        if (this.sliderInfo.leftEnable || status == 'close') {
          return
        }
        this.isFreashLeftMute = false
        mute = !this.sliderInfo.leftMute
      } else {
        if (this.sliderInfo.rightEnable || status == 'close') {
          return
        }
        this.isFreashRightMute = false
        mute = !this.sliderInfo.rightMute
      }
      const param = {
        rid: this.rid,
        params: JSON.stringify({ ChannelTag: type, IsMute: mute })
      }
      this.axios.post('/ccp/tvucc-device/receiver/setMute', param).then(res => {
        const result = res.data.result
        if (result && result.includes('0x0')) {
          if (type == 'L') {
            this.sliderInfo.leftMute = mute
            setTimeout(() => {
              this.isFreashLeftMute = true
            }, 1000)
          } else {
            this.sliderInfo.rightMute = mute
            setTimeout(() => {
              this.isFreashRightMute = true
            }, 1000)
          }
        }
      }).catch(() => {})
    },
    // 设置音量
    dragEnd (val, type) {
      if (type == 'L') {
        this.isFreashLeftVoice = false
      } else {
        this.isFreashRightVoice = false
      }
      const param = {
        rid: this.rid,
        params: JSON.stringify({ ChannelTag: type, Volume: val })
      }
      this.axios.post('/ccp/tvucc-device/receiver/setVolume', param).then(res => {
        const result = res.result.data
        if (result.includes('0x0')) {
          // 重置音量状态，关闭静音
          this.externalMute(type, 'close')

          if (type == 'L') {
            this.sliderInfo.leftValue = val
            // 延迟一秒更新websoket的值
            setTimeout(() => {
              this.isFreashLeftVoice = true
            }, 1000)
          } else {
            this.sliderInfo.rightValue = val
            // 延迟一秒更新websoket的值
            setTimeout(() => {
              this.isFreashRightVoice = true
            }, 1000)
          }
        }
      }).catch(() => {})
    },
    getLogoUrl () {
      let array = []
      for (var i = 0; i < this.encoderListInfo.length; i++) {
        let obj = {}
        array.push({ EncoderIndex: i })
        this.logoUrl[i] = obj
      }
      const params = {
        rid: this.rid,
        data: JSON.stringify(array)
      }
      const URL = 'ccp/tvucc-device/receiver/getEncoderLogo'
      this.axios.post(URL, params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then((res) => {
        if (res.data.errorCode == '0x0' && res.data.result) {
          this.logoUrl = JSON.parse(res.data.result)
          this.logoUrl.map(item => {
            if (item.LogoFileBase64String == '') {
              item.isEdit = false
            } else {
              item.isEdit = true
            }
            return item
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.errorInfo
          })
        }
      }).catch(() => {})
    },
    getEncoderInfo () {
      if (JSON.stringify(this.receiverWebsocket) != '{}') {
        if (this.receiverWebsocket && this.receiverWebsocket.successObj.getIpStreamOut) {
          this.receiverWebsocket.stop('getIpStreamOut')
        }

        this.receiverWebsocket.send({
          message: { categoryId: '2152870144', operationType: '101', rid: this.rid },
          time: 2000,
          key: 'getIpStreamOut',
          success: res => {
            // console.log(JSON.parse(res.data))
            this.encoderListCopy = JSON.parse(res.data).Config.ListEncoders
            if (this.initCopierNumber) {
              this.initCopierNumber = false
              // 用来控制是否显示添加新账号的
              this.encoderList = JSON.parse(res.data).Config.ListEncoders[0].Copiers.length
              this.createEncoderName()

              let streamUrlListObj = {}
              JSON.parse(res.data).Config.ListEncoders[0].Copiers.map((v, i) => {
                streamUrlListObj[v.Name] = ''
                streamUrlListObj = Object.assign({}, streamUrlListObj)
              })
              this.streamUrlList = streamUrlListObj
            }
            let resolutionArray = []; let vbitrateArray = []; let abitrateArray = []; let achannelArray = []; let acodecArray = []

            resolutionArray = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListResolutions,
              isSame: false,
              valArray: ['label', 'value']
            })
            vbitrateArray = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListVBitrates,
              valArray: ['label', 'value'],
              isSame: false
            })
            abitrateArray = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListABitrates,
              valArray: ['label', 'value'],
              isSame: false
            })
            achannelArray = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListAChannels,
              valArray: ['label', 'value'],
              isSame: false
            })
            acodecArray = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListAEncoders,
              valArray: ['label', 'value'],
              isSame: false
            })
            this.pushStreamSource = Tool.changeArray({
              data: JSON.parse(res.data).Config.ListEnabledSHMs,
              valArray: ['label', 'value'],
              isSame: true
            })
            this.pushStreamList = []
            // update push stream array
            this.encoderListCopy.forEach((v, i) => {
              const copierList = v.Copiers
              copierList.forEach((v1, i1) => {
                if (v1.Enabled && v1.CopierNextOperation === 'Stop' && !this.pushStreamList.includes(v1.Url)) {
                  this.pushStreamList.push(v1.Url)
                }
              })
            })

            if (!this.updateInfo) {
              return
            }
            this.resolution = resolutionArray
            this.vbitrate = vbitrateArray
            this.abitrate = abitrateArray
            this.achannel = achannelArray
            this.acodec = acodecArray
            this.encoderListInfo = JSON.parse(res.data).Config.ListEncoders
            this.copyencoderListInfo = JSON.parse(res.data).Config.ListEncoders
            this.showLoading = false
            if (!this.logoUrl.length) this.getLogoUrl()
            // 左侧信息
            if (this.rVersion > 5.6) {
              const leftData = JSON.parse(res.data)
              this.updateExternal(leftData)
            }
            // 查询4M,2M,1.2M在列表中的index
            this.vbitrate.forEach((v, i) => {
              const label = v.label
              if (label === '4M' || label === '2M' || label === '1.2M') {
                this.needBitObject[label] = i
              }
            })
            this.resolution.forEach((v, i) => {
              const label = v.label
              if (label === '1920x1080' || label === '1280x720' || label === '640x480') {
                this.needResolution[label] = i
              }
            })
            // 当分辨率是1920,1280,720(包括以下)的时候需要给R下发一次设置bitrate的命令
            this.encoderListInfo.forEach((v, i) => {
              const enable = v.Enabled
              if (enable && this.encoderListInitList[i] === undefined) {
                this.encoderListInitList[i] = true
                this.changeBitrate(v, i)
              }
            })
          }
        })
      }
    },
    changeBitrate (item, index) {
      let sendIndex = 0
      const resolution = item.SelectedResolutionIndex
      if (resolution === this.needResolution['1920x1080']) {
        sendIndex = this.needBitObject['4M']
        this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
      } else if (resolution === this.needResolution['1280x720']) {
        sendIndex = this.needBitObject['2M']
        this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
      } else if (resolution >= this.needResolution['640x480']) {
        sendIndex = this.needBitObject['1.2M']
        this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
      }
    },
    createEncoderName () {
      for (var i = 0; i < this.encoderList; i++) {
        this.showEncoderName[i] = []
        for (var n = 0; n < this.encoderList; n++) {
          this.showEncoderName[i].push({ value: false, name: '' })
        }
      }
    },
    // 设置encoder
    setEncoder (index) {
      // 操作过后停止更新websocket，接口返回失败时在去更新否则就等到值发生变话在去更新
      this.optionIndex = index
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      const currentStatus = this.encoderListInfo[index].Enabled
      // let params = {"OperationType":302,"Data":"{\"SelectedEncoderIndex\":"+index+",\"Enabled\":"+currentStatus+"}"};
      const data = {
        rid: this.rid,
        Data: '{"SelectedEncoderIndex":' + index + ',"Enabled":' + currentStatus + '}'
      }
      const params = Object.assign(data, Api.options.setEncoder)
      this.axios.post(this.initUrl + Api.url.setEncoder, params)
        .then(res => {
          if (res.status === 200) {
            this.checkEncoderSatus(index, currentStatus)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.getEncoderInfo()
          }
          setTimeout(() => {
            this.hadClick = false
          })
        }).catch(() => {})
      // google adAnalytice
      if (currentStatus) {
        if (this.pn === 'hm') {
          adAnalytice('enableEncoderHM')
        } else {
          adAnalytice('enableEncoderRC')
        }
      } else {
        if (this.pn === 'hm') {
          adAnalytice('disableEncoderHM')
        } else {
          adAnalytice('disableEncoderRC')
        }
      }
    },
    // 设置encoder里面选项
    setEncoderInfo (key, value, index) {
      this.optionIndex = index
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()

      const data = {
        rid: this.rid,
        data: ''
      }
      switch (key) {
        case 'SelectedResolutionIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedResolutionIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          if (this.pn === 'hm') {
            adAnalytice('setResolutionHM', '', '', value)
          } else {
            adAnalytice('setResolutionRC', '', '', value)
          }
          break
        case 'SelectedVBitrateIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedVBitrateIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          if (this.pn === 'htm') {
            adAnalytice('setBitrateHM', '', '', value)
          } else {
            adAnalytice('setBitrateRC', '', '', value)
          }
          break
        case 'SelectedEncoderIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedEncoderIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          break
        case 'SelectedABitrateIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedABitrateIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          if (this.pn === 'hm') {
            adAnalytice('setABitrateHM', '', '', value)
          } else {
            adAnalytice('setABitrateHM', '', '', value)
          }
          break
        case 'SelectedAChannelIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedAChannelIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          if (this.pn === 'hm') {
            adAnalytice('setAChannelHM', '', '', value)
          } else {
            adAnalytice('setAChannelHm', '', '', value)
          }
          break
        case 'SelectedACodecIndex':
          data.data = '{"SelectedEncoderIndex":' + index + ',"SelectedACodecIndex":' + value + ',"IsCBR":' + this.encoderListInfo[index].IsCBR + '}'
          if (this.pn === 'hm') {
            adAnalytice('setCodecHM', '', '', value)
          } else {
            adAnalytice('setCodecRC', '', '', value)
          }
      }
      const params = Object.assign(data, Api.options.setEncoderInfo)
      this.axios.post(this.initUrl + Api.url.setEncoderInfo, params)
        .then(res => {
          this.hadClick = false
          if (res.status === 200) {
            this.checkEncoderInfoStatus(key, value, index)
            if (key === 'SelectedResolutionIndex') {
              let sendIndex = 0
              if (value === this.needResolution['1920x1080']) {
                sendIndex = this.needBitObject['4M']
                this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
              } else if (value === this.needResolution['1280x720']) {
                sendIndex = this.needBitObject['2M']
                this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
              } else if (value >= this.needResolution['640x480']) {
                sendIndex = this.needBitObject['1.2M']
                this.setEncoderInfo('SelectedVBitrateIndex', sendIndex, index)
              }
            }
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.getEncoderInfo()
          }
        }).catch(() => {})
    },
    // 设置encoder，cbr
    setEncoderCBR (index) {
      this.optionCBRIndex = index
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      const currentStatus = this.encoderListInfo[index].IsCBR
      const data = {
        rid: this.rid,
        Data: '{"SelectedEncoderIndex":' + index + ',"IsCBR":' + currentStatus + '}'
      }
      const params = Object.assign(data, Api.options.setCBR)
      // let params = {"OperationType":302,"Data":"{\"SelectedEncoderIndex\":"+index+",\"IsCBR\":"+currentStatus+"}"};
      this.axios.post(this.initUrl + Api.url.setCBR, params)
        .then(res => {
          if (res.status === 200) {
            this.checkCbrStatus(index, currentStatus)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.getEncoderInfo()
          }
          setTimeout(() => {
            this.hadClick = false
          })
        }).catch(() => {})

      // google adAnalytice
      if (currentStatus) {
        if (this.pn === 'hm') {
          adAnalytice('enableCbrHM')
        } else {
          adAnalytice('enableCbrRC')
        }
      } else {
        if (this.pn === 'hm') {
          adAnalytice('disableCbrHM')
        } else {
          adAnalytice('disableCbrRC')
        }
      }
    },
    setSharedMemory (name, encoderIndex) {
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()
      const data = {
        rid: this.rid,
        Data: '{"SelectedEncoderIndex":' + encoderIndex + ',"SelectedSharedMemory":"' + name + '"}'
      }
      const params = Object.assign(data, Api.options.setSharedMemory)
      this.axios.post(this.initUrl + Api.url.setSharedMemory, params)
        .then(res => {
          if (res.status === 200) {
            this.checkShareMemory(encoderIndex, name)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.getEncoderInfo()
          }
          setTimeout(() => {
            this.hadClick = false
          })
        }).catch(() => {})

      // google adAnalytice
      if (this.pn === 'hm') {
        adAnalytice('setSharedMemoryHM', '', '', name)
      } else {
        adAnalytice('setSharedMemoryRC', '', '', name)
      }
    },
    // 设置推流信息
    setCopierInfo (key, value, index, url) {
      if (this.hadClick) return
      this.hadClick = true
      this.clearWebsocket()

      let port
      url || (url = '')
      port = this.encoderListInfo[index].Port
      const data = {
        rid: this.rid,
        Data: '{"SelectedEncoderIndex":' + index + ',"Enabled":' + value + ',"Name":"' + key + '","Url":"' + url + '","Port":"' + port + '"}'
      }
      const params = Object.assign(data, Api.options.setCopierInfo)
      // params = {"OperationType":401,"Data":"{\"SelectedEncoderIndex\":"+index+",\"Enabled\":"+value+",\"Name\":\""+key+"\",\"Url\":\""+url+"\",\"Port\":\""+port+"\"}"};
      this.axios.post(this.initUrl + Api.url.setCopierInfo, params)
        .then(res => {
          if (res.status === 200) {
            if (!value) {
              this.streamUrlList[key] = ''
            }
            this.checkCopierStatus(index, value, key)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.getEncoderInfo()
          }
          setTimeout(() => {
            this.hadClick = false
          })
        }).catch(() => {})
      // google adAnalytice
      if (this.pn === 'hm') {
        adAnalytice('setCopierHM', '', '', key)
      } else {
        adAnalytice('setCopierRC', '', '', key)
      }
    },
    checkEncoderSatus (index, currentVal) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            const copierList = JSON.parse(res.data.result).Config.ListEncoders
            if (copierList[index]) {
              const encoderVal = copierList[index].Enabled
              if (currentVal === encoderVal) {
                this.getEncoderInfo()
              } else {
                setTimeout(() => {
                  this.checkEncoderSatus(index, currentVal)
                }, 1500)
              }
            }
          }
        }).catch(() => {})
    },
    checkCbrStatus (index, currentVal) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            const copierList = JSON.parse(res.data.result).Config.ListEncoders
            if (copierList[index]) {
              const cbrVal = copierList[index].IsCBR
              if (currentVal === cbrVal) {
                this.getEncoderInfo()
              } else {
                setTimeout(() => {
                  this.checkCbrStatus(index, currentVal)
                }, 1500)
              }
            }
          }
        }).catch(() => {})
    },
    checkShareMemory (encoderIndex, currentName) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            if (JSON.parse(res.data.result).Config) {
              const copierList = JSON.parse(res.data.result).Config.ListEncoders
              if (copierList[encoderIndex]) {
                const shareMemory = copierList[encoderIndex].CurrentSharedMemoryString
                if (currentName === shareMemory) {
                  this.getEncoderInfo()
                } else {
                  setTimeout(() => {
                    this.checkShareMemory(encoderIndex, currentName)
                  }, 1500)
                }
              }
            }
          }
        }).catch(() => {})
    },
    checkEncoderInfoStatus (key, value, index) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            const copierList = JSON.parse(res.data.result).Config.ListEncoders
            if (copierList[index]) {
              const info = copierList[index][key]
              if (value === info) {
                this.getEncoderInfo()
              } else {
                setTimeout(() => {
                  this.checkEncoderInfoStatus(key, value, index)
                }, 1500)
              }
            }
          }
        }).catch(() => {})
    },
    checkCopierStatus (index, value, key) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            let flag = true
            const copierList = JSON.parse(res.data.result).Config.ListEncoders
            if (copierList[index]) {
              copierList[index].Copiers.map((v, i) => {
                if (v.Name === key && value === v.Enabled) {
                  flag = false
                  this.getEncoderInfo()
                }
              })
              if (flag) {
                setTimeout(() => {
                  this.checkCopierStatus(index, value, key)
                }, 1500)
              }
            }
          }
        }).catch(() => {})
    },
    checkCurrentPushStatus (name, index, copiersIndex, currentBtnStatus) {
      const params = Object.assign({ rid: this.rid }, Api.options.externalEncoderInfo)
      this.axios.post(this.initUrl + Api.url.externalEncoderInfo, params)
        .then(res => {
          if (res.status === 200) {
            let flag = true
            const copierList = JSON.parse(res.data.result).Config.ListEncoders
            if (copierList[index]) {
              copierList[index].Copiers.map((v, i) => {
                if (v.Name === name && v.CopierNextOperation === currentBtnStatus) {
                  flag = false
                  v.loading = false
                }
              })
              if (flag) {
                setTimeout(() => {
                  this.checkCopierStatus(index, '', '')
                }, 1500)
              }
            }
          }
        }).catch(() => {})
    },
    // 保存推流地址
    addStreamUrl (key, obj) {
      this.streamUrlList[key] = 'obj'
    },
    // 开始推流
    pushStream (key, index, copiersIndex, copierUrl) {
      if (!this.encoderListInfo[index].Copiers[copiersIndex].Url) {
        this.$message({
          type: 'warning',
          message: this.language[this.lang].warnAddMsg
        })
        return
      }

      this.encoderListInfo[index].Copiers[copiersIndex].loading = true
      let params, enable
      let btnStatus
      const currentBtnStatus = this.encoderListInfo[index].Copiers[copiersIndex].CopierNextOperation == 'Start' ? 'Stop' : 'Start'
      if (this.encoderListInfo[index].Copiers[copiersIndex].CopierNextOperation === 'Start') {
        // if current input url was had been pushed not send agin
        if (this.pushStreamList.includes(this.encoderListInfo[index].Copiers[copiersIndex].Url)) {
          this.$message({
            type: 'warning',
            message: this.language[this.lang].pushSameStream
          })
          return
        };
      }
      if (this.encoderListInfo[index].Copiers[copiersIndex].CopierNextOperation == 'Start' || this.copyencoderListInfo[index].Copiers[copiersIndex].Url != copierUrl) {
        this.setCopierInfo(key, this.encoderListInfo[index].Copiers[copiersIndex].Enabled, index, this.encoderListInfo[index].Copiers[copiersIndex].Url)
        enable = true
        const data = {
          rid: this.rid,
          Data: '{"SelectedEncoderIndex":' + index + ',"Enabled":' + enable + ',"Name":"' + key + '","Url":"' + this.encoderListInfo[index].Copiers[copiersIndex].Url + '","Port":"' + this.encoderListInfo[index].Port + '"}'
        }
        btnStatus = 'start'
        params = Object.assign(data, Api.options.startPushStream)
        // google adAnalytice
        if (this.pn === 'hm') {
          adAnalytice('startPushStreamHM')
        } else {
          adAnalytice('startPushStreamRC')
        }
      } else {
        enable = false
        const data = {
          rid: this.rid,
          Data: '{"SelectedEncoderIndex":' + index + ',"Enabled":' + enable + ',"Name":"' + key + '","Url":"' + this.encoderListInfo[index].Copiers[copiersIndex].Url + '","Port":"' + this.encoderListInfo[index].Port + '"}'
        }
        btnStatus = 'stop'
        params = Object.assign(data, Api.options.stopPushStream)
        // google adAnalytice
        if (this.pn === 'hm') {
          adAnalytice('stopPushStreamHM')
        } else {
          adAnalytice('stopPushStreamRC')
        }
      }

      this.axios.post(this.initUrl + Api.url.startPushStream, params)
        .then(res => {
          if (res.status === 200) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.updateInfo = true
            setTimeout(() => {
              this.checkCurrentPushStatus(key, index, copiersIndex, currentBtnStatus)
              if (btnStatus == 'stop') { // 点击停止推流后，获取一次状态，然后停掉
                this.getEncoderInfo()
                setTimeout(() => {
                  this.clearWebsocket()
                }, 3000)
              }
            }, 2000)
          } else {
            this.$message({
              type: 'warning',
              message: this.$t('lang.requestFailed')
            })
            this.encoderListInfo[index].Copiers[copiersIndex].loading = false
          }
          if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
        }).catch(() => {})
    },
    showButton (url, index, copiersIndex) {
      if (!this.copyencoderListInfo[index]) return

      const obj = {
        colorClass: '',
        lang: this.language[this.lang].Start
      }
      if (this.copyencoderListInfo[index].Copiers[copiersIndex].Url === url) {
        if (this.copyencoderListInfo[index].Copiers[copiersIndex].CopierNextOperation == 'Start') {
          obj.lang = this.language[this.lang].Start
          return obj
        } else {
          obj.colorClass = 'redBg'
          obj.lang = this.language[this.lang].Stop
          return obj
        }
      } else {
        return obj
      }
    },
    async addShareAccount (key, index, copiersIndex) {
      // 停掉当前的websocket循环
      this.clearRefresh(index, copiersIndex)

      if (!this.encoderListInfo[index].Copiers[copiersIndex].addNickName || !this.encoderListInfo[index].Copiers[copiersIndex].Url) {
        this.$message({
          type: 'warning',
          message: this.language[this.lang].warnAddMsg
        })
        return
      }

      const params = {
        nickName: this.encoderListInfo[index].Copiers[copiersIndex].addNickName,
        rtmpUrl: this.encoderListInfo[index].Copiers[copiersIndex].Url,
        authType: 1
      }

      const isHave = await this.checkNickname(params)

      if (!isHave) {
        this.axios.post(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/other/authInfo', params, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200) {
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
              // 添加成功不去更新
              const obj = this.showEncoderName[this.indexss][this.keyss]
              obj.value = false
              this.$set(this.showEncoderName[this.indexss], this.keys, obj)
              this.$set(this.showEncoderName, this.indexss, this.showEncoderName[this.indexss])
              this.pushStream(key, index, copiersIndex)
              // google adAnalytice
              if (this.pn === 'hm') {
                adAnalytice('addNewShareAccountHM')
              } else {
                adAnalytice('addNewShareAccountRC')
              }
              if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
            } else {
              this.$message({
                type: 'warning',
                message: this.$t('lang.requestFailed')
              })
            }
            this.updateInfo = true
          }).catch(() => {})
      }
    },
    checkNickname (options) {
      return new Promise((resolve, reject) => {
        const data = {
          params: {
            nickName: options.nickName,
            flag: false
          }
        }
        this.axios.get(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/media/checkAuth', data, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200 && res.data.errorCode === '82100001') {
              resolve(false)
            } else if (res.data.errorCode === '82100002') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.nameExist')
              })
              resolve(true)
            } else {
              this.$message({
                type: 'warning',
                message: res.data.errorInfo
              })
              resolve(true)
            }
          }).catch(() => {})
      })
    },
    onScroll () {
      if (this.noMore) return
      this.currentPage++
      this.getShareAccount()
    },
    // 获取推流地址列表
    getShareAccount (queryString, type) {
      return new Promise((resolve, reject) => {
        const params = {
          condition: queryString,
          pageNum: this.currentPage,
          pageSize: 20,
          type
        }

        this.axios.post(window.location.protocol + '//' + this.mediaUrl + '/tvucc-media/account/ipStreamOutAccountList', params, { contentType: 'application/json;charset=UTF-8' })
          .then(res => {
            if (res.status === 200 && res.data.errorCode === '0x0') {
              this.shareAccount = this.shareAccount.concat(res.data.result.list)
              if (res.data.result.list.length < 20) {
                this.noMore = true
              }
              this.showListCb(this.shareAccount)
              resolve(res.data.result.list)
            } else {
              this.$message({
                type: 'warning',
                message: this.$t('lang.requestFailed')
              })
            }
          }).catch(() => {})
      })
    },
    async searchShareAccount (queryString, cb, type) {
      this.showListCb = cb
      this.lastCurrentTime = this.currentTime
      this.currentTime = new Date().getTime()
      // if (this.currentTime - this.lastCurrentTime < 1000) return;

      var accountArray = await this.getShareAccount(queryString, type)
      var result = queryString ? accountArray.filter(this.filterAccount(queryString)) : accountArray
      if (!result || !result.length) {
        const obj = this.showEncoderName[this.indexss][this.keyss]
        obj.value = true
        this.$set(this.showEncoderName[this.indexss], this.keys, obj)
        this.$set(this.showEncoderName, this.indexss, this.showEncoderName[this.indexss])
      } else {
        const obj = this.showEncoderName[this.indexss][this.keyss]
        obj.value = false
        this.$set(this.showEncoderName[this.indexss], this.keys, obj)
        this.$set(this.showEncoderName, this.indexss, this.showEncoderName[this.indexss])
      }
      cb(result)
    },
    filterAccount (queryString) {
      return (shareAccount) => {
        return (shareAccount.nickName.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          shareAccount.rtmpUrl.indexOf(queryString === 0)
        )
      }
    },
    saveShareAccount (queryString, index, key, url) {
      this.clearRefresh(index, key)
      this.showButton(url, index, key)
    },
    // 往出定时任务，防止填写的值被覆盖
    clearRefresh (index, key) {
      this.updateInfo = false
      this.indexss = index
      this.keyss = key
      this.shareAccount = []
    },
    changeUpFlag () {
      if (this.reFreshTimer) clearTimeout(this.reFreshTimer)
      // reFresh data after ten second
      this.reFreshTimer = setTimeout(() => {
        this.updateInfo = true
      }, 10000)
    },
    showTypeIcon (item) {
      const type = item.type
      if (type === 'O') {
        return 'otherIcon'
      } else if (type === 'Y') {
        return 'youtubeIcon'
      }
    },
    clearWebsocket () {
      if (this.receiverWebsocket && this.receiverWebsocket.successObj.getIpStreamOut) {
        this.receiverWebsocket.stop('getIpStreamOut')
      }
    }
  },
  filters: {
    upperCaseName: function (name) {
      if (name == 'M3u8') {
        return 'HLS'
      } else if (name == 'Rtmp') {
        return 'RTMP(s)'
      } else {
        return name.toUpperCase()
      }
    }
  },
  beforeDestroy () {
    this.clearWebsocket()
    if (this.receiverWebsocket && this.receiverWebsocket.successObj && this.receiverWebsocket.successObj.queryExtInfo) {
      this.receiverWebsocket.stop('queryExtInfo')
    }
    this.$emit('selectOtherR', '', 'notChangeRStatus')
  }
}
</script>
<style lang="less" scoped>
::v-deep .avatar {
  width: 25px;
  height: 25px;
}
::v-deep .el-input.w_55 {
  width: 55%;
}

::v-deep .streamBox .el-input__inner::-webkit-input-placeholder {
  color: #666;
}
::v-deep .streamBox .el-input__inner::-moz-input-placeholder {
  color: #666;
}
::v-deep .streamBox .el-input__inner::-ms-input-placeholder {
  color: #666;
}
::v-deep #moduleBox .el-slider.is-vertical .el-slider__runway {
  width: 10px;
  border-radius: 0px;
  margin: 0px 12px;
}
::v-deep #moduleBox .el-slider.is-vertical .el-slider__button-wrapper {
  left: -13px;
}
::v-deep
  #moduleBox
  .el-slider__runway
  .el-slider__button-wrapper
  .el-slider__button {
  border-radius: 0;
  height: 6px;
}

::v-deep #moduleBox .el-slider__runway.disabled .el-slider__button {
  border-color: #c0c4cc;
}
.otherIcon:before {
  content: '\E60F';
  font-size: 24px;
}
.youtubeIcon:before {
  content: '\E60C';
  font-size: 24px;
}
.f_left {
  float: left;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#moduleBox {
  height: 490px;
  .uploadImg {
    display: inline-block;
    margin-left: 10px;
    .icon-upload {
      font-size: 18px;
    }
  }
  .source-default {
    .green {
      color: #33aa50;
    }
    i {
      margin-right: 5px;
    }
  }
  .sourceInfo {
    .title {
      margin-top: 20px;
      font-size: 18px;
      color: #aaa;
      margin-bottom: 5px;
    }
    .info {
      font-size: 14px;
    }
  }
  .outputVoiceModule {
    margin-top: 8px;
    .setVoiceModule {
      margin-top: 6px;
      .voiceTitle {
        margin-top: 8px;
      }
      .rightVoice {
        margin-right: 60px;
      }
      .leftVoiceIcon[disabled],
      .rightVoiceIcon[disabled] {
        color: gray;
        cursor: not-allowed;
      }
      .leftVoiceIcon:before,
      .rightVoiceIcon:before {
        content: '\e613';
        margin-left: 12px;
      }
      .icon-mute:before {
        content: '\E615';
        color: red;
      }
    }
    .outputVoice {
      .common_bg {
        height: 150px;
        width: 15px;
        background: #444;
        margin-right: 6px;
        position: relative;
        .voices {
          position: absolute;
          width: 100%;
          background: #33aa50;
          bottom: 0;
        }
      }
      .audioScale {
        background: url('../../assets/img/audio_scale.png');
        background-size: contain;
        height: 160px;
        width: 50px;
        margin-top: -5px;
      }
    }
  }
  #slotModule {
    padding: 10px;
    color: #fff;
    text-align: left;
    min-height: 300px;
    max-height: 450px;
    .whiteFont {
      color: #fff;
    }
    .encoderBox {
      margin-bottom: 10px;
    }
    .sj {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background: #fff;
      position: relative;
      top: 2px;
    }
    .sj:after {
      box-sizing: content-box;
      content: '';
      border: 1px solid #33aa50;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 5px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      transform-origin: center;
      transform: rotate(45deg) scaleY(1);
    }
    .outputSpan {
      width: 75px;
      display: inline-block;
    }
    .outputInput {
      color: #333;
      margin-right: 10px;
    }
    .outputBtn {
      width: 60px;
      outline: none;
      background: #33ab4f;
      border: none;
      height: 24px;
      border-radius: 3px;
      margin-right: 4px;
      margin-left: 4px;
    }
    .outputBtn[disabled] {
      background: gray;
    }
    .outputBtn:hover {
      background: #209d3d;
    }
    .outputBtn[disabled]:hover {
      background: gray;
    }
    .redBg {
      background: red;
    }
    .redBg:hover {
      background: #db1a14;
    }
    .streamBox {
      margin-top: 2px;
    }
    .checkBoxModule {
      position: relative;
    }
    .streamDiv {
      margin-bottom: 10px;
      border-bottom: 2px solid #333;
      .streamDiv-top {
        margin-bottom: 5px;
      }
    }
    .uploadBtn {
      position: absolute;
      right: 20%;
      top: -4px;
    }
    .slotBox {
      padding: 10px 20px 0 20px;
    }
  }
  ::v-deep .el-main {
    overflow: initial;
  }
  ::v-deep .el-select-dropdown {
    position: absolute !important;
    top: 45px !important;
    left: 0 !important;
  }
  ::v-deep .el-select--small .el-select-dropdown {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
  }
}
</style>
