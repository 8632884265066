<template>
  <div :id="boxId"></div>
</template>
<script>
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import Tool from '@/assets/js/utils.js'
var Highcharts = require('highcharts')
export default {
  props: {
    defaultQuatly: {
      type: Number,
      default: () => {
        return 1
      }
    },
    defaultStr: {
      type: String,
      default: () => {
        return 'abc'
      }
    },
    boxId: { // 渲染流量图的容器，id必须唯一
      type: String,
      default: () => {
        return 'quality'
      }
    },
    height: { // 绘制流量图的高度，宽度可根据父盒子自适应
      type: Number,
      default: () => {
        return 150
      }
    },
    qualityVal: { // 流量图每秒的数据
      type: Number,
      isRequired: true
    },
    color: { // 默认渲染流量图的颜色
      type: String,
      default: () => {
        return 'Lime'
      }
    },
    mutilColors: { // 当流量图渲染多种颜色时，不同颜色对应值的最大最小值以及对应显示的颜色
      type: Array,
      default: () => {
        return []
      }
    },
    changeColorVal: { // 更改颜色的值
      type: Number
    },
    xAxis: { // x轴坐标
      type: Array,
      default: () => {
        return [0, 60, 120, 180, 240, 300]
      }
    },
    yAxis: { // y轴坐标
      type: Object,
      default: () => {
        return {
          tickAmount: 5, // 默认显示多少个刻度
          max: 6000// 默认显示的最大值
        }
      }
    },
    workMode: {
      /*
      * 判断视频状态，暂时用来解决home页面，流量图不更新的问题
      * 具体不更新的原因是因为this.State.GetKernelStateObj对象为空
      */
      type: Number
    }
  },
  data () {
    return {
      options: {
        chart: {
          type: 'area',
          // width:430, //画布宽高
          height: 150, //
          marginLeft: 35,
          marginRight: 1,
          marginBottom: 28,
          backgroundColor: '',
          animation: false
        },
        colors: [this.color],
        title: {
          text: ''
        },
        credits: {
          enabled: false // 版权信息
        },
        legend: {
          enabled: false // 图例
        },
        xAxis: {
          endOnTick: true,
          startOnTick: true,
          allowDecimals: false,
          gridLineWidth: 1, // x网格线宽度
          tickWidth: 2,
          tickLength: 1,
          lineColor: '#555',
          gridLineColor: '#555',
          tickColor: '#555',
          tickPositions: [0, 60, 120, 180, 240, 300],
          labels: { // 轴坐标和单位
            formatter: function () {
              return `${this.value / 60}:00` // 格式化x坐标显示
            },
            style: {
              color: '#999'
            },
            x: -5
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          gridLineWidth: 1, // x网格线宽度
          // tickAmount:5,
          tickInterval: 1500,
          gridLineColor: '#555',
          lineColor: '#555',
          tickWidth: 0,
          tickLength: 0,
          tickColor: '#555',
          max: 6000,
          offset: -8,
          labels: {
            formatter: function () {
              return this.value / 1000
            },
            style: {
              color: '#999'
            }
          },
          x: -5 // 设置偏移y轴坐标
        },
        tooltip: {
          formatter: function () {
            return this.y + 'kbps'
          }
        },
        plotOptions: {
          area: {
            lineWidth: 0,
            marker: { // 鼠标移入选中的点
              enabled: false,
              symbol: 'circle',
              radius: 0,
              states: {
                hover: {
                  radius: 2,
                  fillColor: 'red',
                  lineColor: '',
                  lineWidth: 1,
                  border: 1
                }
              }
            }
          }
        },
        series: [{
          name: '',
          zoneAxis: 'x',
          zones: [],
          data: [],
          states: {
            hover: {
              enabled: false
            }
          }
        }]
      },
      qualityTotalPoints: this.xAxis[this.xAxis.length - 1],
      timer: null,
      maxNum: 0
    }
  },
  computed: {
    ...mapState({
      // 映射
      State: state => state
    })
  },
  watch: {
    'yAxis.max': function (val) {
      if (this.chart) {
        this.chart.yAxis[0].update({ // 更新图表坐标轴
          max: val,
          tickInterval: val / (this.yAxis.tickAmount - 1)
        })
      }
    }
  },
  mounted () {
    this.createQuality()
    this.updataData()
    Bus.$on('changQuatilMax', () => {
      this.createQuality()
      this.maxNum = 0
    })
  },
  methods: {
    // 创建流量图
    createQuality () {
      this.options.series[0].data = []
      for (var i = 0; i < this.qualityTotalPoints; i++) { // 设置初始data的值
        this.options.series[0].data.push({ color: '', y: null, x: i })
      }

      this.options.yAxis.max = this.yAxis.max
      this.options.yAxis.tickInterval = this.yAxis.max / (this.yAxis.tickAmount - 1)
      this.options.chart.height = this.height

      if ($(`#${this.boxId}`)[0]) {
        this.chart = Highcharts.chart(this.boxId, this.options) // 绘制图表
      } else {
        if (this.timers) clearTimeout(this.timers)
        this.timers = setTimeout(() => {
          if ($(`#${this.boxId}`)[0]) {
            this.chart = Highcharts.chart(this.boxId, this.options) // 绘制图表
          }
        }, 500)
      }
    },
    // 更新流量图
    updataData () {
      if (this.timer) clearInterval(this.timer)
      this.timer = setInterval(() => {
        // 添加这个判断仅仅用来处理home页面的流量图不更新操作
        if (this.State.GetKernelStateObj.WorkMode != 1 && this.workMode != 1) return
        this.updateTotalGraph(this.qualityVal, this.changeColorVal)
      }, 1000)
    },
    // 更新流量图里面的数据
    updateTotalGraph (qualityVal, errorRate) {
      if (!qualityVal) return
      if (this.defaultQuatly == 0 || this.defaultStr == 'auto' || this.defaultStr == '自动' || this.defaultStr == 'Auto') {
        if (qualityVal > this.maxNum) {
          let maxValue = 6000
          this.maxNum = qualityVal
          if (qualityVal < 1200) {
            maxValue = 1200
          } else if (qualityVal >= 1200 && qualityVal < 2400) {
            maxValue = 2400
          } else if (qualityVal >= 2400 && qualityVal < 6000) {
            maxValue = 6000
          } else if (qualityVal >= 6000 && qualityVal < 12000) {
            maxValue = 12000
          } else if (qualityVal >= 12000 && qualityVal < 24000) {
            maxValue = 24000
          } else {
            maxValue = 30000
          }
          this.chart.yAxis[0].update({ // 更新坐标轴
            max: maxValue,
            tickInterval: maxValue / 4
          })
          this.options.yAxis.max = maxValue
          this.options.yAxis.tickInterval = maxValue / 4
        }
      }
      if (this.defaultQuatly == 6 || this.defaultStr == '6Mb/s') {
        const maxValue = 6000
        this.chart.yAxis[0].update({ // 更新坐标轴
          max: maxValue,
          tickInterval: maxValue / 4
        })
        this.options.yAxis.max = maxValue
        this.options.yAxis.tickInterval = maxValue / 4
      }
      if (!this.chart || typeof qualityVal !== 'number' || (this.mutilColors.length && errorRate == undefined)) {
        return
      }
      // 区分显示颜色
      let color
      if (this.mutilColors.length) {
        this.mutilColors.map(v => {
          if (v.max != undefined && v.min != undefined) {
            if (errorRate <= v.max && errorRate > v.min) {
              color = v.color
            }
          } else {
            if (v.max != undefined) {
              if (errorRate <= v.max) {
                color = v.color
              }
            }
            if (v.min != undefined) {
              if (errorRate > v.min) {
                color = v.color
              }
            }
          }
        })
      }
      // 更新数组
      if (!Tool.isJSON(JSON.stringify(this.options.series[0].data))) return
      const data = JSON.parse(JSON.stringify(this.options.series[0].data))
      data.splice(this.qualityTotalPoints - 1, 1)
      data.forEach(v => {
        v.x += 1
      })
      data.unshift({ color, y: qualityVal, x: 0 })

      if (this.mutilColors.length) {
        // // 处理图表显示色值区域
        const zones = this.formatColor(data)
        // // 更新图表
        // 使用updata会重绘图表，会有性能问题，但是为了更新图表颜色，需要使用update函数
        if (this.chart && this.chart.series && this.chart.series[0]) {
          this.chart.series[0].update({
            data,
            zones
          })
        }
      } else {
        // 只更新数据使用setData函数即可
        if (this.chart && this.chart.series && this.chart.series[0]) {
          this.chart.series[0].setData(data)
        }
      }
    },
    // 用来处理图表显示不同颜色区域
    formatColor (data) {
      const color = []
      let lastColor = ''
      lastColor = lastColor || data[0].color
      data.map(v => {
        if (v.y) {
          if (!color.length) {
            if (v.x == 0) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            }
          } else {
            if (lastColor != v.color) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            } else {
              if (color.length) {
                color[color.length - 1].value = v.x
              }
            }
          }
        }
      })
      return color
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
</style>
